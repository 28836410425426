import React, { useContext } from "react";
import { Box, Typography, Tooltip, Zoom } from "@mui/material";
import { STANDARD_BORDER_RADIUS } from "../utils/constants";
import { COLORS } from "../utils/colors";
import { keyframes, width } from "@mui/system";
import {
  Home as HomeIcon,
  Diamond as DiamondIcon,
  People as PeopleIcon,
  Store as ShopIcon,
  AttachMoney as IncomeIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Notes as NotesIcon,
  LoginRounded,
  HelpOutlineOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/userContext";

const sparkle = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.2) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
`;

const getAdjustedFontSize = (text) => {
  if (text.length <= 20) return "1.25rem";
  if (text.length <= 30) return "1rem";
  return "0.875rem";
};

const StandardHeader = () => {
  const { user } = useContext(UserContext);
  const organization = user?.orgName ? user : { orgName: "TeamMembers.ai" }; // Replace with actual organization data

  return (
    <Box sx={styles.header}>
      <Box
        onClick={() => (window.location.href = "/")}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title="Shine bright like a diamond!"
          TransitionComponent={Zoom}
          arrow
        >
          <DiamondIcon
            sx={{
              fontSize: 36,
              color: "#1A73E8",
              "&:hover": {
                animation: `${sparkle} 0.5s ease-in-out`,
              },
            }}
          />
        </Tooltip>
        <Box sx={{ ml: 1, lineHeight: 1.2, flex: 1, overflow: "hidden" }}>
          <Tooltip title={organization?.orgName} arrow>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                background: "linear-gradient(45deg, #1A73E8 30%, #34A853 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: getAdjustedFontSize(organization?.orgName),
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {organization?.orgName}
            </Typography>
          </Tooltip>

          <Typography
            variant="caption"
            sx={{
              opacity: 0.7,
              transition: "opacity 0.3s",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            AI Team Members
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  header: {
    display: "flex",
    width: "95%",
    alignItems: "center",
    padding: "8px 16px",
    backgroundColor: "transparent",
    height: "60px",
    borderRadius: STANDARD_BORDER_RADIUS,
  },
};

export default StandardHeader;
