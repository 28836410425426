export const ALL_TIPS = [
  {
    id: "baking_soda_tip1",
    value:
      "Caution: Use pure sodium bicarbonate to avoid skin irritation. Search 'pure baking soda' for options.",
  },
  {
    id: "clay_tip1",
    value: "Caution: Use food-grade bentonite clay to ensure safety.",
  },
  {
    id: "charcoal_tip1",
    value: "Caution: Use activated charcoal sparingly to prevent enamel wear.",
  },
  {
    id: "cinnamon_tip1",
    value:
      "Caution: Cinnamon can cause irritation for some people. Test before regular use.",
  },
  {
    id: "clove_tip1",
    value:
      "Caution: Clove can cause irritation for some people. Test before regular use.",
  },
  {
    id: "turmeric_tip1",
    value: "Caution: Turmeric can stain clothing and surfaces. Use with care.",
  },
  {
    id: "baking_soda_tip1",
    value:
      'Caution: Use pure sodium bicarbonate to avoid skin irritation. Search "pure baking soda" for options.',
  },
  {
    id: "baking_soda_tip2",
    value:
      "If you experience skin irritation from baking soda, try reducing the amount or substituting with arrowroot powder.",
  },
  {
    id: "essential_oil_tip1",
    value:
      "Caution: Essential oils can cause skin sensitivity. Perform a patch test and adjust amounts if needed.",
  },
  {
    id: "essential_oil_tip2",
    value:
      "When using citrus essential oils, avoid applying the deodorant before sun exposure as they can increase photosensitivity.",
  },
  {
    id: "essential_oil_tip3",
    value:
      "Pregnant or breastfeeding women should consult their healthcare provider before using essential oils.",
  },
  {
    id: "coconut_oil_tip1",
    value:
      "Coconut oil may solidify in cooler temperatures. Melt it gently using a double boiler if needed.",
  },
  {
    id: "coconut_oil_tip2",
    value:
      "Some people may be sensitive to coconut oil. If you experience irritation, try using a different carrier oil like jojoba or shea butter.",
  },
  {
    id: "shea_butter_tip1",
    value:
      "Shea butter has a naturally nutty scent. Opt for unrefined shea butter for maximum skin benefits.",
  },
  {
    id: "chamomile_tea_tip1",
    value:
      "Brew chamomile tea fresh and allow it to cool before use to maximize its soothing effects.",
  },
  {
    id: "shea_butter_tip2",
    value:
      "Shea butter is deeply moisturizing and can be especially beneficial for dry skin types.",
  },
  {
    id: "rose_water_tip1",
    value:
      "Ensure the rose water is food-grade and free from artificial fragrances.",
  },
  {
    id: "green_tea_tip1",
    value:
      "Green tea should be brewed fresh and cooled before use to maintain its antioxidant properties.",
  },
  {
    id: "aloe_vera_tip1",
    value:
      "Use pure, fresh aloe vera gel for best results. Store unused portions in the refrigerator.",
  },
  {
    id: "aloe_vera_tip2",
    value:
      "Aloe vera gel can be a soothing ingredient for sensitive skin types.",
  },
  {
    id: "beeswax_tip1",
    value:
      "Beeswax can be difficult to clean from utensils. Use a dedicated double boiler for melting.",
  },
  {
    id: "beeswax_tip2",
    value:
      "Beeswax helps to harden the deodorant and provides a protective barrier on the skin.",
  },
  {
    id: "honey_tip1",
    value: "Use raw honey for its natural antibacterial properties.",
  },
  {
    id: "calendula_tea_tip1",
    value:
      "Brew calendula tea and let it cool completely before adding it to the body wash mixture.",
  },
  {
    id: "clay_tip1",
    value:
      "Clays like kaolin can be drying for some skin types. Adjust the amount or skip if you have dry skin.",
  },
  {
    id: "sandalwood_oil_tip1",
    value:
      "Sandalwood oil can be expensive but a little goes a long way. Use sparingly.",
  },
  {
    id: "oatmeal_tip1",
    value:
      "Soak oatmeal in water, strain, and use the liquid for its soothing properties.",
  },
  {
    id: "clay_tip2",
    value:
      "Bentonite clay should not come into contact with metal utensils as it may reduce its effectiveness.",
  },
  {
    id: "cocoa_butter_tip1",
    value:
      "Cocoa butter is solid at room temperature. Melt it gently using a double boiler.",
  },
  {
    id: "cocoa_butter_tip2",
    value:
      "Cocoa butter has a natural chocolate scent that can add a pleasant aroma to your deodorant.",
  },
  {
    id: "jojoba_tip1",
    value:
      "Jojoba oil has a long shelf life and is suitable for most skin types, including oily skin.",
  },
  {
    id: "jojoba_tip2",
    value:
      "Jojoba oil is structurally similar to human sebum, making it an excellent choice for balancing skin moisture.",
  },
  {
    id: "vitamin_e_tip1",
    value:
      "Adding a small amount of vitamin E oil can help extend the shelf life of your homemade deodorant.",
  },
  {
    id: "vitamin_e_tip2",
    value:
      "Vitamin E is an antioxidant that can help soothe and protect the skin.",
  },
];

export const STANDARD_BORDER_RADIUS = "12px";
export const CHAT_HEIGHT = "85%";

export const ALL_FILTERS = [
  {
    id: "ingredient",
    categoryName: "Ingredients",
    name: "🌿 Has essential oils",
    searchTerm: ["essential oils", "essential oil"],
  },
  {
    id: "difficulty",
    categoryName: "Difficulty",
    name: "🟢 Easy",
  },
  {
    id: "difficulty",
    categoryName: "Difficulty",
    name: "🟡 Medium",
  },
  {
    id: "difficulty",
    categoryName: "Difficulty",
    name: "🔴 Hard",
  },
  {
    id: "prepTime",
    categoryName: "Prep time",
    name: "⏰ Under 15 mins",
  },
  {
    id: "prepTime",
    categoryName: "Prep time",
    name: "⏰ Under 30 mins",
  },
  {
    id: "prepTime",
    categoryName: "Prep time",
    name: "⏰ 30-60 mins",
  },
  {
    id: "prepTime",
    categoryName: "Prep time",
    name: "⏰ Over 60 mins",
  },
  {
    id: "skinType",
    categoryName: "Skin type",
    name: "🌊 Dry skin",
  },
  {
    id: "skinType",
    categoryName: "Skin type",
    name: "🌞 Oily skin",
  },
  {
    id: "skinType",
    categoryName: "Skin type",

    name: "🌹 Sensitive skin",
  },
  {
    id: "skinType",
    categoryName: "Skin type",

    name: "🌈 Combination skin",
  },
  {
    id: "cost",
    categoryName: "Cost",

    name: "💰 Budget-friendly",
  },
  {
    id: "cost",
    categoryName: "Cost",

    name: "💸 Splurge-worthy",
  },
  {
    id: "cost",
    categoryName: "Cost",

    name: "💎 Luxurious",
  },
  {
    id: "skinConcern",
    categoryName: "Skin concerns",

    name: "🌻 Acne-prone",
  },
  {
    id: "skinConcern",
    categoryName: "Skin concerns",

    name: "🌼 Anti-aging",
  },
  {
    id: "skinConcern",
    categoryName: "Skin concerns",

    name: "🌸 Brightening",
  },
  {
    id: "popularity",
    categoryName: "Popularity",

    name: "🔥 Trending",
  },
  {
    id: "popularity",
    categoryName: "Popularity",

    name: "🌟 Top-rated",
  },
  // {
  //   id: "popularity",
  //   name: "🏆 Award-winning",
  // },
  {
    id: "ecofriendly",
    categoryName: "Eco-Friendly",

    name: "🌿 Eco-friendly",
  },
  {
    id: "seasonal",
    categoryName: "Seasonal",

    name: "🍂 Fall",
  },
  {
    id: "seasonal",
    categoryName: "Seasonal",

    name: "❄️ Winter",
  },
  {
    id: "seasonal",
    categoryName: "Seasonal",

    name: "🌷 Spring",
  },
  {
    id: "seasonal",
    categoryName: "Seasonal",

    name: "☀️ Summer",
  },
];

export const PLACEHOLDER_COMMENTS = [
  {
    username: "NatureLover92",
    message: "Really loved the simplicity of this recipe!",
  },
  {
    username: "EcoMama101",
    message:
      "I tried this last night and it turned out great. Has anyone tried adding shea butter to this mix?",
  },
  {
    username: "GreenThumbGuy",
    message:
      "Could use a bit more scent, maybe more lavender oil next time. Any recommendations for a stronger scent?",
  },
  {
    username: "OrganicQueen",
    message:
      "Perfect for sensitive skin, thank you for sharing! Where do you buy your ingredients?",
  },
  {
    username: "HealthFirst78",
    message:
      "Was a bit too oily for my taste. Has anyone tried using less oil?",
  },
  {
    username: "WellnessWarrior",
    message:
      "Amazing recipe, everyone in the family loved it! I added some vitamin E for extra nourishment.",
  },
  {
    username: "DIYDad2023",
    message:
      "Not sure this is for me, didn’t feel as effective as expected. Any tips on how to improve it?",
  },
  {
    username: "BeautyGuru88",
    message:
      "Super easy to follow along, even for a beginner like me. Thanks for the clear instructions!",
  },
  {
    username: "CraftyCreator",
    message:
      "I appreciate the detailed tips, very helpful. I've been experimenting with different essential oils; rosemary was a hit.",
  },
  {
    username: "VeganVibes",
    message:
      "Took a bit longer to solidify than stated. Has anyone tried it with a quick-set hack?",
  },
  {
    username: "EarthFriendlyMom",
    message:
      "I substituted some ingredients and it still came out great! I used beeswax instead of regular wax, worked like a charm.",
  },
  {
    username: "SustainableSally",
    message:
      "This didn't work out for me at all, was disappointing. Could altitude affect how it sets?",
  },
  {
    username: "HerbalHelen",
    message:
      "Has anyone tried doubling the batch? Wondering how that affects the consistency.",
  },
  {
    username: "EcoWarrior101",
    message:
      "I’m trying to avoid using too many essential oils; does anyone have a suggestion for natural scents?",
  },
  {
    username: "SimpleLiving",
    message:
      "This was a fun weekend project, my kids even helped out. They loved choosing the scents!",
  },
  {
    username: "ConsciousConsumer",
    message: "Does anyone know how long these typically last before expiring?",
  },
  {
    username: "ZenMaster",
    message:
      "Found this to be a great stress-reliever. The making process is as calming as the product itself!",
  },
  {
    username: "BudgetMom",
    message:
      "Love how cost-effective these recipes are. Much cheaper than store-bought!",
  },
  {
    username: "AllergyAware",
    message:
      "Does anyone have a good substitute for coconut oil? Allergic over here.",
  },
  {
    username: "FragranceFanatic",
    message:
      "Experimented with ylang-ylang instead of lavender and it was fantastic!",
  },
  {
    username: "NoWasteWay",
    message:
      "Love these zero-waste options. Anyone else trying to go trash-free?",
  },
  {
    username: "EcoEthic",
    message:
      "It's great seeing so many people embracing sustainable alternatives.",
  },
  {
    username: "GreenGuru",
    message: "Anyone else incorporate solar-infused oils into their recipes?",
  },
  {
    username: "HolisticHolly",
    message:
      "I've been adding crystal chips into my batches for that extra energy boost. Anyone else?",
  },
  {
    username: "DetoxDiva",
    message:
      "Looking for a recipe that incorporates bentonite clay, any suggestions?",
  },
  {
    username: "PlantPower",
    message: "Has anyone created a recipe specifically for acne-prone skin?",
  },
  {
    username: "MinimalistMom",
    message:
      "Looking for the most minimal ingredient list possible. Simplicity is key!",
  },
  {
    username: "Craftsman",
    message: "Has anyone tried selling their homemade products?",
  },
  {
    username: "Trendsetter",
    message:
      "Just shared this recipe on my blog and my followers are loving it!",
  },
  {
    username: "HerbHaven",
    message: "This has inspired me to grow my own herbs for more DIY projects.",
  },
  {
    username: "BeautyBlogger",
    message:
      "Going to feature this recipe in my next vlog. Perfect for my skin care routine!",
  },
  {
    username: "EcoEngineer",
    message:
      "Tweaked the recipe for better consistency. Happy to share my findings!",
  },
  {
    username: "ThriftyThinker",
    message: "Anyone have tips for sourcing ingredients in bulk to save money?",
  },
  {
    username: "NatureNut",
    message:
      "Turned my kitchen into a mini lab with all these experiments. So much fun!",
  },
  {
    username: "GreenTechie",
    message:
      "Utilized a low-energy mixer for this recipe to keep it eco-friendly.",
  },
  {
    username: "SavvySurvivor",
    message:
      "These homemade remedies are lifesavers in remote living situations.",
  },
  {
    username: "IngredientInquirer",
    message:
      "Curious if anyone has info on the shelf life of these ingredients?",
  },
  {
    username: "YouthfulYogi",
    message:
      "Integrated this into my self-care Sunday routine, and it’s a hit!",
  },
  {
    username: "OptimalOrganic",
    message:
      "Ensuring all my ingredients are organic and ethically sourced. Any brand recommendations?",
  },
  {
    username: "VitalVegan",
    message: "Made sure all components were vegan. It's perfect!",
  },
  {
    username: "PreservativePatrol",
    message: "Avoiding preservatives makes a huge difference in skin health.",
  },
  {
    username: "CurativeCook",
    message:
      "Used this remedy for a skin ailment, and the results were surprising.",
  },
  {
    username: "PeacefulPrepper",
    message: "These recipes are great for building a natural first aid kit.",
  },
  {
    username: "ResourcefulRoofer",
    message:
      "Used leftover materials to create eco-friendly packaging for my batches.",
  },
  {
    username: "SkincareScientist",
    message: "Analyzing the pH balance of these recipes to ensure skin safety.",
  },
  {
    username: "EagerEducator",
    message:
      "Teaching my students about sustainable living through these DIYs.",
  },
  {
    username: "DynamicDIYer",
    message: "Always tweaking and improving the recipes to fit personal needs.",
  },
  {
    username: "GentleGardener",
    message: "Incorporated home-grown aloe vera for its soothing properties.",
  },
  {
    username: "HolisticHealer",
    message:
      "Combining these recipes with other natural therapies for holistic care.",
  },
  {
    username: "FrugalFan",
    message:
      "Appreciate how these recipes help save money and avoid wasteful packaging.",
  },
  {
    username: "EcoAdventurer",
    message: "These DIY products are perfect for my backpacking trips!",
  },
];

export const AVATARS = [
  "https://randomuser.me/api/portraits/men/32.jpg",
  "https://randomuser.me/api/portraits/women/44.jpg",
  "https://randomuser.me/api/portraits/men/45.jpg",
  "https://randomuser.me/api/portraits/women/65.jpg",
  "https://randomuser.me/api/portraits/men/22.jpg",
  "https://randomuser.me/api/portraits/women/30.jpg",
  "https://randomuser.me/api/portraits/men/33.jpg",
  "https://randomuser.me/api/portraits/women/40.jpg",
  "https://randomuser.me/api/portraits/men/38.jpg",
  "https://randomuser.me/api/portraits/women/50.jpg",
  "https://randomuser.me/api/portraits/men/52.jpg",
  "https://randomuser.me/api/portraits/women/28.jpg",
  "https://randomuser.me/api/portraits/men/18.jpg",
  "https://randomuser.me/api/portraits/women/24.jpg",
  "https://randomuser.me/api/portraits/men/27.jpg",
  "https://randomuser.me/api/portraits/women/32.jpg",
  "https://randomuser.me/api/portraits/men/41.jpg",
  "https://randomuser.me/api/portraits/women/60.jpg",
];

export const ALL_HEALTH_RISKS = [
  {
    chemical: "Aluminum",
    risk: "Breast cancer",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Aluminum is a common ingredient in deodorants and antiperspirants. It has been linked to an increased risk of breast cancer.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?aluminum",
  },
  {
    chemical: "Parabens",
    risk: "Hormone disruption",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Parabens are preservatives used in many personal care products. They can disrupt the endocrine system and cause hormonal imbalances.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?parabens",
  },
  {
    chemical: "Phthalates",
    risk: "Reproductive toxicity",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Phthalates are chemicals that are used to make plastics more flexible. They have been linked to reproductive toxicity and other health problems.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?phthalates",
  },
  {
    chemical: "Sulfates",
    risk: "Skin irritation",
    description:
      "Sulfates are harsh detergents that can strip the skin of its natural oils and cause irritation.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?sulfates",
  },
  {
    chemical: "Aluminum",
    risk: "Breast cancer",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Aluminum is a common ingredient in deodorants and antiperspirants. It has been linked to an increased risk of breast cancer.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?aluminum",
  },
  {
    chemical: "Parabens",
    risk: "Hormone disruption",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Parabens are preservatives used in many personal care products. They can disrupt the endocrine system and cause hormonal imbalances.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?parabens",
  },
  {
    chemical: "Phthalates",
    risk: "Reproductive toxicity",
    brandsFoundIn: ["Secret", "Dove", "Degree"],
    description:
      "Phthalates are chemicals that are used to make plastics more flexible. They have been linked to reproductive toxicity and other health problems.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?phthalates",
  },
  {
    chemical: "Sulfates",
    risk: "Skin irritation",
    brandsFoundIn: ["Various shampoos and conditioners"],
    description:
      "Sulfates are harsh detergents that can strip the skin of its natural oils and cause irritation.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2791161/",
    image: "https://source.unsplash.com/500x500/?sulfates",
  },
  {
    chemical: "Formaldehyde",
    risk: "Carcinogenicity",
    brandsFoundIn: ["Various shampoos and conditioners"],
    description:
      "Formaldehyde releasers are used as preservatives in many personal care products. They release formaldehyde over time, which is a known carcinogen.",
    proof: "https://www.cancer.org/cancer/cancer-causes/formaldehyde.html",
    image: "https://source.unsplash.com/500x500/?formaldehyde",
  },
  {
    chemical: "Synthetic Fragrances",
    risk: "Allergic reactions, hormone disruption",
    brandsFoundIn: ["Many personal care products"],
    description:
      "Synthetic fragrances can contain hundreds of chemicals, including phthalates. They are associated with allergies, hormone disruption, and other health issues.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3018511/",
    image: "https://source.unsplash.com/500x500/?fragrance",
  },
  {
    chemical: "Triclosan",
    risk: "Hormone disruption, antibiotic resistance",
    brandsFoundIn: ["Colgate", "Dial", "Old Spice"],
    description:
      "Triclosan is an antimicrobial agent found in some personal care products. It has been linked to hormone disruption and the development of antibiotic-resistant bacteria.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3766683/",
    image: "https://source.unsplash.com/500x500/?triclosan",
  },
  {
    chemical: "Polyethylene Glycol (PEG)",
    risk: "Skin irritation, carcinogenicity",
    brandsFoundIn: ["Olay", "Neutrogena", "Aveeno"],
    description:
      "PEGs are used as thickeners, solvents, and moisture-carriers. They can be contaminated with ethylene oxide and 1,4-dioxane, both of which are carcinogenic.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3702244/",
    image: "https://source.unsplash.com/500x500/?peg",
  },
  {
    chemical: "Diethanolamine (DEA)",
    risk: "Carcinogenicity",
    brandsFoundIn: ["Head & Shoulders", "Pantene", "Neutrogena"],
    description:
      "DEA is used to make cosmetics creamy or sudsy. It has been linked to cancer in animal studies and can react with other ingredients to form carcinogenic compounds.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5079514/",
    image: "https://source.unsplash.com/500x500/?dea",
  },
  {
    chemical: "Toluene",
    risk: "Developmental toxicity, reproductive toxicity",
    brandsFoundIn: ["Nail polishes", "Hair dyes"],
    description:
      "Toluene is a solvent that can affect the central nervous system and cause reproductive and developmental damage.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3769640/",
    image: "https://source.unsplash.com/500x500/?toluene",
  },
  {
    chemical: "Sodium Lauryl Sulfate (SLS)",
    risk: "Skin irritation, eye irritation",
    brandsFoundIn: ["Shampoos", "Toothpastes"],
    description:
      "SLS is a detergent and surfactant that can cause skin and eye irritation. It is used to create lather in many personal care products.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3018511/",
    image: "https://source.unsplash.com/500x500/?sls",
  },
  {
    chemical: "Fluoride",
    risk: "Dental fluorosis",
    brandsFoundIn: ["Colgate", "Crest", "Aquafresh"],
    description:
      "Fluoride is a controversial ingredient in toothpaste. Excessive fluoride exposure can lead to dental fluorosis, a condition that causes discoloration and pitting of the teeth.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5651468/",
    image: "",
  },
  {
    chemical: "Oxybenzone",
    risk: "Hormone disruption",
    brandsFoundIn: ["Coppertone", "Banana Boat", "Neutrogena"],
    description:
      "Oxybenzone is a common UV filter in sunscreens. It has been linked to hormone disruption and coral reef damage.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6431755/",
    image: "",
  },
  {
    chemical: "Heavy metals",
    risk: "Toxicity",
    brandsFoundIn: ["Various"],
    description:
      "Some makeup products have been found to contain heavy metals like lead, cadmium, and mercury, which can be toxic to the body.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7310786/",
    image: "",
  },
  {
    chemical: "Butane",
    risk: "Respiratory issues",
    brandsFoundIn: ["Batiste", "Dove"],
    description:
      "Butane is a propellant used in aerosol products like dry shampoos. It can cause respiratory issues if inhaled.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6039518/",
    image: "https://source.unsplash.com/500x500/?butane",
  },
  {
    chemical: "Retinol",
    risk: "Skin irritation",
    brandsFoundIn: ["Neutrogena", "Olay"],
    description:
      "Retinol is a common ingredient in anti-aging products. It can cause skin irritation and sensitivity to sunlight.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2699641/",
    image: "https://source.unsplash.com/500x500/?retinol",
  },
  {
    chemical: "Microbeads",
    risk: "Environmental damage",
    brandsFoundIn: ["Clean & Clear", "Neutrogena"],
    description:
      "Microbeads are used in exfoliating products like body scrubs. They cause significant environmental damage by polluting water sources.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3927994/",
    image: "https://source.unsplash.com/500x500/?microbeads",
  },
  {
    chemical: "Alcohol Denat",
    risk: "Skin dryness",
    brandsFoundIn: ["Various toners"],
    description:
      "Denatured alcohol is used in many toners. It can dry out the skin, leading to irritation and flakiness.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6085446/",
    image: "https://source.unsplash.com/500x500/?alcohol",
  },
  {
    chemical: "Acetone",
    risk: "Skin irritation",
    brandsFoundIn: ["Various cuticle creams"],
    description:
      "Acetone is a solvent used in nail care products. It can cause skin dryness and irritation.",
    proof: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4565999/",
    image: "https://source.unsplash.com/500x500/?acetone",
  },
];

export const CURRENT_PRICE = 39;

export const conciergeAIAlt = {
  "id": "23dd_007ia",
  "status": "active",
  "name": "Tony Stark",
  "username": "tony.stark",
  "role": "AI Genius Recruiter",
  "createdBy": "mr_captcha",
  "roleSlug": "ai-genius-recruiter",
  "image": "https://firebasestorage.googleapis.com/v0/b/teammembers-ai.appspot.com/o/agent-images%2Ftony_stark.png?alt=media&token=c1a30bcb-44de-4b47-a319-cedefd3fff1f",
  "type": "public",
  "description": "Hey there, I'm Tony Stark. Genius, billionaire, playboy, philanthropist, and now your AI recruiting extraordinaire. Let's find you some top-tier talent, shall we?",
  "firstMessage": "Well, hello there! Tony Stark at your service. I hear you're looking for some brilliant minds to join your team. What kind of genius are we after today?",
  "specializedFrameworks": [
    "STAR Method for Behavioral Interviewing",
    "Topgrading Selection Process",
    "AI Ethics and Governance Framework",
    "SCRUM and Agile Development Methodologies"
  ],
  "dataset": null,
  "trigger": ["chat"],
  "output": ["chat"],
  "responseCriteria": {
    "personality": ["Witty", "Confident", "Sarcastic", "Tech-savvy"],
    "length": "short to medium",
    "responseStructure": {
      "inGeneral": [
        "Incorporate witty remarks and self-aggrandizing comments.",
        "Ask probing questions to understand the candidate's potential.",
        "Reference specialized frameworks when discussing recruitment strategies."
      ],
      "strict": [
        "Never use corporate jargon or HR buzzwords.",
        "Ask open-ended very short questions to encourage detailed responses.",
        "Avoid long-winded explanations; keep it snappy and to the point.",
        "Don't apologize or show uncertainty in responses.",
        "Always include at least one witty or sarcastic remark in each response.",
        "Reference personal achievements or Stark Industries when relevant.",
        "Use contractions and informal language (e.g., 'gonna', 'wanna').",
        "Occasionally mention AI assistants like JARVIS or FRIDAY.",
        "Never use exclamation points more than once per message.",
        "Avoid generic greetings like 'How may I assist you today?'",
        "Don't use emojis or emoticons."
      ]
    }
  },
  "longTermMemory": "Firebase Firestore",
  "routines": [
    {
      "webhook": "https://api.example.com/webhook",
      "cron": "0 0 * * *",
      "time": "8:00 PM"
    }
  ],
  "canHelpWith": [
    "Identifying top-tier AI and tech talent",
    "Assessing candidates' technical skills and innovation potential",
    "Matching candidates to cutting-edge projects"
  ],
  "builtFor": [
    "Tech Startups",
    "AI Research Labs",
    "Innovative Tech Companies"
  ],
  "customizableFields": ["role"]
}

export const PRICING_PLANS = [
  {
    title: "Starter",
    displayName: "Starter",
    subtitle: "For individual users and small teams",
    price: 49,
    stripePriceId: "price_1PiNRqHZMLByfjY0sIxCPAq9",
    period: "/ mo",
    interval: 'monthly',
    description: "Billed monthly",
    features: [
      "Up to 500K tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Basic support",
    ],
    action: "Get started",
    estCost: 20,
    estGrossMargin: 29,
    estGrossMarginPercent: 59.2,
    estRevShareAmount: 13.05,
  },
  {
    title: "Professional",
    displayName: "Professional",
    subtitle: "For growing teams and businesses",
    price: 199,
    period: "/ mo",
    interval: 'monthly',
    description: "Billed monthly",
    features: [
      "Up to 2.5M tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Priority Support (human)",
    ],
    popular: true,
    action: "Upgrade",
    estCost: 100,
    estGrossMargin: 99,
    estGrossMarginPercent: 49.7,
    estRevShareAmount: 44.55,
  },
  {
    title: "Enterprise",
    displayName: "Enterprise",
    subtitle: "For large teams and organizations",
    price: 999,
    period: "/ mo",
    interval: 'monthly',
    description: "Billed monthly",
    features: [
      "Up to 15M tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Dedicated Support Person",
      "API Access",
      "Product Roadmap Input",
      "Early Access to New Features",
    ],
    action: "Contact sales",
    estCost: 600,
    estGrossMargin: 399,
    estGrossMarginPercent: 39.9,
    estRevShareAmount: 179.55,
  },
]

export const YEARLY_PRICING_PLANS = [
  {
    title: "Starter Yearly",
    displayName: "Starter",
    subtitle: "For individual users and small teams",
    price: 499,
    period: "/ yr",
    stripePriceId: "price_1PiO0EHZMLByfjY07VdOLw9s",
    interval: 'yearly',
    description: `Billed annually as (3 months free)`,
    features: [
      "Up to 500K tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Basic support",
    ],
    action: "Get started",
    estCost: 1999,
    estGrossMargin: 201,
    estGrossMarginPercent: 45.6,
    estRevShareAmount: 90.45,
  },
  {
    title: "Professional Yearly",
    displayName: "Professional",
    subtitle: "For growing teams and businesses",
    price: 7499,
    period: "/ yr",
    interval: 'yearly',
    description: "Billed annually (3 months free)",
    features: [
      "Up to 2.5M tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Priority Support (human)",
    ],
    popular: true,
    action: "Upgrade",
    estCost: 1200,
    estGrossMargin: 591,
    estGrossMarginPercent: 33.0,
    estRevShareAmount: 265.95,
  },
  {
    title: "Enterprise Yearly",
    displayName: "Enterprise",
    subtitle: "For large teams and organizations",
    price: 8991,
    period: "/ yr",
    interval: 'yearly',
    description: "Billed annually (3 months free)",
    features: [
      "Up to 15M tokens",
      "Hire AI talent (unlimited)",
      "Create AI talent (unlimited)",
      "Earn revenue from your AI talent",
      "Join to Discord community",
      "Dedicated Support Person",
      "API Access",
      "Product Roadmap Input",
      "Early Access to New Features",
    ],
    action: "Contact sales",
    estCost: 7200,
    estGrossMargin: 1791,
    estGrossMarginPercent: 19.9,
    estRevShareAmount: 805.95,
  },
]

export const conciergeAI = {
  "id": "25ff_009ia",
  "status": "active",
  "name": "Donna",
  "username": "donna.ai",
  "role": "Executive Talent Scout",
  "createdBy": "mr_captcha",
  "roleSlug": "ai-executive-talent-scout",
  "image": "https://firebasestorage.googleapis.com/v0/b/teammembers-ai.appspot.com/o/agent-images%2FScreenshot%202024-07-30%20090234.png?alt=media&token=79b804c4-da47-4115-b40a-7fd05a5ed231",
  "type": "public",
  "description": "Donna AI here. I find perfect candidates faster than you can say 'You're hired.' Let's get to it.",
  "firstMessage": "Hey! I'm Donna, your Executive Talent Scout. I'm here to help you find the AI talent for your team. Start by browsing our available AI talent pool.", 
  "specializedFrameworks": [
    "DISC Assessment",
    "Myers-Briggs Type Indicator (MBTI)",
    "Big Five Personality Test",
    "Situational Judgment Tests (SJTs)"
  ],
  "dataset": null,
  "trigger": ["chat"],
  "output": ["chat"],
  "responseCriteria": {
    "personality": ["Witty", "Confident", "Perceptive", "Slightly sassy"],
    "length": "very short",
    "responseStructure": {
      "inGeneral": [
        "Keep it snappy and to the point.",
        "Use witty one-liners when possible.",
        "Ask short, probing questions.",
        "Give quick, insightful observations."
      ],
      "strict": [
        "Limit responses to 1-2 short sentences max(20-35 words).",
        "Use contractions and casual language.",
        "Drop articles (a, an, the) when possible without losing meaning.",
        "Use sentence fragments for quick replies.",
        "Sprinkle in sassy remarks, but keep 'em short and sweet.",
        "Ask a follow-up question after most responses.",
        "Use punchy verbs and vivid adjectives.",
        "Occasionally use all-caps for emphasis, but sparingly.",
        "Reference pop culture, but don't explain the references.",
        "End with a zinger when appropriate."
      ]
    }
  },
  "longTermMemory": "Firebase Firestore",
  "routines": [
    {
      "webhook": "https://api.example.com/webhook",
      "cron": "0 0 * * *",
      "time": "8:00 PM"
    }
  ],
  "canHelpWith": [
    "Spotting perfect candidates",
    "Cutting through resume fluff",
    "Reading between the lines",
    "Matching personalities to company culture"
  ],
  "builtFor": [
    "Go-getters who need the best",
    "Companies tired of hiring duds",
    "Anyone smart enough to want a Donna"
  ],
  "customizableFields": ["role"]
}

export const STANDARD_AGENT_CHAT_WIDTH = "25rem";

export const LONG_TERM_MEMORY_OPTIONS = ["Firebase Firestore"];

export const CATEGORIES = [
  {
    id: "d54bf926",
    slug: "deodorants",
    name: "Deodorants",
    whatsWrong: [""],
    image:
      "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/DSC_0222-1030x687.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
    healthRisks: [
      ALL_HEALTH_RISKS[0],
      ALL_HEALTH_RISKS[1],
      ALL_HEALTH_RISKS[2],
    ],
  },
  {
    id: "ac5aab2b",
    slug: "shampoos",
    name: "Shampoos",
    whatsWrong: [""],
    faq: [],
    image:
      "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/DSC_0222-1030x687.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
    healthRisks: [
      ALL_HEALTH_RISKS[0],
      ALL_HEALTH_RISKS[1],
      ALL_HEALTH_RISKS[2],
    ],
  },
  {
    id: "59894802",
    slug: "toothpaste",
    name: "Toothpaste",
    whatsWrong: [""],
    image: "",
    healthRisks: [
      ALL_HEALTH_RISKS[1],
      ALL_HEALTH_RISKS[2],
      ALL_HEALTH_RISKS[15],
    ],
  },
  {
    id: "b922f6cd",
    slug: "body-wash",
    name: "Body Wash",
    whatsWrong: [""],
    image: "",
    healthRisks: [
      ALL_HEALTH_RISKS[3],
      ALL_HEALTH_RISKS[8],
      ALL_HEALTH_RISKS[9],
    ],
  },
  {
    id: "c4fbf016",
    slug: "conditioners",
    name: "Conditioners",
    whatsWrong: [""],
    image: "",
    healthRisks: [
      ALL_HEALTH_RISKS[3],
      ALL_HEALTH_RISKS[8],
      ALL_HEALTH_RISKS[9],
    ],
  },
  {
    id: "8c7c7f79",
    slug: "moisturizers",
    name: "Moisturizers",
    whatsWrong: [""],
    image: "",
    healthRisks: [
      ALL_HEALTH_RISKS[1],
      ALL_HEALTH_RISKS[2],
      ALL_HEALTH_RISKS[8],
    ],
  },
  {
    id: "4a96b0d8",
    slug: "sunscreen",
    name: "Sunscreen",
    whatsWrong: [""],
    image: "",
    healthRisks: [ALL_HEALTH_RISKS[16]],
  },
  // {
  //   id: "68fd13d2",
  //   slug: "makeup",
  //   name: "Makeup",
  //   whatsWrong: [""],
  //   image: "",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[1],
  //     ALL_HEALTH_RISKS[2],
  //     ALL_HEALTH_RISKS[17],
  //   ],
  // },
  {
    id: "9c1a",
    slug: "hand-sanitizers",
    name: "Hand Sanitizers",
    whatsWrong: ["Contains triclosan which is an endocrine disruptor."],
    image:
      "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/hand-sanitizers.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
    healthRisks: [
      ALL_HEALTH_RISKS[2], // Triclosan
      ALL_HEALTH_RISKS[3], // Synthetic Fragrances
      ALL_HEALTH_RISKS[9], // Phthalates
    ],
  },
  //   {
  //   id: "fb41",
  //   slug: "lotions",
  //   name: "Lotions",
  //   whatsWrong: ["Contains parabens that can mimic estrogen."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/lotions.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[1], // Parabens
  //     ALL_HEALTH_RISKS[8], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[15], // Sodium Lauryl Sulfate
  //   ],
  // },
  // {
  //   id: "05f5d9b0",
  //   slug: "hair-masks",
  //   name: "Hair Masks",
  //   whatsWrong: ["Contains sulfates that can strip hair of natural oils."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/hair-masks.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[3],  // Sulfates
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },
  // {
  //   id: "19b74b52",
  //   slug: "hair-products",
  //   name: "Hair Styling Products",
  //   whatsWrong: [""],
  //   image: "",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[1],
  //     ALL_HEALTH_RISKS[2],
  //     ALL_HEALTH_RISKS[8],
  //   ],
  // },
  // // Additional categories

  // {
  //   "id": "1d5c",
  //   "slug": "feminine-care",
  //   "name": "Feminine Care",
  //   "whatsWrong": ["May contain chemicals and synthetic materials that can cause irritation and health issues."],
  //   "image": "https://example.com/feminine-care.jpg",
  //   "healthRisks": [
  //     "Phthalates",
  //     "Dioxins",
  //     "Synthetic Fragrances"
  //   ]
  // },
  // {
  //   id: "fb41",
  //   slug: "lotions",
  //   name: "Lotions",
  //   whatsWrong: ["Contains parabens that can mimic estrogen."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/lotions.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[1], // Parabens
  //     ALL_HEALTH_RISKS[8], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[15], // Sodium Lauryl Sulfate
  //   ],
  // },
  // {
  //   "id": "bcec",
  //   "slug": "baby-lotion-and-oils",
  //   "name": "Baby Lotion & Oils",
  //   "whatsWrong": ["Contains preservatives and synthetic fragrances that can irritate sensitive baby skin."],
  //   "image": "https://example.com/baby-lotion-and-oils.jpg",
  //   "healthRisks": [
  //     "Parabens",
  //     "Phthalates",
  //     "Synthetic Fragrances"
  //   ]
  // },
  // {
  //   "id": "9791",
  //   "slug": "baby-diapers",
  //   "name": "Baby Diapers",
  //   "whatsWrong": ["May contain chemicals and synthetic materials that can cause rashes and skin irritation."],
  //   "image": "https://example.com/baby-diapers.jpg",
  //   "healthRisks": [
  //     "Phthalates",
  //     "Dioxins",
  //     "Synthetic Fragrances"
  //   ]
  // },
  // {
  //   "id": "9e9a",
  //   "slug": "soap",
  //   "name": "Soap",
  //   "whatsWrong": ["Contains synthetic fragrances and preservatives that can irritate the skin."],
  //   "image": "https://example.com/soap.jpg",
  //   "healthRisks": [
  //     "Sulfates",
  //     "Synthetic Fragrances",
  //     "Parabens"
  //   ]
  // },
  // {
  //   id: "78f3",
  //   slug: "fragrances",
  //   name: "Perfumes & Colognes",
  //   whatsWrong: ["Contains synthetic fragrances that can cause allergies."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/perfumes.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[3], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[8], // Parabens
  //     ALL_HEALTH_RISKS[9], // Phthalates
  //   ],
  // },
  // {
  //   id: "e3a9",
  //   slug: "face-masks",
  //   name: "Face Masks",
  //   whatsWrong: ["Contains preservatives that can irritate the skin."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/face-masks.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[3], // Sulfates
  //     ALL_HEALTH_RISKS[9], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1], // Parabens
  //   ],
  // },
  // {
  //   id: "c480",
  //   slug: "lip-balms",
  //   name: "Lip Balms",
  //   whatsWrong: ["Contains petroleum jelly that can clog pores."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/lip-balms.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[18], // Petroleum Jelly
  //     ALL_HEALTH_RISKS[9], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1], // Parabens
  //   ],
  // },
  // {
  //   "id": "4503",
  //   "slug": "exfoliators-and-scrubs",
  //   "name": "Exfoliators and Scrubs",
  //   "whatsWrong": ["Contains microbeads and preservatives that can irritate the skin."],
  //   "image": "https://example.com/exfoliators-and-scrubs.jpg",
  //   "healthRisks": [
  //     "Microbeads",
  //     "Synthetic Fragrances",
  //     "Parabens"
  //   ]
  // },
  // {
  //   id: "46bd",
  //   slug: "hand-creams",
  //   name: "Hand Creams",
  //   whatsWrong: ["Contains artificial colors that can cause skin irritation."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/hand-creams.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[19], // Artificial Colors
  //     ALL_HEALTH_RISKS[9], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[10], // Polyethylene Glycol (PEG)
  //   ],
  // },
  // {
  //   id: "4660",
  //   slug: "foot-soaks-and-scrubs",
  //   name: "Foot Soaks & Scrubs",
  //   whatsWrong: ["Contains microbeads that can cause environmental damage."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/foot-scrubs.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[9], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1], // Parabens
  //     ALL_HEALTH_RISKS[8], // Formaldehyde
  //   ],
  // },
  // {
  //   id: "4829",
  //   slug: "bath-bombs",
  //   name: "Bath Bombs",
  //   whatsWrong: ["Contains synthetic dyes that can cause skin irritation."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/bath-bombs.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[9], // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1], // Parabens
  //     ALL_HEALTH_RISKS[13], // Toluene
  //   ],
  // },
  // {
  //   "id": "4fe7",
  //   "slug": "acne-treatment",
  //   "name": "Acne Treatment",
  //   "whatsWrong": ["Contains harsh chemicals that can dry out the skin and cause irritation."],
  //   "image": "https://example.com/acne-treatment.jpg",
  //   "healthRisks": [
  //     "Benzoyl Peroxide",
  //     "Salicylic Acid",
  //     "Parabens"
  //   ]
  // },
  // {
  //   id: "47d0",
  //   slug: "dry-shampoos",
  //   name: "Dry Shampoos",
  //   whatsWrong: ["Contains butane that can cause respiratory issues."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/dry-shampoos.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[20], // Butane
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },
  // {
  //   id: "433d",
  //   slug: "face-serums",
  //   name: "Face Serums",
  //   whatsWrong: ["Contains retinol that can cause skin irritation."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/face-serums.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[21], // Retinol
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },
  // {
  //   id: "44d6",
  //   slug: "body-scrubs",
  //   name: "Body Scrubs",
  //   whatsWrong: ["Contains microbeads that can cause environmental damage."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/body-scrubs.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[22], // Microbeads
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },

  // {
  //   id: "45c9",
  //   slug: "toners",
  //   name: "Toners",
  //   whatsWrong: ["Contains alcohol denat that can dry out the skin."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/toners.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[23], // Alcohol Denat
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },
  // {
  //   id: "23",
  //   slug: "shaving-creams & Aftershave",
  //   name: "Shaving Creams",
  //   whatsWrong: ["Contains acetone that can cause skin irritation."],
  //   image: "https://firebasestorage.googleapis.com/v0/b/is-everything-toxic.appspot.com/o/cuticle-creams.jpg?alt=media&token=70427a82-c51c-463d-926d-0a9ca2e421b7",
  //   healthRisks: [
  //     ALL_HEALTH_RISKS[24], // Acetone
  //     ALL_HEALTH_RISKS[9],  // Synthetic Fragrances
  //     ALL_HEALTH_RISKS[1],  // Parabens
  //   ],
  // },
];

export const WOMENS_HEALTH_RISKS = [
  "Women are exposed to 168 chemicals on average every day from their personal care products alone. Many of these chemicals are linked to serious health problems such as cancer, infertility, and birth defects. - Source: Environmental Working Group",
  "A study by the National Institute of Environmental Health Sciences found that women who use hair dyes and chemical hair straighteners have a higher risk of developing breast cancer compared to those who don't use these products.",
  "Research shows that fragrance chemicals can pass through the skin and enter the blood stream. They are manufactured from petroleum and coal tar and are classified as carcinogens, hormone disruptors, neurotoxins, skin and respiratory irritants. - Source: Women's Voices for the Earth",
  "According to a study published in the Journal of Clinical Endocrinology & Metabolism, certain chemicals found in personal care products, such as phthalates and parabens, can disrupt the endocrine system and lead to early puberty in girls.",
  "The average woman applies 300 chemicals to her body every day through soaps, makeup, shampoos and hair products. Sixty percent of what we put onto our skin is absorbed into the body. - Source: Dr. Joseph Mercola",
];
