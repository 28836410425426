import React, { useContext, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import toast from "react-simple-toasts";
import UserContext from "../context/userContext";
import { logoutFirebase } from "../utils/helperMethods";
import { firestore } from "../firebaseConfig";
import axios from "axios";

const CancellationModal = () => {
  const theme = useTheme();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!user?.id) {
      toast("User not authenticated.");
      return;
    }

    setLoading(true)
    try {
      const response = await axios.post(
        "https://us-central1-teammembers-ai.cloudfunctions.net/cancelSubscriptionNow",
        {
          uid: user.id,
          reason,
        }
      );

      const result = await response.data

      if (result.success) {
        toast("Your subscription has been canceled.");
        setGlobalState({ ...globalState, showCancellationModal: false });

        // fyi - dont save here, happening in backend
        setUser({ ...user, premiumUser: null });
      } else {
        toast(result.message || "Failed to cancel subscription.");
        // console.log("Failed to cancel subscription:", result);
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message || "Failed to cancel subscription."}`);
      } else if (error.request) {
        // The request was made but no response was received
        toast("Network error. Please check your internet connection and try again.");
      } else {
        // Something happened in setting up the request that triggered an Error
        toast("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={globalState?.showCancellationModal}
      onClose={() =>
        setGlobalState({ ...globalState, showCancellationModal: false })
      }
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: isMobile ? "0px" : "8px",
          width: isMobile ? "100%" : "500px",
          padding: "24px",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Cancel Subscription
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please let us know why you are canceling your subscription:
        </Typography>
        <TextField
          label="Reason for cancellation"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          sx={{ mb: 3 }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
          <Button
            variant="outlined"
            onClick={() =>
              setGlobalState({ ...globalState, showCancellationModal: false })
            }
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            sx={{ backgroundColor: "red" }}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress /> : "CANCEL MY SUBSCRIPTON"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancellationModal;
