import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import { GlobalStateProvider } from "./context/globalStateContext";
import { UserContextProvider } from "./context/userContext";
import "./global.css";
import FinishSignUp from "./pages/FinishUpPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Success from "./pages/Success";
import Profile from "./pages/Profile";
import Hire from "./pages/Hire";
import Edit from "./pages/Edit";
import Pricing from "./pages/Pricing";

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStateProvider>
          <UserContextProvider>
            <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/hire/:role/:username" element={<Hire />} />
            <Route path="/edit/:role/:username" element={<Edit />} />
            <Route path="/finishSignUp" element={<FinishSignUp />} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="/pricing" element={<Pricing />} />
              <Route path="/success" element={<Success />} />
              <Route path="/profile" element={<Profile />} />
              {/* PRESET WITH A FILTER - pSEO (nomadList appproach)  */}
              <Route path="/:filter" element={<HomePage />} />
            </Routes>
          </UserContextProvider>
        </GlobalStateProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
