import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import { firebase } from "../firebaseConfig"; // Import firebase from your config file
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { useLocation } from "react-router-dom";

const SignupModal = () => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Check if the email already exists
      const signInMethods = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email);

      if (signInMethods.length > 0) {
        // Email already exists
        toast("This email is already registered. Please sign in instead.");
        // You might want to switch to a sign-in modal here
        return;
      }

      const actionCodeSettings = {
        url: `${window.location.origin}/finishSignUp`,
        handleCodeInApp: true,
      };

      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);

      window.localStorage.setItem("emailForSignIn", email);
      window.localStorage.setItem("redirectPath", location.pathname);

      toast("Magic link sent! Check your email to complete signup.");
      setGlobalState({ ...globalState, showSignupModal: false });
    } catch (error) {
      console.error("Error sending sign-in link to email:", error);
      toast(`Failed to send magic link. ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={globalState?.showSignupModal}
      onClose={() => setGlobalState({ ...globalState, showSignupModal: false })}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, #FF6B6B, #4ECDC4)",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose
            handleClose={() =>
              setGlobalState({ ...globalState, showSignupModal: false })
            }
          />
        </Box>

        <Typography
          variant="h4"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center" }}
        >
          Let's get started
        </Typography>

        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: COLORS.lightGrey, my: .5}}
          >
          Create an account or login
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            autoComplete="off"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
       

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              marginTop: "1rem",
              fontWeight: "bold",
              background: "linear-gradient(45deg, #2196F3, #21CBF3)",
              color: "#FFFFFF",
              py: 1.5,
              borderRadius: "8px",
              transition: "background 0.3s ease, transform 0.3s ease",
              "&:hover": {
                background: "linear-gradient(45deg, #21CBF3, #2196F3)",
                transform: "translateY(-2px)",
              },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "GET STARTED"}
          </Button>
        </form>

        <Typography
          variant="body2"
          sx={{ mt: 3, textAlign: "center", color: COLORS.darkGrey }}
        >
          By signing up, you agree to our Terms of Service and Privacy Policy.
        </Typography>
      </Box>
    </Modal>
  );
};

export default SignupModal;
