import React, { useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Zoom,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { COLORS } from "../utils/colors";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)",
  },
  title: {
    mb: 4,
    fontWeight: 700,
    textAlign: 'center',
    background:  'black', // 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '2.5rem',
    textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
  },
  stepLabel: {
    '& .MuiStepLabel-label': {
      fontWeight: 600,
      fontSize: '1.2rem',
      color: '#333',
    },
  },
  stepContent: {
    borderLeft: '2px solid #FF4B2B',
    marginLeft: '12px',
    paddingLeft: '20px',
    marginBottom: 2,
  },
  stepDescription: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '0.5rem',
  },
  stepHighlight: {
    fontWeight: 600,
    color: COLORS.lightGrey
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    width: isMobile ? "100%" : "600px",
    maxHeight: "90%",
    overflow: "hidden", // Changed from "auto" to "hidden"
    display: "flex",
    flexDirection: "column",
    p: 3,
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: "linear-gradient(90deg, #FF6B6B, #4ECDC4)",
    },
  }),
  closeIcon: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    color: 'text.secondary',
  },
  

  teaserQuestion: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#666',
    marginTop: '1rem',
  },
  exploreButton: {
    alignSelf: 'center',
    mt: 2,
    background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    '&:hover': {
      background: 'linear-gradient(45deg, #1e88e5, #1cb5e0)',
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },
};

const HowItWorksModal = () => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => setGlobalState({ ...globalState, showHowItWorksModal: false });

  const steps = [
    { 
      title: "Browse AI Talent 🎭", 
      description: "Browse our marketplace of specially trained & uniquely capable workplace AI agents created by other users.",
      icon: <SearchIcon />,
      highlight: "Find your perfect match"
    },
    { 
      title: "Hire AI Talent 🚀", 
      description: "Subscribe once to TeamMembers.ai then add as many AI team members as you need to your team.",
      icon: <PersonAddIcon />,
      highlight: "Configure in seconds"
    },
    { 
      title: "Work with AI Talent 🧠", 
      description: "Specialized AI agents have specific prompts, response criterias, routines, & unique functional capablilities built around your work.",
      icon: <HandshakeIcon />,
      highlight: "Small tasks done very well"
    },
    { 
      title: "Create AI Talent 🌟", 
      description: "Monetize your specific knowledge into a workplace AI agent and earn through our revenue share program.",
      icon: <AttachMoneyIcon />,
      highlight: "Shape the future"
    }
  ];

  return (
    <Modal
      open={globalState?.showHowItWorksModal}
      onClose={handleClose}
      sx={styles.modal}
    >
      <Zoom in={globalState?.showHowItWorksModal}>
        <Box sx={styles.modalContent(isMobile)}>
          <CloseIcon sx={styles.closeIcon} onClick={handleClose} />
          <Typography variant="h4" sx={styles.title}>
            How it works
          </Typography>
          <Stepper orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index} active={true}>
                <StepLabel StepIconComponent={() => step.icon} sx={styles.stepLabel}>
                  {step.title}
                </StepLabel>
                <StepContent sx={styles.stepContent}>
                  <Typography sx={styles.stepDescription}>{step.description}</Typography>
                  <Typography sx={styles.stepHighlight}>{step.highlight}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <Button variant="contained" sx={styles.exploreButton} onClick={() => {
            setGlobalState({ ...globalState, showHowItWorksModal: false, showSignupModal: true });
          }}>
            Get Started Today
          </Button>
          <Typography sx={styles.teaserQuestion}>
            Are you ready to redefine what's possible with AI?
          </Typography>
        </Box>
      </Zoom>
    </Modal>
  );
};

export default HowItWorksModal;