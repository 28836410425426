import React, { useContext, useState } from "react";
import {
  Box,
  InputBase,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { borderRadius, height } from "@mui/system";
import { STANDARD_BORDER_RADIUS } from "../utils/constants";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import UserContext from "../context/userContext";

const FloatingHeader = () => {
  const { user } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (!user?.email) {
      setGlobalState({ ...globalState, showSignupModal: true });
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelStripe = () => {
    // Redirect to cancellation page or show a modal
    setGlobalState({ ...globalState, showCancellationModal: true });
  };

  const handleAvatarClick = (e) => {
    if (!user?.email) {
      // If user is not logged in, show signup modal
      setGlobalState({ ...globalState, showSignupModal: true });
      return;
    } else if (!user?.premiumUser?.isActive) {
      // user is logged in but not a subscriber
      return;
    } else {
      // user is logged in and is a subscriber
      handleClick(e);
    }
  };

  return (
    <Box sx={styles.header}>
      <Box sx={styles.searchBar}>
        <SearchIcon sx={{ color: "#9AA0A6", fontSize: 20, marginRight: 1 }} />
        <InputBase
          placeholder="Search all conversations"
          sx={{ flex: 1, fontSize: 14 }}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon sx={{ color: "#9AA0A6", fontSize: 20 }} />
        </IconButton>
      </Box>
      <Box sx={styles.actions}>
        <Button
          onClick={() => {
            setGlobalState({ ...globalState, showInvitePeopleModal: true });
          }}
          variant="contained"
          sx={styles.inviteButton}
        >
          + INVITE PEOPLE
        </Button>
        <Button
          onClick={() =>
            setGlobalState({ ...globalState, showSelectAgentModal: true })
          }
          variant="contained"
          sx={styles.addAIButton}
        >
          + BROWSE AI TALENT POOL
        </Button>
        <IconButton
          size="small"
          sx={{ color: COLORS.darkBlue }}
          onClick={handleAvatarClick}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCancelStripe}>Cancel Subscription</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const styles = {
  header: {
    display: "flex",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
    backgroundColor: "white",
    width: "95%",
    height: "60px",
    borderRadius: STANDARD_BORDER_RADIUS,
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "4px 8px",
    flex: ".95",
  },
  commandKey: {
    backgroundColor: "#F1F3F4",
    borderRadius: "2px",
    padding: "2px 4px",
    fontSize: 12,
    color: "#5F6368",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  createButton: {
    backgroundColor: "#1A73E8",
    color: "white",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1967D2",
      boxShadow: "none",
    },
  },
  inviteButton: {
    backgroundColor: "#F1F3F4",
    color: "#5F6368",
    fontWeight: "600",
    textTransform: "none",
    padding: "8px 16px",
    borderRadius: "20px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#E0E0E0",
      color: "#4C4C4C",
    },
  },
  addAIButton: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    color: "white",
    fontWeight: "600",
    textTransform: "none",
    padding: "8px 16px",
    borderRadius: "20px",
    boxShadow: "0 2px 10px rgba(33, 150, 243, 0.3)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #1E88E5, #00BCD4)",
      boxShadow: "0 4px 20px rgba(33, 150, 243, 0.4)",
      transform: "translateY(-1px)",
    },
  },
};

export default FloatingHeader;
