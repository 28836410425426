import React, { useContext } from "react";
import { Button, Box, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { COLORS } from "../utils/colors";
import { keyframes } from "@mui/system";
import GlobalStateContext from "../context/globalStateContext";

const popAnimation = keyframes`
  0% { transform: scale(0.8); opacity: 0; }
  70% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const ShareButton = ({ disabled }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  
  return (
    <Button
    disabled={disabled}
      onClick={() => {
        setGlobalState({
          ...globalState,
          showInviteModal: true,
        });
      }}
      variant="contained"
      sx={{
        backgroundColor: COLORS.diamondBlue,
        marginTop: 3,
        padding: "10px 20px",
        position: "relative",
        overflow: "hidden",
        transition: "all 0.3s ease",
        height: "48px", // Fixed height to prevent layout shift
        width: "120px", // Fixed width
        minWidth: "120px", // Ensure the button doesn't shrink

        "&:hover": {
          backgroundColor: COLORS.diamondBlueDark,
          "& .socialIcons": {
            opacity: 1,
            transform: "translate(-50%, -50%) scale(1)",
          },
          "& .shareText": {
            opacity: 0,
            transform: "translateY(20px)",
          },
        },
      }}
    >
      <Box
        className="shareText"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          transition: "all 0.3s ease",
        }}
      >
        <ShareIcon sx={{ mr: 1 }} />
        <Typography>Share</Typography>
      </Box>
      <Box
        className="socialIcons"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) scale(0.8)",
          display: "flex",
          opacity: 0,
          transition: "all 0.3s ease",
        }}
      >
        <TwitterIcon
          sx={{ mx: 0.5, animation: `${popAnimation} 0.5s ease 0.1s both` }}
        />
        <FacebookIcon
          sx={{ mx: 0.5, animation: `${popAnimation} 0.5s ease 0.2s both` }}
        />
        <LinkedInIcon
          sx={{ mx: 0.5, animation: `${popAnimation} 0.5s ease 0.3s both` }}
        />
      </Box>
    </Button>
  );
};

export default ShareButton;
