export const COLORS = {
    primaryGray: "#f8fafc",
    primaryLightGrey: "#FBFAF8",
    primaryPink: "#fc64c4",
    primaryBrown: "#fffcf4",
    primaryRed: "#ff4742",

    diamondBlue: "#2573da",
    indiehackersBlue: "#0e2439",
    darkBlue: "#282c34",
    gmailBlue: "#eaf1fb",
    lightBlue: "#c2e7ff",
    lightGrey: "#6b727a"
}