// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { CircularProgress, Box, Typography } from "@mui/material";
// import { firebase, firestore } from "../firebaseConfig";
// import toast from "react-simple-toasts";

// const FinishSignUp = () => {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const completeSignIn = async () => {
//       if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
//         let email = window.localStorage.getItem("emailForSignIn");
//         if (!email) {
//           email = window.prompt("Please provide your email for confirmation");
//         }
//         try {
//           const result = await firebase
//             .auth()
//             .signInWithEmailLink(email, window.location.href);
//           window.localStorage.removeItem("emailForSignIn");

//           // Add user to Firestore
//           await firestore.collection("users").doc(result.user.uid).set({
//             email: result.user.email,
//             lastSignIn: firebase.firestore.FieldValue.serverTimestamp(),
//           });

//           toast("✅ Successfully signed in!");
//           const redirectPath =
//           window.localStorage.getItem("redirectPath") || "/";
//           window.localStorage.removeItem("redirectPath");
//           navigate(redirectPath.startsWith("/hire/") ? redirectPath : "/");
//         } catch (error) {
//           console.error("Error completing sign-in:", error);
//           // toast(`🚨 ${error.message}`);
//         } finally {
//           setLoading(false);
//         }
//       }
//     };

//     completeSignIn();
//   }, [navigate]);

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       justifyContent="center"
//       height="100vh"
//     >
//       {loading ? (
//         <>
//           <CircularProgress />
//           <Typography variant="h6" mt={2}>
//             Finishing sign-up...
//           </Typography>
//         </>
//       ) : (
//         <Typography variant="h6">Sign-up completed! Redirecting...</Typography>
//       )}
//     </Box>
//   );
// };

// export default FinishSignUp;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Typography } from "@mui/material";
import { firebase, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import UserContext from "../context/userContext";

const FinishSignUp = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const completeSignIn = async () => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        try {
          const result = await firebase
            .auth()
            .signInWithEmailLink(email, window.location.href);
          window.localStorage.removeItem("emailForSignIn");

          const userRef = firestore.collection("users").doc(result.user.uid);
          const userDoc = await userRef.get();

          if (userDoc.exists) {
            // User already exists, update last sign-in
            await userRef.update({
              lastSignIn: firebase.firestore.FieldValue.serverTimestamp(),
            });

            // Fetch existing user data
            const userData = userDoc.data();

            // You might want to store this data in your app's state or context
            setUser((prevUser) => {
              return { ...prevUser, ...userData };
            });
          } else {
            // New user, create document
            await userRef.set({
              email: result.user.email,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              lastSignIn: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }

          toast("✅ Successfully signed in!");
          const redirectPath = window.localStorage.getItem("redirectPath") || "/";
          window.localStorage.removeItem("redirectPath");
          
          window.location.href = redirectPath.startsWith("/hire/") ? redirectPath : "/";
        } catch (error) {
          console.error("Error completing sign-in:", error);
          // toast(`🚨 Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        navigate("/"); // Redirect to home if not a valid sign-in link
      }
    };

    completeSignIn();
  }, [navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      {loading ? (
        <>
          <CircularProgress />
          <Typography variant="h6" mt={2}>
            Finishing sign-in...
          </Typography>
        </>
      ) : (
        <Typography variant="h6">Sign-in completed! Redirecting...</Typography>
      )}
    </Box>
  );
};

export default FinishSignUp;