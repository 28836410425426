import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Typography,
  Avatar,
  Button,
  Chip,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import { placeholderAgents } from "../utils/constants";
import StandardHeader from "../components/StandardHeader";
import { COLORS } from "../utils/colors";
import RenderStars from "../components/RenderStars";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import ConfigureAgentModal from "../components/ConfigureAgentModal";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import SignupModal from "../components/SignupModal";
import { Info, InfoOutlined, InfoSharp } from "@mui/icons-material";
import {
  fetchAllAgents,
  fetchUserInfo,
  handleNav,
} from "../utils/helperMethods";
import OnboardingModal from "../components/OnboardingModal";
import WorkIcon from "@mui/icons-material/Work";
import ChatIcon from "@mui/icons-material/Chat";
import StorageIcon from "@mui/icons-material/Storage";
import ScheduleIcon from "@mui/icons-material/Schedule";
import InviteModal from "../components/InviteModal";
import ShareButton from "../components/ShareButton";

const Hire = () => {

  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { role, username } = useParams();
  const [agent, setAgent] = useState(null);
  const [reviews, setReviews] = useState({ rating: 4.7, count: 253 }); // Placeholder data
  const [agentIsTeamMember, setAgentIsTeamMember] = useState(false);
  const [activeTab, setActiveTab] = useState("basic");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleHire = () => {
    // logged in??
    if (!user?.email) {
      setGlobalState({ ...globalState, showSignupModal: true });
      return;
    }

    if (!user?.premiumUser?.status) {
      navigate("/pricing");
      toast("Must be subscribed to hire", { position: "top-right" });
      return;
    }

    // PROCEED
    setGlobalState({
      ...globalState,
      configureAgentModal: true,
      selectedAgent: agent,
    });
  };

  useEffect(() => {
    const fetchAgents = async () => {
      const allAgents = await fetchAllAgents();
      // Do something with the agents data\
      const foundAgent =
        allAgents && allAgents.find((agent) => agent.username === username);

      // unfinished created ai agent that is stil lin /edit mode
      if (!foundAgent?.birthed && foundAgent?.createdAt) {
        toast(
          "This agent is still in edit mode. Please finish configuring it.",
          toastConfig
        );
        window.location.href = `/edit/${foundAgent?.roleSlug}/${foundAgent?.username}`; //
        return;
      }

      setAgent(foundAgent);
    };

    if (globalState?.allAgents && user?.id) {
      // logged in
      const foundAgent = globalState.allAgents.find(
        (agent) => agent.username === username
      );

      // unfinished created ai agent that is stil lin /edit mode
      if (!foundAgent?.birthed && foundAgent?.createdAt) {
        toast(
          "This agent is still in edit mode. Please finish configuring it.",
          toastConfig
        );
        window.location.href = `/edit/${foundAgent?.roleSlug}/${foundAgent?.username}`; //
        return;
      }

      setAgent(foundAgent);
    } else fetchAgents(); // visitor
  }, [user]);

  // see if already a team member
  useEffect(() => {
    if (user?.teamMembers && agent) {
      checkIfAgentTeamMember();
    }
  }, [user, agent]);

  useEffect(() => {
    const getUserProfile = async () => {
      if (user?.id && !user?.email) {
        const profile = await fetchUserInfo(user.id);
        setUser(profile);

        // Show onboarding if !user.orgName
        if (!profile.orgName) {
          setGlobalState({ ...globalState, showOnboardingModal: true });
          return;
        }
        // TODO - CHECK TO SEE IF USER IS PAYING - show PAYWALL
        setGlobalState({ ...globalState, showPaywall: true });
      }
    };

    getUserProfile();
  }, [user]);

  const checkIfAgentTeamMember = () => {
    const isTeamMember = Object.values(user.teamMembers).some(
      (teamMember) => teamMember.id === agent.id
    );
    setAgentIsTeamMember(isTeamMember);
  };

  const renderBasicInfo = () => (
    <>
      <Typography variant="h4" sx={styles.sectionHeader}>
        {agent.name}
      </Typography>
      <Typography variant="body1" sx={styles.description}>
        {agent.description}
      </Typography>
      <Divider sx={styles.divider} />
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={{...styles.subSectionTitle }}>
          Created by
        </Typography>
        <Chip 
        onClick={() => {
          window.location.href = `/profile/${agent.createdBy.username}`;
        }}
        label={agent.createdBy.username} sx={{...styles.typeChip, cursor:'pointer',}} />
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Type
        </Typography>
        <Chip label={agent.type} sx={styles.chip} />
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Built For
        </Typography>
        <Box sx={styles.chipContainer}>
          {agent.builtFor.map((userType, index) => (
            <Chip key={index} label={userType} sx={styles.chip} />
          ))}
        </Box>
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Expert in Frameworks
        </Typography>
        <Box sx={styles.chipContainer}>
          {agent.specializedFrameworks.map((framework, index) => (
            <Chip key={index} label={framework} sx={styles.chip} />
          ))}
        </Box>
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Can help you with
        </Typography>
        {agent.canHelpWith.map((specialty, index) => (
          <Typography key={index} variant="body1" sx={styles.listItem}>
            <WorkIcon sx={styles.inlineIcon} /> {specialty}
          </Typography>
        ))}
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Personality
        </Typography>
        <Box sx={styles.chipContainer}>
          {agent.responseCriteria.personality.map((trait, index) => (
            <Chip key={index} label={trait} sx={styles.chip} />
          ))}
        </Box>
      </Box>
    </>
  );

  const renderWorkflow = () => (
    <>
      <Typography variant="h4" sx={styles.sectionHeader}>
        AI Workflow
      </Typography>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Triggered by
        </Typography>
        <Box sx={styles.chipContainer}>
          {agent.trigger.map((trigger, index) => (
            <Chip
              key={index}
              label={trigger}
              icon={<ChatIcon color="white" />}
              sx={{...styles.chip, }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Outputs to
        </Typography>
        <Box sx={styles.chipContainer}>
          {agent.output.map((output, index) => (
            <Chip
              key={index}
              label={output}
              icon={<ChatIcon color="white"/>}
              sx={{...styles.chip, }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={styles.infoSection}>
        <Typography variant="h6" sx={styles.subSectionTitle}>
          Response Criteria
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          Length: {agent.responseCriteria.length}
        </Typography>
        {agent.responseCriteria?.responseStructure?.inGeneral && (
          <>
            <Typography variant="body2" sx={styles.text}>
              Structure:
            </Typography>
            {agent.responseCriteria.responseStructure.inGeneral.map(
              (item, index) => (
                <Typography key={index} variant="body2" sx={styles.listItem}>
                  <InfoOutlined sx={styles.inlineIcon} /> {item}
                </Typography>
              )
            )}
          </>
        )}
      </Box>
    </>
  );

  const renderSettings = () => (
    <>
      <Typography variant="h4" sx={styles.sectionHeader}>
        Settings & Capabilities
      </Typography>
      {agent.longTermMemory && (
        <Box sx={styles.infoSection}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Long Term Memory
          </Typography>
          <Typography variant="body1" sx={styles.text}>
            <StorageIcon sx={styles.inlineIcon} /> Stored in{" "}
            {agent.longTermMemory}
          </Typography>
        </Box>
      )}
      {agent.routines && agent.routines.length > 0 && (
        <Box sx={styles.infoSection}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Routines
          </Typography>
          {agent.routines.map((routine, index) => (
            <Typography key={index} variant="body1" sx={styles.text}>
              <ScheduleIcon sx={styles.inlineIcon} />
              {routine.cron && `${routine.cron} `}
              {routine.time && `at ${routine.time}`}
            </Typography>
          ))}
        </Box>
      )}
      {agent.customizableFields && agent.customizableFields.length > 0 && (
        <Box sx={styles.infoSection}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Customizable Fields
          </Typography>
          <Box sx={styles.chipContainer}>
            {agent.customizableFields.map((field, index) => (
              <Chip key={index} label={field} sx={styles.chip} />
            ))}
          </Box>
        </Box>
      )}
    </>
  );

  if (!agent)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        ...
      </Box>
    );
    

  return (
    <Box sx={styles.container}>
      {globalState?.showOnboardingModal && <OnboardingModal />}
      {globalState?.showSignupModal && <SignupModal />}
      {globalState?.configureAgentModal && <ConfigureAgentModal />}
      {globalState?.showInviteModal && (
        <InviteModal
          agent={agent}
          agentUrl={`https://www.teammembers.ai/hire/${agent.roleSlug}/${agent.username}`}
        />
      )}

      <StandardHeader />
      <Box sx={styles.content}>
        <Box sx={styles.leftColumn}>
          <Box sx={styles.leftColumnContent}>
            <Avatar src={agent.image} alt={agent.name} sx={styles.avatar} />
            <Typography variant="h2" sx={styles.name}>
              {agent.name}
            </Typography>
            <Typography variant="h5" sx={styles.role}>
              @{agent.username}
            </Typography>
            <Typography variant="subtitle1" sx={styles.role}>
              AI {agent.role}
            </Typography>
            <Box sx={styles.ratingContainer}>
              <Typography variant="h4" sx={styles.ratingNumber}>
                {reviews.rating}
              </Typography>
              <RenderStars rating={reviews.rating} />
              <Tooltip title="Total number of reviews" arrow>
                <Box sx={styles.reviewCount}>
                  <PersonIcon sx={{ fontSize: 14, marginRight: 0.5 }} />
                  <Typography variant="body2">{reviews.count}</Typography>
                </Box>
              </Tooltip>
            </Box>
            <Button
              onClick={agentIsTeamMember ? () => handleNav("/") : handleHire}
              variant="contained"
              sx={styles.primaryButton}
            >
              {agentIsTeamMember
                ? `CHAT WITH ${agent.name.split(" ")[0].toUpperCase()}`
                : `HIRE ${agent.name.split(" ")[0].toUpperCase()}`}
            </Button>

            {/* SHARE BUTTON LIKE THE STRIPE ONE BUT DIFF COLOR  */}
            <ShareButton />

            {agentIsTeamMember && (
              <Typography sx={styles.alreadyWorkingText}>
                You're already working with {agent.name.split(" ")[0]}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.rightColumn}>
          <Box sx={styles.tabsContainer}>
            <Tabs value={activeTab} onChange={handleTabChange} sx={styles.tabs}>
              <Tab label="BASIC INFO" value="basic" />
              <Tab label="AI WORKFLOW" value="workflow" />
              <Tab label="SETTINGS" value="settings" />
            </Tabs>
          </Box>
          <Box sx={styles.tabContent}>
            {activeTab === "basic" && renderBasicInfo()}
            {activeTab === "workflow" && renderWorkflow()}
            {activeTab === "settings" && renderSettings()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100vh", // Full viewport height
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  sectionHeader: {
    fontWeight: 700,
    marginBottom: 2,
    color: COLORS.primary,
  },
  subSectionTitle: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 1,
    color: COLORS.darkGrey,
  },
  infoSection: {
    marginBottom: 3,
  },
  typeChip: {
    backgroundColor: COLORS.lightBlue,
    color: COLORS.primary,
    fontWeight: 600,
    marginTop: 1,
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
  },
  chip: {
    backgroundColor: COLORS.lightGrey,
    color: "white",
    "&:hover": {
      backgroundColor: COLORS.grey,
    },
  },
  inlineIcon: {
    verticalAlign: "middle",
    marginRight: 1,
    color: COLORS.primary,
    fontSize: "1rem",
  },
  alreadyWorkingText: {
    color: COLORS.lightGrey,
    textAlign: "center",
    marginTop: 2,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },

  content: {
    display: "flex",
    flex: 1, // Take remaining space
    overflow: "hidden", // Hide overflow
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },
  tabs: {
    flex: 1,
  },
  tabContent: {
    flex: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },

  leftColumnContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "sticky",
    top: 40,
  },
  avatar: {
    width: 200,
    height: 200,
    marginBottom: 2,
  },
  name: {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.2,
    marginBottom: 0.5,
  },
  role: {
    color: COLORS.darkGrey,
    fontSize: "1rem",
    marginBottom: 1,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 2,
  },
  ratingNumber: {
    fontWeight: 700,
    marginRight: 1,
    color: COLORS.primary,
    fontSize: "1.5rem",
  },
  reviewCount: {
    display: "flex",
    alignItems: "center",
    color: COLORS.darkGrey,
    cursor: "help",
    marginLeft: 1,
  },
  primaryButton: {
    background: "#22C55E",
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },

  leftColumn: {
    flex: "0 0 300px",
    position: "sticky",
    top: 0,
    height: "100%",
    overflowY: "auto",
    padding: "40px 20px",
  },

  rightColumn: {
    flex: 1,
    overflowY: "auto",
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 3,
    "&::-webkit-scrollbar": {
      height: "4px",
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },

  description: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: 1.3,
  },

  divider: {
    margin: "24px 0",
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: "1.1rem",
  },
  text: {
    color: "#4B5563",
  },

  buttonGroup: {
    display: "flex",
    gap: 2,
    marginBottom: 4,
  },

  secondaryButton: {
    borderColor: "#D1D5DB",
    color: "#18181B",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
  },

  experienceList: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  experienceItem: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  companyLogo: {
    width: 40,
    height: 40,
    backgroundColor: "#F3F4F6",
    color: "#4B5563",
  },
  frameworkName: {
    fontWeight: 600,
  },
  frameworkDate: {
    color: "#6B7280",
  },

  ratingBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },

  starRating: {
    "& .MuiRating-iconFilled": {
      color: COLORS.primary,
    },
    "& .MuiRating-iconEmpty": {
      color: COLORS.lightGrey,
    },
  },
};

export default Hire;
