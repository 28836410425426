//
import React, { useContext, useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Collapse,
  Zoom,
  Box,
  Typography,
} from "@mui/material";
import {
  Home as HomeIcon,
  Diamond as DiamondIcon,
  People as PeopleIcon,
  Store as ShopIcon,
  AttachMoney as IncomeIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Notes as NotesIcon,
  LoginRounded,
  HelpOutlineOutlined,
} from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { borderTop, keyframes } from "@mui/system";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import GlobalStateContext from "../context/globalStateContext";
import { STANDARD_BORDER_RADIUS } from "../utils/constants";
import { COLORS } from "../utils/colors";
import UserContext from "../context/userContext";
import { capitalizeWords, logoutFirebase } from "../utils/helperMethods";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const sparkle = keyframes`
  0% { transform: scale(1a) rotate(0deg); }
  50% { transform: scale(1.2) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
`;

const LogoutConfirmationModal = ({ open, handleClose, handleLogout }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "16px",
          background: "linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle id="logout-dialog-title" style={{ color: "#1A73E8" }}>
        Confirm Logout
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="logout-dialog-description"
          style={{ color: "#333" }}
        >
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "16px" }}>
        <Button onClick={handleClose} style={{ color: "#666" }}>
          Cancel
        </Button>
        <Button
          onClick={handleLogout}
          style={{
            cursor: "pointer",
            background: "linear-gradient(45deg, #1A73E8 30%, #34A853 90%)",
            color: "white",
            borderRadius: "20px",
            padding: "8px 24px",
          }}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Sidebar = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [openStates, setOpenStates] = useState({
    deptsOpen: false,
    teamMembersOpen: true,
    createOpen: false,
  });
  const [selectedItem, setSelectedItem] = useState("Home");
  const [organization, setOrganization] = useState({
    name: "Your Company",
  });
  const [teamMembersArr, setTeamMembersArr] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [createdAgents, setCreatedAgents] = useState([]);

  useEffect(() => {
    if (user && user?.orgName) setOrganization({ name: user?.orgName });
    else setOrganization({ name: "TeamMembersAI" });
  }, [user]);

  // setup department render
  useEffect(() => {
    if (user && user.departments) {
      const deptArray = Object.values(user.departments);
      setDepartments(deptArray);
    } else {
      setDepartments([]);
    }
  }, [user]);

  useEffect(() => {
    if (
      user?.teamMembers !== undefined &&
      Object.values(user?.teamMembers)?.length > 0
    ) {
      // Created AI agents have a "createdAt" prop & not archived in teamMembers object
      const createdAiMembers = Object.values(user?.teamMembers).filter(
        (member) => member.createdAt && member?.status !== "archived"
      );

      setCreatedAgents(createdAiMembers);
      setTeamMembersArr(user?.activeMembers);
    } else {
      setTeamMembersArr([]);
    }
  }, [user?.teamMembers]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    const isHomePage = ["Home", "Departments", "Team Members", "Create AI Talent"].includes(item);
  
    let deptMembers = [];
    if (!isHomePage) {
      const selectedDept = departments.filter((dept) => {
        return dept.name.toLowerCase() === item.toLowerCase()
      })
      deptMembers = selectedDept[0]?.members || [];
    }
  

    const deptMembersWithProfiles = user?.activeMembers?.filter((member) =>
      deptMembers.includes(member.id)
    );
  
  
    setGlobalState({
      ...globalState,
      selectedView: isHomePage ? "Home" : item,
      selectedDepartment: item,
      selectedDepartmentViewProfiles: deptMembersWithProfiles || null,
    });
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false);
  };

  const handleLogoutConfirm = () => {
    logoutFirebase();
    setLogoutModalOpen(false);
    setUser(null);
  };

  const handleAgentClick = (agent) => {
    window.location.href = `/edit/${agent.roleSlug}/${agent.username}`;
  };

  const toggleSection = (section) => {
    setOpenStates((prevState) => {
      const newState = {
        deptsOpen: false,
        teamMembersOpen: false,
        createOpen: false,
        hireOpen: false,
      };
      newState[section] = !prevState[section];
      return newState;
    });
  };

  const getAdjustedFontSize = (text) => {
    if (text.length <= 20) return "1.25rem";
    if (text.length <= 30) return "1rem";
    return "0.875rem";
  };

  const listItems = [
    {
      text: "Home",
      icon: <HomeIcon fontSize="small" />,
      action: () => handleItemClick("Home"),
      selected: selectedItem === "Home",
    },
    {
      text: "Departments",
      icon: <NotesIcon fontSize="small" />,
      action: () => {
        handleItemClick("Departments");
        toggleSection("deptsOpen");
      },
      selected: selectedItem === "Departments",
      collapse: true,
      collapseOpen: openStates.deptsOpen,
      subItems: [
        {
          text: "Create Department",
          icon: <AddCircleOutlineIcon sx={styles.subItemIcon} />,
          action: () => {
            if (!user?.premiumUser) {
              toast("Membership required to create departments", toastConfig);
              window.location.href = "/pricing";
              return;
            }
            handleItemClick("Create Department");
            setGlobalState({ ...globalState, showCreateDeptsModal: true });
          },
          selected: selectedItem === "Create Department",
        },
        ...departments.map((dept) => ({
          text: capitalizeWords(dept.name),
          icon: <GroupsIcon sx={styles.subItemIcon} />,
          action: () => handleItemClick(capitalizeWords(dept.name)),
          selected: selectedItem === capitalizeWords(dept.name),
          endIcon: (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setGlobalState({
                  ...globalState,
                  showDeptEditModal: true,
                  selectedDepartment: dept.name,
                });
              }}
              sx={styles.moreOptionsButton}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          ),
        })),
      ],
    },
    {
      text: "Team Members",
      icon: <PeopleIcon fontSize="small" />,
      action: () => {
        handleItemClick("Team Members");
        toggleSection("teamMembersOpen");
      },
      selected: selectedItem === "Team Members",
      collapse: true,
      collapseOpen: openStates.teamMembersOpen,
      subItems: [],
    },
    {
      text: "AI Talent Pool",
      icon: <ShopIcon fontSize="small" />,
      action: () => {
        handleItemClick("AI Talent Pool");
        toggleSection("hireOpen");
        setGlobalState({ ...globalState, showSelectAgentModal: true });
      },
      selected: selectedItem === "AI Talent Pool",
      collapse: false,
    },
    {
      text: "Create AI Talent",
      icon: <IncomeIcon fontSize="small" />,
      action: () => {
        handleItemClick("Create AI Talent");
        toggleSection("createOpen");
      },
      selected: selectedItem === "Create AI Talent",
      collapse: true,
      collapseOpen: openStates.createOpen,
      subItems: [
        {
          text: "Create Your Own & Earn",
          action: () => {
            if (!user?.premiumUser) {
              toast("Membership required to create AI talent", toastConfig);
              window.location.href = "/pricing";
              return;
            }
            handleItemClick("Create AI Talent");
            setGlobalState({ ...globalState, showCreateAgentModal: true });
          },
          selected: selectedItem === "Create Your Own & Earn",
        },
      ],
    },
  ];

  return (
    <Box sx={styles.sidebar}>
      <Box
        onClick={() => {
          // allow for organization switching
        }}
        sx={{
          p: 2,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Tooltip
          title="Shine bright like a diamond!"
          TransitionComponent={Zoom}
          arrow
        >
          <DiamondIcon
            sx={{
              fontSize: 36,
              color: "#1A73E8",
              "&:hover": {
                animation: `${sparkle} 0.5s ease-in-out`,
              },
            }}
          />
        </Tooltip>
        <Box sx={{ ml: 1, lineHeight: 1.2, flex: 1, overflow: "hidden" }}>
          <Tooltip title={organization?.name} arrow>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                background: "linear-gradient(45deg, #1A73E8 30%, #34A853 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: getAdjustedFontSize(organization?.name),
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {organization?.name || "TeamMembersAI"}
            </Typography>
          </Tooltip>

          {organization?.name !== "TeamMembersAI" && (
            <Typography
              variant="caption"
              sx={{
                opacity: 0.7,
                transition: "opacity 0.3s",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              on TeamMembers.ai
            </Typography>
          )}
        </Box>
      </Box>

      <List sx={styles.list}>
        {listItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              sx={{
                ...styles.listItemStyles,
                ...(item.selected && styles.selectedItem),
              }}
              onClick={item.action}
            >
              <ListItemIcon sx={styles.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={
                  selectedItem === item.text
                    ? {
                        ...styles.listItemText,
                        fontWeight: "bold",
                        color: COLORS?.diamondBlue,
                      }
                    : styles.listItemText
                }
              />
              {teamMembersArr !== undefined &&
                teamMembersArr?.length > 0 &&
                item.text === "Team Members" && (
                  <Typography sx={styles.countBadge}>
                    {teamMembersArr?.length}
                  </Typography>
                )}
              {item.text === "Departments" && departments.length > 0 && (
                <Typography sx={styles.countBadge}>
                  {departments.length}
                </Typography>
              )}
              {item.collapse &&
                (item.collapseOpen ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                ))}
            </ListItem>
            {item.subItems && (
              <Collapse in={item.collapseOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      button
                      sx={{
                        ...styles.listItemStyles,
                        pl: 4,
                        ...(subItem.selected && {
                          bgcolor: "rgba(0, 0, 0, 0.04)",
                        }),
                      }}
                      onClick={subItem.action}
                    >
                      {subItem.icon && (
                        <ListItemIcon sx={styles.listItemIcon}>
                          {subItem.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={subItem.text}
                        primaryTypographyProps={styles.listItemTextMini}
                      />
                      {subItem.endIcon}
                    </ListItem>
                  ))}

                  {item.text === "Team Members" &&
                    teamMembersArr &&
                    teamMembersArr.length > 0 && (
                      <Box sx={styles.teamMembersList}>
                        {teamMembersArr.map((member, memberIndex) => (
                          <ListItem
                            onClick={
                              member?.username === "donna.ai"
                                ? null
                                : () =>
                                    (window.location.href = `/hire/${member.roleSlug}/${member.username}`)
                            }
                            key={memberIndex}
                            sx={{ ...styles.teamMemberItem, cursor: "pointer" }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={member.image}
                                alt={member.name}
                                sx={styles.avatar}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${member.name}`}
                              secondary={`${member?.human ? null : "AI"} ${
                                member.role
                              }`}
                              primaryTypographyProps={styles.teamMemberName}
                              secondaryTypographyProps={styles.teamMemberRole}
                            />
                            {/* <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setGlobalState({
                ...globalState,
                showMemberEditModal: true,
                selectedMember: member,
              });
            }}
            sx={styles.moreOptionsButton}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton> */}
                          </ListItem>
                        ))}
                      </Box>
                    )}

                  {item.text === "Create AI Talent" &&
                    createdAgents &&
                    createdAgents.length > 0 && (
                      <Box sx={styles.teamMembersList}>
                        {createdAgents.map((member, memberIndex) => (
                          <ListItem
                            key={memberIndex}
                            sx={styles.teamMemberItem}
                            onClick={() => handleAgentClick(member)}
                            button
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={member.image}
                                alt={member.name}
                                sx={styles.avatar}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${member.name}`}
                              secondary={`AI ${member.role}`}
                              primaryTypographyProps={styles.teamMemberName}
                              secondaryTypographyProps={styles.teamMemberRole}
                            />
                          </ListItem>
                        ))}
                      </Box>
                    )}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      <Box sx={{ marginTop: "auto", p: 2 }}>
        {/* How it works */}
        <ListItem
          button
          onClick={() => {
            setGlobalState({ ...globalState, showHowItWorksModal: true });
          }}
        >
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary="How It Works"
            primaryTypographyProps={styles.listItemText}
          />
        </ListItem>
        {/* Pricing */}
        <ListItem
          button
          onClick={() => {
            window.location.href = "/pricing";
          }}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText
            primary="Pricing"
            primaryTypographyProps={styles.listItemText}
          />
        </ListItem>
        {/* Help section */}
        <ListItem
          button
          onClick={() => {
            toast("hello@teammembers.ai", {
              position: "top-right",
              duration: 10000,
            });
          }}
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText
            primary="Support"
            primaryTypographyProps={styles.listItemText}
          />
        </ListItem>
        {/* Logout section */}
        {user?.email ? (
          <ListItem sx={{ cursor: "pointer" }} onClick={handleLogoutClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ ...styles.listItemText }}
            />
          </ListItem>
        ) : (
          <ListItem
            sx={{ cursor: "pointer" }}
            onClick={() =>
              setGlobalState({ ...globalState, showSignupModal: true })
            }
          >
            <ListItemIcon>
              <LoginRounded />
            </ListItemIcon>
            <ListItemText
              primary="Login / Create account"
              primaryTypographyProps={styles.listItemText}
            />
          </ListItem>
        )}
        {/* Mode toggle */}
        {/* <ListItem>
          <ListItemText primary="Light" />
          <Switch
            checked={globalState?.darkMode}
            onChange={() =>
              setGlobalState({
                ...globalState,
                darkMode: !globalState?.darkMode,
              })
            }
            color="primary"
          />
          <ListItemText primary="Dark" sx={{ textAlign: "right" }} />
        </ListItem> */}
      </Box>
      <LogoutConfirmationModal
        open={logoutModalOpen}
        handleClose={handleLogoutModalClose}
        handleLogout={handleLogoutConfirm}
      />
    </Box>
  );
};

const styles = {
  sidebar: {
    minWidth: 300,
    height: "95vh",
    backgroundColor: "white",
    borderRadius: STANDARD_BORDER_RADIUS,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  subItemIcon: {
    fontSize: "1.25rem",
    color: COLORS.lightGrey,
    marginRight: "8px",
  },
  listItem: {
    borderRadius: "8px",
    margin: "4px 0",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  selectedItem: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  icon: {
    minWidth: "40px",
  },
  expandIcon: {
    fontSize: "1.2rem",
  },
  helpSection: {
    marginTop: "auto",
    padding: "16px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  modeToggle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "20px",
    padding: "4px",
    marginTop: "8px",
  },
  list: {
    padding: "8px",
    marginTop: "10%",
  },
  listItemStyles: {
    borderRadius: "8px",
    mb: 0.5,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  moreOptionsButton: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },

  listItemIcon: {
    minWidth: "40px",
    color: "inherit",
  },
  listItemText: {
    fontSize: "0.875rem",
    fontWeight: "600",
    color: COLORS.lightGrey,
  },
  listItemTextMini: {
    fontWeight: "600",
    color: COLORS.lightGrey,
    fontSize: "0.8rem",
  },
  countBadge: {
    backgroundColor: COLORS.diamondBlue,
    color: "white",
    borderRadius: "50%",
    padding: "2px 6px",
    fontSize: "0.75rem",
    marginRight: "8px",
  },

  // sidebar team list
  teamMembersList: {
    maxHeight: "300px",
    paddingX: 2,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "2px",
    },
  },
  teamMemberItem: {
    py: 0.5,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  avatar: {
    width: 32,
    height: 32,
  },
  teamMemberName: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: COLORS.darkGray,
    lineHeight: 1.2,
  },
  teamMemberRole: {
    fontSize: "0.75rem",
    color: COLORS.lightGrey,
  },
};

export default Sidebar;
