import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Avatar,
  Chip,
  Button,
  InputBase,
  CircularProgress,
} from "@mui/material";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import SearchIcon from "@mui/icons-material/Search";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import RedClose from "./RedClose";
import { COLORS } from "../utils/colors";
import { firestore } from "../firebaseConfig";

const EditDeptModal = () => {
  const { user, setUser } = useContext(UserContext);
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const deptDetails = Object.values(user?.departments)?.find(
      (dept) => dept.name === globalState?.selectedDepartment
    );
    const existingDeptMembers = deptDetails?.members || [];
    const initialSelectedMembers =
      user?.activeMembers?.filter((member) =>
        existingDeptMembers.includes(member.id)
      ) || [];
    setSelectedMembers(initialSelectedMembers);
  }, [user, globalState?.selectedDepartment]);

  const handleClose = () => {
    setGlobalState({
      ...globalState,
      showDeptEditModal: false,
    });
    setConfirmDelete(false); // Reset confirmDelete state
  };

  const handleSelectMember = (member) => {
    if (selectedMembers.find((m) => m.id === member.id)) {
      setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleDeleteDepartment = async () => {
    try {
      const deptDetails = Object.values(user?.departments)?.find(
        (dept) => dept.name === globalState?.selectedDepartment
      );

      if (!deptDetails) {
        throw new Error("Department not found");
      }

      // Remove the department from the user's departments
      const updatedDepartments = { ...user.departments };
      delete updatedDepartments[deptDetails.id];

      // Update Firestore
      await firestore.collection("users").doc(user?.id).update({
        departments: updatedDepartments,
      });

      // Update local user state
      setUser({
        ...user,
        departments: updatedDepartments,
      });

      toast("Department deleted successfully", {
        position: "top-right",
      });

      handleClose();
    } catch (error) {
      console.error("Error deleting department:", error);
      toast("Error deleting department. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleAddMembers = () => {
    const selectedMembersIds = selectedMembers.map((m) => m.id);

    const deptDetails = Object.values(user?.departments)?.find(
      (dept) => dept.name === globalState?.selectedDepartment
    );

    // Update the department members with the currently selected members
    const updatedDeptDetails = { ...deptDetails, members: selectedMembersIds };

    firestore
      .collection("users")
      .doc(user?.id)
      .update({
        [`departments.${deptDetails.id}`]: updatedDeptDetails,
      });

    setUser({
      ...user,
      departments: {
        ...user.departments,
        [deptDetails.id]: updatedDeptDetails,
      },
    });

    // setSelectedMembers([]);
    setSearchTerm("");
    toast("Updated successfully. Reload", {
      position: "top-right",
    });
    handleClose();
  };

  const filteredMembers = user?.activeMembers?.filter((member) =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      open={globalState?.showDeptEditModal}
      onClose={handleClose}
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile)}>
        <Box sx={styles.header}>
          <Typography variant="h5" sx={styles.title}>
            Edit
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              onClick={
                confirmDelete
                  ? () => {
                      setConfirmDelete(false);
                    }
                  : () => {
                      setConfirmDelete(true);
                    }
              }
              variant="caption"
              sx={{
                color: confirmDelete ? "black" : "red",
                cursor: "pointer",
                fontWeight: "800",
                pr: 4,
              }}
            >
              {confirmDelete ? "GO BACK" : "DELETE"}
            </Typography>
            <RedClose handleClose={handleClose} />
          </Box>
        </Box>

        <Typography
          variant="body1"
          sx={{ color: COLORS.darkGray, pl: 2, pt: 2, fontWeight: "bold" }}
        >
          Add to {globalState?.selectedDepartment} team
        </Typography>
        <Box sx={styles.searchBar}>
          <InputBase
            sx={styles.searchInput}
            placeholder="Search team members..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SearchIcon sx={styles.searchIcon} />
        </Box>

        <Box sx={styles.membersGrid}>
          {filteredMembers?.map((member) => (
            <Chip
              key={member.id}
              avatar={<Avatar alt={member.name} src={member.image} />}
              label={member.name}
              onClick={() => handleSelectMember(member)}
              sx={styles.memberChip(
                selectedMembers.find((m) => m.id === member.id)
              )}
            />
          ))}
        </Box>

        <Box sx={styles.footer}>
          <Typography variant="body2" sx={styles.selectedCount}>
            {selectedMembers.length} members selected
          </Typography>
          {confirmDelete ? (
            <Button
              variant="contained"
              onClick={handleDeleteDepartment}
              sx={{ backgroundColor: "red" }}
            >
              {isLoading ? <CircularProgress /> : "Confirm Delete"}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleAddMembers}
              disabled={selectedMembers.length === 0}
              sx={styles.addButton}
            >
              {isLoading ? <CircularProgress /> : "Add Members"}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  membersGrid: {
    display: "flex",
    flexWrap: "nowrap", // Changed to nowrap for horizontal scrolling
    gap: "0.5rem",
    padding: "1rem",
    overflowX: "auto", // Changed to horizontal scrolling
    "&::-webkit-scrollbar": {
      height: "6px", // Changed to height for horizontal scrollbar
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },
  memberChip: (isSelected) => ({
    margin: 0.5,
    backgroundColor: isSelected ? COLORS.diamondBlue : COLORS.primaryLightGry,
    color: isSelected ? COLORS.white : COLORS.darkGray,
    "&:hover": {
      backgroundColor: isSelected ? COLORS.darkBlue : "COLORS.lightGrey",
    },
  }),
  addButton: {
    backgroundColor: COLORS.green,
    border: "2px solid transparent",
    borderImage: "linear-gradient(45deg, #2196F3, #21CBF3) 1",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: COLORS.darkGreen,
    },
    "&:disabled": {
      borderImage: "none",
    },
    "&:not(:disabled)": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      color: COLORS.white,
    },
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    width: isMobile ? "100%" : "600px",
    maxHeight: "90%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: 24,
    background: `linear-gradient(135deg, ${COLORS.diamondBlue}, ${COLORS.oceanBlue})`,
  }),
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    borderBottom: `1px solid ${COLORS.lightGrey}`,
  },
  title: {
    color: "black",
    fontWeight: 600,
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: COLORS.primaryLightGrey,
    borderRadius: "8px",
    padding: "0.5rem 1rem",
    margin: "1rem",
  },
  searchInput: {
    flex: 1,
    fontSize: "1rem",
  },
  searchIcon: {
    color: "#757575",
  },

  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    borderTop: `1px solid ${COLORS.lightGrey}`,
  },
  selectedCount: {
    color: "white",
  },
};

export default EditDeptModal;
