
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBYvWHUz3Vo3sAzgOYDHsUuyOkAsSCZZzY",
  authDomain: "teammembers-ai.firebaseapp.com",
  projectId: "teammembers-ai",
  storageBucket: "teammembers-ai.appspot.com",
  messagingSenderId: "524271432967",
  appId: "1:524271432967:web:ac54d19a464009621581a2",
  measurementId: "G-EMVD00HXF7"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

const functions = firebase.functions();

export { firebase, firestore, functions };
