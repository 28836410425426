import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import UserContext from "../context/userContext";
import { firebase, firestore } from "../firebaseConfig";

const ConfigureAgentModal = () => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (globalState.selectedAgent) {
      const initialData = {};
      globalState.selectedAgent?.customizableFields?.forEach((field) => {
        initialData[field] = globalState.selectedAgent[field] || "";
      });
      setFormData(initialData);
    }
  }, [globalState.selectedAgent]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!user || !user.id) {
      toast("Error: User not authenticated", { position: "top-right" });
      setIsLoading(false);
      return;
    }

    try {
      // Include all customizable fields
      const customFields = {};
      globalState.selectedAgent.customizableFields.forEach((field) => {
        customFields[field] = formData[field];
      });

      // Add additional fields that should be saved
      customFields.id = globalState.selectedAgent.id;
      customFields.name = globalState.selectedAgent.name;
      customFields.username = globalState.selectedAgent.username;

      // Add hiredDate
      customFields.hiredDate = firebase.firestore.FieldValue.serverTimestamp();

      // Save to Firestore
      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [`teamMembers.${globalState.selectedAgent.id}`]: customFields,
        });

      // Close the modal and show success message
      toast(`🎉 Congrats on hiring ${globalState.selectedAgent.name}`, {
        position: "top-right",
      });
      setGlobalState({
        ...globalState,
        configureAgentModal: false,
        selectedAgent: null,
      });
      // refresh page
      window.location.reload();
    } catch (error) {
      console.error("Error saving agent configuration:", error);
      toast("Error saving configuration. Please try again.", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderCustomizableField = (field) => {
    switch (field) {
      case "role":
      case "description":
        return (
          <TextField
            key={field}
            fullWidth
            multiline={field === "description"}
            rows={field === "description" ? 4 : 1}
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            margin="normal"
          />
        );
      case "type":
        return (
          <FormControl fullWidth margin="normal" key={field}>
            <InputLabel>Type</InputLabel>
            <Select
              value={formData[field]}
              onChange={(e) => handleInputChange(field, e.target.value)}
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
              <MenuItem value="premium">Premium</MenuItem>
            </Select>
          </FormControl>
        );
      default:
        return (
          <TextField
            key={field}
            fullWidth
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            margin="normal"
          />
        );
    }
  };

  return (
    <Modal
      open={globalState?.configureAgentModal}
        onClose={() => setGlobalState({ ...globalState, configureAgentModal: false })}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: isMobile ? "0px" : "16px",
          width: isMobile ? "100%" : "600px",
          maxHeight: "90%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          p: 3,
          boxShadow: 24,
        }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 600, mb: 2 }}>
          Configure {globalState?.selectedAgent?.name}
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          {globalState?.selectedAgent?.customizableFields && globalState.selectedAgent?.customizableFields?.map((field) =>
            renderCustomizableField(field)
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={styles.primaryButton}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "START"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

const styles = {
  primaryButton: {
    background: "#22C55E",
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },
};

export default ConfigureAgentModal;
