import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { COLORS } from "../utils/colors";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import SignupModal from "../components/SignupModal";
import Sidebar from "../components/Sidebar";
import FloatingHeader from "../components/FloatingHeader";
import { STANDARD_BORDER_RADIUS } from "../utils/constants";
import AgentChats from "../components/AgentChats";
import DefaultModal from "../components/DefaultModal";
import SelectAgentModal from "../components/SelectAgentModal";
import {
  fetchAllAgents,
  fetchUserInfo,
  logoutFirebase,
} from "../utils/helperMethods";
import OnboardingModal from "../components/OnboardingModal";
import ConfigureAgentModal from "../components/HowItWorksModal.js";
import CreateDeptModal from "../components/CreateDeptModal";
import CreateAgentModal from "../components/CreateAgentModal";
import { conciergeAI } from "../utils/constants";
import { useTheme } from "@emotion/react";
import MobileComingSoon from "../components/MobileComingSoon";
import CancellationModal from "../components/CancellationModal";
import EditDeptModal from "../components/EditDeptModal";
import HowItWorksModal from "../components/HowItWorksModal.js";
import InvitePeopleModal from "../components/InvitePeopleModal.js";

const BACKGROUND_COLOR = "#F1F3F4";

const HomePage = () => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user, setUser } = useContext(UserContext);
  const [activeChatbots, setActiveChatbots] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getUserProfile = async (userId, allAgents) => {
    let userData = await fetchUserInfo(userId);

    if (!userData.orgName) {
      setGlobalState((prevState) => ({
        ...prevState,
        showOnboardingModal: true,
        allAgents: allAgents,
      }));
      return;
    }

    if (userData?.teamMembers && Object.keys(userData.teamMembers).length > 0) {
      userData.teamMembers = Object.values(userData.teamMembers).map(
        (member) => {
          const agentData = allAgents.find((agent) => agent.id === member.id);
          return agentData ? { ...agentData, ...member } : member;
        }
      );

      userData.activeMembers = [
        conciergeAI,
        ...userData.teamMembers.filter((agent) => agent.status === "active"),
      ];
    } else {
      userData.teamMembers = [conciergeAI];
      userData.activeMembers = [conciergeAI];
    }

    setUser(userData);
    setActiveChatbots(userData.activeMembers);
    setGlobalState((prevState) => ({ ...prevState, allAgents: allAgents }));
  };

  useEffect(() => {
    const initializeApp = async () => {
      const allAgents = await fetchAllAgents();

      if (!user?.id) {
        // Visitor logic
        setActiveChatbots([conciergeAI]);
        setUser({ teamMembers: [conciergeAI], activeMembers: [conciergeAI] });
        setGlobalState((prevState) => ({
          ...prevState,
          allAgents,
          selectedView: "Home",
        }));
      } else {
        // Authenticated user logic
        getUserProfile(user.id, allAgents);
      }
    };

    initializeApp();
  }, [user?.id]); // Only re-run if user.id changes

  useEffect(() => {
    if (!globalState?.selectedView) {
      setGlobalState((prevState) => ({ ...prevState, selectedView: "Home" }));
    }
  }, []);

  const backgroundContainer = {
    display: "flex",
    paddingX: "1.5%",
    alignItems: "center",
    height: "100vh",
    backgroundColor: globalState?.darkMode ? COLORS.darkBlue : BACKGROUND_COLOR,
    transition: "background-color 0.3s ease",
  };

  const updateChatbotContext = (chatbotId, newContext) => {
    setActiveChatbots((prevChatbots) =>
      prevChatbots.map((chatbot) =>
        chatbot.id === chatbotId ? { ...chatbot, context: newContext } : chatbot
      )
    );
  };

  if (isMobile) {
    return <MobileComingSoon />;
  }

  return (
    <Box sx={styles.container}>
      {globalState?.showInvitePeopleModal && <InvitePeopleModal />}
      {globalState?.showHowItWorksModal && <HowItWorksModal />}
      {globalState?.showDeptEditModal && <EditDeptModal />}
      {globalState?.showCreateAgentModal && <CreateAgentModal />}
      {globalState?.showCancellationModal && <CancellationModal />}
      {globalState?.showCreateDeptsModal && <CreateDeptModal />}
      {globalState?.configureAgentModal && <ConfigureAgentModal />}
      {globalState?.showOnboardingModal && <OnboardingModal />}
      {globalState?.showSignupModal && <SignupModal />}
      {globalState?.showSelectAgentModal && (
        <SelectAgentModal allAgents={globalState?.allAgents} />
      )}
      {/* {globalState?.showSignupModal && <SignupModal />} */}
      <Box sx={[styles.content, backgroundContainer]}>
        <Sidebar />
        {globalState?.selectedView === "Home" && (
          <Box sx={styles.mainArea}>
            <FloatingHeader />
            <AgentChats
              updateChatbotContext={updateChatbotContext}
            />
          </Box>
        )}


        {/* SELECTED DEPT  */}
        {globalState?.selectedView !== "Home" &&
          globalState?.selectedDepartment && (
            <Box sx={styles.mainArea}>
              <FloatingHeader />
              <AgentChats
                departmentAgents={globalState?.selectedDepartmentViewProfiles || null}
                updateChatbotContext={updateChatbotContext}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

const styles = {
  content: {
    display: "flex",
    paddingX: "1.5%",
    alignItems: "center",
    height: "100vh",
    transition: "background-color 0.3s ease",
  },
  mainArea: {
    // flex: 1,
    height: "95vh",
    backgroundColor: "transparent",
    borderRadius: STANDARD_BORDER_RADIUS,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },
  sidebar: {
    width: 240,
    height: "95vh",
    backgroundColor: "#FFFFFF", // Pure white for sidebar
    borderRadius: 8,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
};

export default HomePage;
