import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const RenderStars = ({ rating }) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<StarIcon key={i} sx={styles.starIcon} />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<StarHalfIcon key={i} sx={styles.starIcon} />);
      } else {
        stars.push(<StarBorderIcon key={i} sx={styles.starIcon} />);
      }
    }

    return stars;

}

const styles = {
    starIcon: {
        color: "#FFD700",
        fontSize: 20,
    },
};

export default RenderStars;
