import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  InputBase,
  IconButton,
  Avatar,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
} from "@mui/material";

import GlobalStateContext from "../context/globalStateContext";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { width } from "@mui/system";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RedClose from "./RedClose";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import RenderStars from "./RenderStars";
import { COLORS } from "../utils/colors";

toastConfig({
  theme: "light",
  position: "top-center",
  maxWidth: "80%",
  duration: 5000,
});

const SelectAgentModal = ({ allAgents }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedAgent, setExpandedAgent] = useState(null);
  const [filteredAgents, setFilteredAgents] = useState(allAgents);
  const handleClose = () => {
    setGlobalState({ ...globalState, showSelectAgentModal: false });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (allAgents) {
      const activeAgents = allAgents.filter(agent => agent.status !== 'archived');
      const filteredAgents = activeAgents.filter(agent =>
        agent.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAgents(filteredAgents);
    }
  }, [searchTerm, allAgents]);
  

  const handleSelectAgent = (agent) => {
    setGlobalState({ ...globalState, showSelectAgentModal: false });
    navigate("/hire/" + agent.roleSlug + "/" + agent.username);
  };

  const handleExpandAgent = (agentUsername) => {
    setExpandedAgent(expandedAgent === agentUsername ? null : agentUsername);
  };

  const AgentCard = ({ agent }) => (
    <Box sx={styles.agentCard}>
      <Box sx={styles.agentHeader}>
        <Avatar src={agent.image} alt={agent.name} sx={styles.avatar} />
        <Typography variant="h6" sx={styles.agentName}>
          {agent.name}
        </Typography>
        <Typography variant="subtitle1" sx={styles.agentUsername}>
          @{agent.username}
        </Typography>
        <Box sx={styles.roleTypeContainer}>
          <Typography variant="body1" sx={styles.agentRole}>
            {agent.role}
          </Typography>
          <Chip label={agent.type} sx={styles.agentType} />
        </Box>

        {/* New Rating and Review Section */}
        <Box sx={styles.ratingContainer}>
          {/* <Typography variant="h4" sx={styles.ratingNumber}>
            {agent.rating || 5}
          </Typography> */}
          <RenderStars rating={agent.rating || 5} />
          {/* <Tooltip title="Total number of reviews" arrow>
            <Box sx={styles.reviewCount}>
              <PersonIcon sx={{ fontSize: 14, marginRight: 0.5 }} />
              <Typography variant="body2">{agent?.reviews?.count}</Typography>
            </Box>
          </Tooltip> */}
        </Box>

        <Typography variant="body2" sx={styles.agentFirstMessage}>
          "{agent.description}"
        </Typography>
      </Box>

      {/* SCROLLABLE CONTENT HERE  */}
      <Box sx={styles.agentScrollableContent}>
        <Box sx={styles.sectionTitle}>Created by</Box>
        <Box sx={{ ...styles.chipContainer }}>
          <Chip
            label={agent.createdBy.username || ""}
            onClick={() => {
              window.location.href = "/profile/" + agent.createdBy.username;
            }}
            sx={{
              cursor: "pointer",
              ...styles.chip,
              backgroundColor: COLORS.diamondBlue,
              color: "white",
            }}
          />
        </Box>

        <Box sx={styles.sectionTitle}>Ideal for</Box>
        <Box sx={styles.chipContainer}>
          {agent.builtFor.map((userType, index) => (
            <Chip key={index} label={userType} sx={styles.chip} />
          ))}
        </Box>

        <Box sx={styles.sectionTitle}>Personality</Box>
        <Box sx={styles.chipContainer}>
          {agent.responseCriteria.personality.map((userType, index) => (
            <Chip key={index} label={userType} sx={styles.chip} />
          ))}
        </Box>

        <Box sx={styles.sectionTitle}>Can help with</Box>
        <Box sx={styles.chipContainer}>
          {agent.canHelpWith.map((specialty, index) => (
            <Chip key={index} label={specialty} sx={styles.chip} />
          ))}
        </Box>

        <Box sx={styles.sectionTitle}>Triggers</Box>
        <Box sx={styles.chipContainer}>
          {agent.trigger.map((trigger, index) => (
            <Chip key={index} label={trigger} sx={styles.chip} />
          ))}
        </Box>

        <Box sx={styles.sectionTitle}>Output destinations</Box>
        <Box sx={styles.chipContainer}>
          {agent?.output.map((trigger, index) => (
            <Chip key={index} label={trigger} sx={styles.chip} />
          ))}
        </Box>

        <Box sx={styles.sectionTitle}>Trained in</Box>
        <Box sx={styles.chipContainer}>
          {agent.specializedFrameworks.map((framework, index) => (
            <Chip key={index} label={framework} sx={styles.chip} />
          ))}
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={styles.selectButton}
        onClick={() => handleSelectAgent(agent)}
      >
        View Resume
      </Button>
    </Box>
  );

  return (
    <Modal
      open={globalState?.showSelectAgentModal}
      onClose={handleClose}
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile)}>
        <Box sx={styles.header}>
          <Typography variant="h5" sx={styles.title}>
            Select an AI team member
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={styles.searchBar}>
              <InputBase
                sx={styles.searchInput}
                placeholder="Search framework or skillsets..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <IconButton
                type="submit"
                sx={styles.searchIcon}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <RedClose handleClose={() => handleClose()} />
          </Box>
        </Box>

        <Box sx={styles.agentGrid}>
          {filteredAgents &&
            filteredAgents?.map((agent) => (
              <AgentCard key={agent.username} agent={agent} />
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "90vw",
    height: "95%",
    overflowY: "hidden", // Prevent vertical scroll
  }),
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0.5rem",
  },
  ratingNumber: {
    fontWeight: 700,
    marginRight: "0.25rem",
    color: "black",
    fontSize: "1.5rem",
  },
  starIcon: {
    color: "#FFC107",
    fontSize: "1.2rem",
    marginRight: "0.5rem",
  },
  reviewCount: {
    display: "flex",
    alignItems: "center",
    color: "#757575",
  },
  personIcon: {
    fontSize: "1rem",
    marginRight: "0.25rem",
  },

  header: {
    display: "flex",
    height: "3vh",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5rem",
    backgroundColor: "white",
  },
  title: {
    fontWeight: "600",
    color: "#333",
    marginLeft: "12px",
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "0.5rem 1rem",
    margin: "1rem",
    width: "100%",
    justifyContent: "space-between",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  searchInput: {
    flex: 1,
    marginLeft: "8px",
    width: "100%",
    fontSize: "1rem",
  },
  searchIcon: {
    padding: "8px",
    color: "#757575",
  },
  agentGrid: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    padding: "1rem",
    gap: "1.5rem",
    height: "90%",
    "&::-webkit-scrollbar": {
      height: "4px",
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },
  agentCard: {
    // flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    width: "280px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
    },
  },

  agentHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "1rem",
  },
  roleTypeContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0.5rem",
  },

  agentScrollableContent: {
    flex: 1,
    overflowY: "auto",
    marginBottom: "1rem",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },

  avatar: {
    width: "100px",
    height: "100px",
    marginBottom: "1rem",
    alignSelf: "center",
    border: "3px solid #E0E0E0",
  },
  agentName: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "0.25rem",
    color: "#333",
  },
  agentUsername: {
    color: "#757575",
    textAlign: "center",
    marginBottom: "0.5rem",
  },
  agentRole: {
    textAlign: "center",
    color: "#424242",
  },
  agentType: {
    alignSelf: "center",
    marginLeft: "1rem",
    backgroundColor: "#E3F2FD",
    color: "#1976D2",
  },
  agentFirstMessage: {
    fontStyle: "italic",
    marginBottom: "1rem",
    textAlign: "center",
    color: "#616161",
    fontSize: "0.8rem",
  },
  accordion: {
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "48px",
      "&.Mui-expanded": {
        minHeight: "48px",
      },
    },
    "& .MuiAccordionSummary-content": {
      margin: "12px 0",
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
  },
  sectionTitle: {
    fontWeight: "bold",
    marginTop: "1rem",
    marginBottom: "0.5rem",
    color: "#424242",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
    marginBottom: "0.5rem",
  },
  chip: {
    fontSize: "0.75rem",
    backgroundColor: "#F5F5F5",
    color: "#616161",
  },
  selectButton: {
    // marginTop: "1rem",
    fontWeight: "bold",
    background: "linear-gradient(45deg, #2196F3, #21CBF3)", // Same gradient as the scroller
    color: "#FFFFFF", // White text
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)", // Reversed gradient on hover
    },
  },
};

export default SelectAgentModal;
