import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import { firebase, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import { guidGenerator } from "../utils/helperMethods";
import { useNavigate } from "react-router-dom";

const CreateAgentModal = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [agentName, setAgentName] = useState("");
  const [agentUsername, setAgentUsername] = useState("");
  const [agentRole, setAgentRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleSlug, setRoleSlug] = useState("");

  useEffect(() => {
    // Update roleSlug whenever agentRole changes
    setRoleSlug(agentRole.toLowerCase().replace(/[^a-z0-9-]/g, "-"));
  }, [agentRole]);

  const handleUsernameChange = (e) => {
    // Replace spaces with hyphens and remove special characters
    const sanitizedUsername = e.target.value
      .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove all characters except alphanumeric, spaces, and hyphens
      .replace(/\s+/g, "-") // Replace one or more spaces with a single hyphen
      .replace(/-+/g, "-"); // Replace multiple consecutive hyphens with a single hyphen
    setAgentUsername(sanitizedUsername);
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!user || !user.id) {
      toast("Error: User not authenticated", { position: "top-center" });
      setIsLoading(false);
      return;
    }

    try {
      const newAgent = {
        id: guidGenerator(),
        name: agentName,
        username: agentUsername,
        role: agentRole,
        roleSlug: roleSlug,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [`teamMembers.${newAgent.id}`]: newAgent,
        });

      toast(`Agent "${agentName}" created successfully`, {
        position: "top-center",
      });
      setGlobalState({ ...globalState, showCreateAgentModal: false });
      setAgentName("");
      setAgentUsername("");
      setAgentRole("");
      setRoleSlug("");

      // navigate 
      window.location.href = `/edit/${roleSlug}/${agentUsername}`;
    } catch (error) {
      console.error("Error creating agent:", error);
      toast("Error creating agent. Please try again.", {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={globalState?.showCreateAgentModal}
      onClose={() =>
        setGlobalState({ ...globalState, showCreateAgentModal: false })
      }
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile)}>
        <Box sx={styles.headerSection}>
          <Typography variant="h4" sx={styles.title}>
            Create AI Talent
          </Typography>
          <Typography variant="subtitle1" sx={styles.subtitle}>
            Design your specialist AI chatbot trained for a specific task
          </Typography>
        </Box>
        <Box sx={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <Box sx={styles.inputGroup}>
              <Typography variant="body2" sx={styles.inputLabel}>
                Name
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                placeholder="e.g., Kellen Manson"
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
                required
                sx={styles.input}
              />
            </Box>
            <Box sx={styles.inputGroup}>
              <Typography variant="body2" sx={styles.inputLabel}>
                Username
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                placeholder="e.g., kellen-manson"
                value={agentUsername}
                onChange={handleUsernameChange}
                required
                sx={styles.input}
                helperText="Spaces will be replaced with hyphens"
              />
            </Box>
            <Box sx={styles.inputGroup}>
              <Typography variant="body2" sx={styles.inputLabel}>
                Role
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                placeholder="e.g., AI Executive Assistant"
                value={agentRole}
                onChange={(e) => setAgentRole(e.target.value)}
                required
                sx={styles.input}
              />
            </Box>
            <Box sx={styles.previewBox}>
              <Typography variant="body2" sx={{ ...styles.previewText }}>
                teammembers.ai/hire/{roleSlug || "{role-slug}"}/
                {agentUsername || "{username}"}
              </Typography>
            </Box>
            <Button
              type="submit"
              variant="contained"
              disabled={
                isLoading ||
                !agentName.trim() ||
                !agentUsername.trim() ||
                !agentRole.trim()
              }
              sx={styles.submitButton}
              fullWidth
            >
              <Typography sx={{ color: "white", fontWeight: 600 }}>
                {isLoading ? "Creating..." : "Create AI Team Member"}
              </Typography>
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "24px",
    width: isMobile ? "100%" : "500px",
    maxHeight: "90vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    p: 4,
  }),
  headerSection: {
    mb: 4,
  },
  title: {
    fontWeight: 700,
    textAlign: "center",
    color: "#333",
    mb: 1,
  },
  subtitle: {
    textAlign: "center",
    color: "#666",
    fontWeight: 400,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  inputLabel: {
    fontWeight: 600,
    color: "#555",
    pt: 2,
  },
  input: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#2196F3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#21CBF3",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 16px",
    },
  },
  previewBox: {
    borderRadius: "12px",
    pt: 2,
    mb: 3,
  },
  previewLabel: {
    fontWeight: 600,
    color: "#555",
    mb: 1,
  },
  previewText: {
    fontStyle: "italic",
    color: "#666",
    wordBreak: "break-all",
  },

  inviteeRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },
  actionButton: {
    color: "#2196F3",
    "&:hover": {
      color: "#21CBF3",
    },
  },

  addButton: {
    mt: 2,
    color: "#2196F3",
    borderColor: "#2196F3",
    "&:hover": {
      borderColor: "#21CBF3",
      backgroundColor: "rgba(33, 203, 243, 0.04)",
    },
    textTransform: "none",
    fontWeight: 600,
  },
  submitButton: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(33, 150, 243, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)",
      boxShadow: "0 4px 6px rgba(33, 150, 243, 0.3)",
      transform: "translateY(-2px)",
    },
  },

  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  stepIndicator: (isActive) => ({
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: isActive
      ? "linear-gradient(45deg, #2196F3, #21CBF3)"
      : "#E0E0E0",
    color: isActive ? "#FFFFFF" : "#757575",
    fontWeight: "bold",
    transition: "all 0.3s ease",
  }),
  progressLine: {
    width: 100,
    height: 4,
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    margin: "0 10px",
    transformOrigin: "left",
  },

  progressBar: {
    marginBottom: 2,
    height: 8,
    borderRadius: 4,
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
  },

  deleteButton: {
    color: "#F44336",
    marginLeft: 1,
  },

  buttonContainer: {
    mt: 3,
    display: "flex",
    justifyContent: "flex-end",
  },

  successMessage: {
    mb: 2,
    color: "#4CAF50",
    fontWeight: 500,
    textAlign: "center",
  },
};

export default CreateAgentModal;
