import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import axios from "axios"; // Make sure to install axios: npm install axios
import { functions } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { httpsCallable } from "firebase/functions";
import JoinTheDiscord from "../components/JoinTheDiscord";
import GlobalStateContext from "../context/globalStateContext";
import { PRICING_PLANS, YEARLY_PRICING_PLANS } from "../utils/constants";
import BillingToggle from "../components/BillingToggle";
import UserContext from "../context/userContext";
import { fetchAllAgents, fetchUserInfo } from "../utils/helperMethods";
import { COLORS } from "../utils/colors";
import Footer from "../components/Footer";

const Pricing = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [intervalType, setIntervalType] = useState("monthly");
  const [loadingPlan, setLoadingPlan] = useState(null);

  const getUserProfile = async (userInfo) => {
    let userData = await fetchUserInfo(userInfo.id);
    // Show onboarding if !user.orgName
    if (!userData.orgName) {
      setGlobalState({
        ...globalState,
        showOnboardingModal: true,
      });
      return;
    }

    setUser(userData);
  };

  useEffect(() => {
    const fetchAgents = async () => {
      if (user?.id && !user?.email) getUserProfile(user);
    };

    fetchAgents();
  }, [user]);

  // Function to create Stripe Checkout session
  const handleSubscription = async (plan) => {
    if (!user?.id) {
      toast("Please sign in to continue");
      return;
    }

    setLoadingPlan(plan.stripePriceId);

    try {
      const response = await axios.post(
        "https://us-central1-teammembers-ai.cloudfunctions.net/createCheckoutSubscription",
        {
          priceId: plan.stripePriceId,
          userId: user.id,
          packageName: plan.title,
          interval: plan.interval,
        }
      );

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      toast(
        "An error occurred while creating the checkout session. Please try again."
      );
    } finally {
      setLoadingPlan(null);
    }
  };

  const renderPricingCard = (plan, index, isYearly) => {
    const displayPrice = isYearly ? (plan.price / 12).toFixed(2) : plan.price;
    const isLoading = loadingPlan === plan.stripePriceId;

    return (
      <Box key={index} sx={index === 2 ? styles.enterpriseCard : styles.card}>
        <Typography sx={styles.cardTitle}>{plan.displayName}</Typography>
        <Typography
          sx={{
            ...styles.cardSubtitle,
            color: index === 2 ? "white" : "black",
          }}
        >
          {plan.subtitle}
        </Typography>
        <Box sx={styles.priceContainer}>
          <Typography sx={styles.price}>${displayPrice}</Typography>
          <Typography sx={styles.period}>/ mo</Typography>
        </Box>
        <Typography
          sx={{ ...styles.description, color: index === 2 ? "white" : "black" }}
        >
          Billed annually as ${plan.price}
        </Typography>
        {isYearly && (
          <Typography sx={{ ...styles.savings, color: "green", pb: 2 }}>
            Save ${(displayPrice * 3).toFixed(2)} annually
          </Typography>
        )}
        {plan.features.map((feature, i) => (
          <Typography key={i} sx={styles.feature}>
            {feature}
          </Typography>
        ))}
        <Button
          disabled={isLoading || user?.premiumUser?.packageName === plan.title}
          onClick={() => handleSubscription(plan)}
          variant={index === 2 ? "contained" : "outlined"}
          fullWidth
          sx={{
            ...styles.enterpriseButton,
            ":disabled": {
              color: "white",
            },
            backgroundColor:
              user?.premiumUser?.packageName === plan.title
                ? COLORS.lightGrey
                : "#007bff",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : user?.premiumUser?.packageName === plan.title ? (
            "Current Plan"
          ) : (
            "SELECT"
          )}
        </Button>

        {plan.popular && <Box sx={styles.popularTag}>Most Popular</Box>}
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant="h3" sx={styles.title}>
          Build Your AI Dream Team
        </Typography>
        <Typography variant="h6" sx={styles.subtitle}>
          Unlock your AI potential
        </Typography>

        <Box sx={styles.planSection}>
          <Box sx={styles.choosePlan}>
            <Typography variant="h6" sx={styles.choosePlanTitle}>
              Choose plan
            </Typography>
            <BillingToggle
              intervalType={intervalType}
              setIntervalType={setIntervalType}
            />
          </Box>

          <Box sx={styles.pricingCards}>
            {(intervalType === "monthly"
              ? PRICING_PLANS
              : YEARLY_PRICING_PLANS
            ).map((plan, index) =>
              renderPricingCard(plan, index, intervalType === "yearly")
            )}
          </Box>
        </Box>
      </Box>
      {/* New Community Join Section */}
      <JoinTheDiscord />
      <Box sx={styles.spacing} />
      <Footer />
    </Box>
  );
};

const styles = {
  container: {
    height: "100%",
    bgcolor: "#F8F9FA",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  spacing: { pb: 20 },
  content: {
    width: "80%",
    height: "100%",
    bgcolor: "transparent",
    borderRadius: 4,
    pt: "10%",
    px: "10%",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    mb: 1,
    fontSize: "3.5rem",
  },
  subtitle: {
    textAlign: "center",
    mb: 12,
    color: "text.secondary",
    fontWeight: "normal",
    fontSize: "1.5rem",
  },
  planSection: {
    display: "flex",
    mb: 4,
  },
  choosePlan: {
    mr: 4,
  },
  choosePlanTitle: {
    fontWeight: "bold",
    mb: 2,
  },
  billingOptions: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  billingOption: {
    display: "flex",
    alignItems: "center",
  },
  pricingCards: {
    display: "flex",
    gap: 3,
    flex: 3,
  },
  card: {
    flex: 1,
    bgcolor: "white",
    p: 3,
    borderRadius: 2,
    border: "1px solid #E9ECEF",
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },
  enterpriseCard: {
    flex: 1,
    bgcolor: "#212529",
    color: "white",
    p: 3,
    borderRadius: 2,
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  cardTitle: {
    fontWeight: "bold",
    mb: 1,
    fontSize: "1.25rem",
  },
  cardSubtitle: {
    mb: 2,
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  priceContainer: {
    display: "flex",
    alignItems: "baseline",
    mb: 1,
  },
  price: {
    fontWeight: "bold",
    fontSize: "2rem",
  },
  period: {
    ml: 0.5,
    fontSize: "0.875rem",
  },
  description: {
    mb: 2,
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  feature: {
    display: "flex",
    alignItems: "center",
    mb: 1,
    fontSize: "0.875rem",
    "&::before": {
      content: '""',
      width: 6,
      height: 6,
      borderRadius: "50%",
      bgcolor: "grey.400",
      mr: 1,
      display: "inline-block",
    },
  },
  button: {
    mt: 2,
    color: "primary.main",
    borderColor: "primary.main",
    "&:hover": {
      bgcolor: "transparent",
    },
  },
  enterpriseButton: {
    mt: 2,
    color: "white",
    bgcolor: "#007bff",
    "&:hover": {
      bgcolor: "#0056b3",
    },
  },
  popularTag: {
    position: "absolute",
    top: 12,
    right: 12,
    bgcolor: "#FF00FF",
    color: "white",
    px: 1,
    py: 0.5,
    borderRadius: 1,
    fontSize: 12,
  },
};

export default Pricing;
