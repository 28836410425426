import { AVATARS } from "./constants";
import { firebase, firestore } from "../firebaseConfig";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';

export const generateAvatars = (numAvatars) => {
    const womenAvatars = AVATARS.filter(url => url.includes('women'));
    const menAvatars = AVATARS.filter(url => url.includes('men'));
  
    const totalWomen = Math.ceil(numAvatars * 0.8);
    const totalMen = numAvatars - totalWomen;
  
    const selectedWomen = womenAvatars.slice(0, totalWomen);
    const selectedMen = menAvatars.slice(0, totalMen);
  
    return [...selectedWomen, ...selectedMen];
  };
  

  export const typewriterEffect = (text, callback) => {
    let i = 0;
    
    const interval = setInterval(() => {
      if (i < text?.length) {
        callback(text.substring(0, i + 1));
        i++;
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust typing speed here
  };

  export const getRelativeTime = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "today";
    } else if (diffDays === 1) {
      return "yesterday";
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  export const fetchUserInfo = async (userId) => {
    try {
      const userDoc = await firestore.collection('users').doc(userId).get();
      if (userDoc.exists) {
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  export const fetchAllAgents = async (userId) => {
    try {
      const agentsSnapshot = await firestore.collection('agents').get();
      if (!agentsSnapshot.empty) {
        const agents = agentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        return agents;
      } else {
        console.log("No agents found!");
        return [];
      }
    } catch (error) {
      console.error("Error fetching agents data:", error);
      return null;
    }
  };
  export const logoutFirebase = async () => {
    try {
      await firebase.auth().signOut();
      toast("Successfully logged out", { position: 'top-right' });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  export const handleNav = path => {
    window.location.href = path;
  }

  export const guidGenerator = () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}`;
  }

  export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (l) => l.toUpperCase());
  };