import React from 'react';
import { Box, Typography, Link, Tooltip, Zoom } from '@mui/material';
import {
  Home as HomeIcon,
  Diamond as DiamondIcon,
  People as PeopleIcon,
  Store as ShopIcon,
  AttachMoney as IncomeIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Notes as NotesIcon,
  LoginRounded,
  HelpOutlineOutlined,
} from "@mui/icons-material";
import { keyframes } from '@mui/system';

const sparkle = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'transparent',
        padding: '2rem',
        borderTop: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        onClick={() => (window.location.href = "/")}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Tooltip title="Shine bright like a diamond!" TransitionComponent={Zoom} arrow>
          <DiamondIcon
            sx={{
              fontSize: 36,
              color: "#1A73E8",
              "&:hover": {
                animation: `${sparkle} 0.5s ease-in-out`,
              },
            }}
          />
        </Tooltip>
        <Box sx={{ ml: 1, lineHeight: 1.2 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              background: "linear-gradient(45deg, #1A73E8 30%, #34A853 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            TeamMembersAI
          </Typography>
          
        </Box>
      </Box>

      <Box sx={{ my: 2 }}>
        {['Privacy', 'Terms', 'Contact'].map((item, index) => (
          <Link
            key={item}
            href={`/${item.toLowerCase()}`}
            sx={{
              color: 'text.secondary',
              textDecoration: 'none',
              mx: 1,
              '&:hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
            }}
          >
            {item}
          </Link>
        ))}
      </Box>

      <Typography variant="body2" color="text.secondary">
        © {currentYear} TeamMembersAI. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;