import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";

const FireAgentConfirmationModal = ({
  open,
  handleClose,
  handleConfirm,
  agentName,
}) => {
  const { globalState } = useContext(GlobalStateContext);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="fire-agent-dialog-title"
      aria-describedby="fire-agent-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "16px",
          background: "linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle id="fire-agent-dialog-title" style={{ color: "#d32f2f" }}>
        Confirm Agent Removal
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="fire-agent-dialog-description"
          style={{ color: "#333" }}
        >
          Are you sure you want to remove {agentName} from your team? You'll
          lose all saved memory with this agent.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "16px" }}>
        <Button onClick={handleClose} style={{ color: "#666" }}>
          Cancel
        </Button>
        <Button
          disabled={globalState?.isLoading}
          onClick={handleConfirm}
          style={{
            cursor: "pointer",
            background: "linear-gradient(45deg, #d32f2f 30%, #f44336 90%)",
            color: "white",
            borderRadius: "20px",
            padding: "8px 24px",
          }}
        >
          YOU'RE FIRED
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FireAgentConfirmationModal;
