import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  InputBase,
  IconButton,
  Box,
  Avatar,
  Chip,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { borderBottomColor } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { firebase, firestore } from "../firebaseConfig";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

import { CHAT_HEIGHT, STANDARD_BORDER_RADIUS } from "../utils/constants";
import { COLORS } from "../utils/colors";
import { STANDARD_AGENT_CHAT_WIDTH } from "../utils/constants";
import {
  generateAvatars,
  getRelativeTime,
  typewriterEffect,
} from "../utils/helperMethods";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import FireAgentConfirmationModal from "./FireAgentConfirmationModal";
import { doc, onSnapshot } from "firebase/firestore";

const AddNewAITeamMember = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showAvatars, setShowAvatars] = useState([]);

  useEffect(() => {
    const genAvatars = generateAvatars(5);
    setShowAvatars(genAvatars);
  }, []);

  return (
    <Box sx={styles.addNewContainer}>
      <Box sx={styles.addNewContent}>
        <Box sx={styles.facePile}>
          {showAvatars.map((avatar, index) => (
            <Avatar key={index} src={avatar} sx={styles.avatarStyles} />
          ))}
        </Box>
        <Box
          onClick={() =>
            setGlobalState({ ...globalState, showSelectAgentModal: true })
          }
          sx={styles.gradientCircle}
        >
          <AddIcon sx={styles.addIcon} />
        </Box>
        <Typography sx={[styles.addNewText, { color: COLORS.diamondBlue }]}>
          BROWSE AVAILABLE
          <Typography sx={[styles.addNewText, { color: "black" }]}>
            AI TALENT POOL
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

const SoloChatView = ({ agent, updateChatbotContext, expanded }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [fireModalOpen, setFireModalOpen] = useState(false);
  const [agentToFire, setAgentToFire] = useState(null);
  const messagesEndRef = useRef(null);

  const [showWebhookDropdown, setShowWebhookDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const [selectedWebhookIndex, setSelectedWebhookIndex] = useState(-1);

  const [showMemoryDropdown, setShowMemoryDropdown] = useState(false);
  const [longTermMemoryItems, setLongTermMemoryItems] = useState([]);

  // EVENT LISTENER - updates chat when /longTermMemory > user updates
  useEffect(() => {
    if (user?.id && agent?.id) {
      const userRef = doc(firestore, "longTermMemory", user.id);
      const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data && data[agent.id]) {
            const items = Object.entries(data[agent.id]).flatMap(
              ([webhookName, webhookData]) => {
                return Object.entries(webhookData).map(
                  ([timestamp, entry]) => ({
                    webhookName,
                    timestamp: Number(timestamp),
                    data: entry.data,
                  })
                );
              }
            );
            // Sort by timestamp, descending
            items.sort((a, b) => b.timestamp - a.timestamp);
            setLongTermMemoryItems(items);

            // Add notification for newly added data
            const latestItem = items[0];
            if (latestItem && Date.now() - latestItem.timestamp < 5000) {
              // Check if data was added in the last 5 seconds
              const message = `I just saved some data from ${latestItem.webhookName} to long term memory.`;
              setMessages((prev) => [...prev, { text: "", sender: "ai" }]);
              typewriterEffect(message, (typedText) => {
                setMessages((prev) =>
                  prev.map((msg, index) =>
                    index === prev.length - 1
                      ? { ...msg, text: typedText }
                      : msg
                  )
                );
              });
            }
          }
        }
      });

      return () => unsubscribe();
    }
  }, [user?.id, agent?.id]);

  // Fetch previous conversation - if any
  useEffect(() => {
    const fetchChatHistory = async () => {
      if (user && user.id && agent.id) {
        try {
          const conversationsRef = firestore
            .collection("conversations")
            .doc(user.id)
            .collection(agent.id);
          const snapshot = await conversationsRef.orderBy("timestamp").get();

          if (!snapshot.empty) {
            const chatHistory = snapshot.docs
              .map((doc) => {
                const data = doc.data();
                return [
                  {
                    text: data.userMessage,
                    sender: "user",
                    timestamp: data.timestamp,
                  },
                  {
                    text: data.aiMessage,
                    sender: "ai",
                    timestamp: data.timestamp,
                  },
                ];
              })
              .flat()
              .sort((a, b) => a.timestamp - b.timestamp);

            setMessages(chatHistory);
          } else {
            if (agent.firstMessage) {
              setMessages([{ text: "", sender: "ai" }]);
              typewriterEffect(agent.firstMessage, (typedText) => {
                setMessages([{ text: typedText, sender: "ai" }]);
              });
            }
          }
        } catch (error) {
          console.error("Error fetching chat history:", error);
        }
      }
    };

    if (user?.email) fetchChatHistory(); // if visitor dont fetch there is none
  }, [agent.id, user]);

  useEffect(() => {
    // for visitor show Donna and her first message
    if (!user?.email) {
      setMessages([{ text: "", sender: "ai" }]);
      typewriterEffect(agent.firstMessage, (typedText) => {
        setMessages([{ text: typedText, sender: "ai" }]);
      });
    }
  }, [user]);

  // Scroll to the bottom
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFireClick = (agent) => {
    setAgentToFire(agent);
    setFireModalOpen(true);
  };

  const sendMessageToChatbot = async (message) => {
    try {
      const response = await axios.post(
        "https://us-central1-teammembers-ai.cloudfunctions.net/getChatGPTResponse",
        {
          agent, // Include agent details
          chatLog: messages, // Include chat history
          message,
        }
      );
      return response.data.response;
    } catch (error) {
      console.error("Error getting chatbot response:", error);
      return "Sorry, I encountered an error.";
    }
  };

  const saveChat = async (agent, userMessage, aiMessage) => {
    if (!user || !user.id) {
      console.error("User is not authenticated.");
      return;
    }

    try {
      // conversation > user id > agent id > chatLog
      const conversationsRef = firestore
        .collection("conversations")
        .doc(user.id);
      const agentRef = conversationsRef.collection(agent.id);

      const chatLog = {
        userMessage,
        aiMessage,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await agentRef.add(chatLog);

    } catch (error) {
      console.error("Error saving chat log:", error);
      console.error("Error details:", error.response?.data || error.message);
    }
  };

  const getResponseFormula = async () => {
    if (!user?.id) {
      // visitor
      return "Nice to meet you. Start by browsing our AI talent pool to get a feel of our app";
    } else if (user?.id && user?.premiumUser?.isActive) {
      // logged in paying user
      const response = await sendMessageToChatbot(input);
      return response;
    } else {
      // logged in user but not paying
      return "You'll need to be subscribed to talk to me. Click the pricing button to the bottom left of the screen.";
    }
  };

  const handleSend = async () => {
    if (input.trim()) {
      // Add user message to chat history
      setMessages((prev) => [...prev, { text: input, sender: "user" }]);

      // Efficient check for data source call
      const lowerCaseInput = input.toLowerCase();
      const fetchIndex = lowerCaseInput.indexOf("fetch");
      const analyzeCommand = /\banalyze\b/.test(lowerCaseInput);

      if (fetchIndex !== -1 && !analyzeCommand) {
        const potentialWebhook = input.slice(fetchIndex).split(/\s+/)[0];
        const matchingWebhook = agent.webhooks.find(
          (webhook) =>
            webhook.name.toLowerCase() === potentialWebhook.toLowerCase()
        );

        if (matchingWebhook) {
          const response = await axios.get(matchingWebhook.link);

          // Save response to long term memory
          const userRef = firestore.collection("longTermMemory").doc(user.id);
          const timestamp = Date.now();

          await userRef.set(
            {
              [agent.id]: {
                [matchingWebhook.name]: {
                  [timestamp]: {
                    time: timestamp,
                    data: response?.data || "No data available",
                  },
                },
              },
            },
            { merge: true }
          );

          setInput("");
          return;
        }
      } else if (analyzeCommand) {
        // Handle analyze command
        const potentialMemoryItem = input.slice(analyzeCommand).split(/\s+/)[1];
        const matchingMemoryItem = longTermMemoryItems.find(
          (item) =>
            item.webhookName.toLowerCase() === potentialMemoryItem.toLowerCase()
        );
      
        if (matchingMemoryItem) {
          // START HERE CLAUDE.ai
          setMessages((prev) => [...prev, { text: "", sender: "ai" }]);
          try {
            const analysisPrompt = `Analyze the following data: ${JSON.stringify(matchingMemoryItem.data)}
              Context: You are an AI ${agent.role} with expertise in ${agent.specializedFrameworks.join(", ")}.
              Your purpose is to ${agent.description}.
              Please provide a detailed analysis based on your specialized knowledge.`;
      
            const response = await sendMessageToChatbot(analysisPrompt);
      
            const responseMessage = `Sure!:\n\n${response}`;
            // const responseMessage = `Analysis of data from ${matchingMemoryItem.webhookName}:\n\n${response}`;
            typewriterEffect(
              responseMessage,
              (typedText) => {
                setMessages((prev) =>
                  prev.map((msg, index) =>
                    index === prev.length - 1 ? { ...msg, text: typedText } : msg
                  )
                );
              }
            );
      
            // Save the analysis to chat history
            saveChat(agent, `Analyze ${matchingMemoryItem.webhookName} data`, response);
      
            // Update context after full message is displayed
            setTimeout(() => {
              updateChatbotContext(agent.id, {
                ...agent.context,
                lastMessage: response,
              });
            }, response.length * 50);
      
          } catch (error) {
            console.error("Error analyzing data:", error);
            setMessages((prev) => [
              ...prev,
              { text: "Sorry, I encountered an error while analyzing the data.", sender: "ai" },
            ]);
          }
          setInput("");
          return;
        }
      }

      setInput("");

      try {
        // Get response from chatbot OR for visitors, show a static message
        const response = await getResponseFormula();

        // Add AI response to chat history
        setMessages((prev) => [...prev, { text: response, sender: "ai" }]);

        // Optionally save the chat log to the database = if signed in - if not do nothing
        saveChat(agent, input, response);

        // Apply typewriter effect
        typewriterEffect(response, (typedText) => {
          setMessages((prev) =>
            prev.map((msg, index) =>
              index === prev.length - 1 ? { ...msg, text: typedText } : msg
            )
          );
        });

        // Update context after full message is displayed
        setTimeout(() => {
          updateChatbotContext(agent.id, {
            ...agent.context,
            lastMessage: response,
          });
        }, response.length * 50); // Adjust based on typewriter speed
      } catch (error) {
        console.error("Error getting chatbot response:", error);
        setMessages((prev) => [
          ...prev,
          { text: "Sorry, I encountered an error.", sender: "ai" },
        ]);
      }
    }
  };

  const handleFireTeamMember = async () => {
    if (!user || !user.id || !agentToFire) {
      console.error("User not authenticated or no agent selected for firing");
      toast("Error: Unable to remove team member", {
        position: "top-right",
        duration: 3000,
      });
      return;
    }

    try {
      setGlobalState((prevState) => ({ ...prevState, isLoading: true }));

      const userRef = firestore.collection("users").doc(user.id);

      // Remove the specific agent from the teamMembers object
      await userRef.update({
        [`teamMembers.${agentToFire.id}`]:
          firebase.firestore.FieldValue.delete(),
      });

      // Show success message
      toast(`${agentToFire.name} has been removed from your team`, {
        position: "top-right",
        duration: 3000,
      });

      // Close the modal and reset agentToFire
      setFireModalOpen(false);
      setAgentToFire(null);

      // reset user state
      setUser((prevUser) => {
        const updatedTeamMembers = { ...prevUser.teamMembers };
        delete updatedTeamMembers[agentToFire.id];
        return {
          ...prevUser,
          teamMembers: updatedTeamMembers,
        };
      });

      // Short delay to ensure the toast is visible before refresh
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error removing team member:", error);
      toast("Error: Failed to remove team member. Please try again.", {
        position: "top-right",
        duration: 3000,
      });
    } finally {
      setGlobalState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderFireConfirmModal = (agent) => {
    return (
      <FireAgentConfirmationModal
        open={fireModalOpen}
        handleClose={() => setFireModalOpen(false)}
        handleConfirm={() => {
          handleFireTeamMember(agent);
        }}
        agentName={agentToFire?.name}
      />
    );
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);

    const lowerCaseInput = newInput.toLowerCase();
    if (
      lowerCaseInput.endsWith("fetch") &&
      agent.webhooks &&
      agent.webhooks.length > 0
    ) {
      setShowWebhookDropdown(true);
      setShowMemoryDropdown(false);
      setAnchorEl(inputRef.current);
      setSelectedWebhookIndex(-1);
    } else if (
      lowerCaseInput.endsWith("analyze") &&
      longTermMemoryItems.length > 0
    ) {
      setShowMemoryDropdown(true);
      setShowWebhookDropdown(false);
      setAnchorEl(inputRef.current);
      setSelectedWebhookIndex(-1);
    } else {
      setShowWebhookDropdown(false);
      setShowMemoryDropdown(false);
    }
  };

  const handleKeyDown = (e) => {
    const isFetchDetected = input.toLowerCase().includes("fetch");
    const isAnalyzeDetected = input.toLowerCase().includes("analyze");

    if (e.key === "Enter") {
      if (e.shiftKey) {
        return; // Default behavior (new line) will occur
      } else if (
        (showWebhookDropdown || showMemoryDropdown) &&
        selectedWebhookIndex !== -1
      ) {
        e.preventDefault();
        if (showWebhookDropdown) {
          handleWebhookSelect(agent.webhooks[selectedWebhookIndex]);
        } else if (showMemoryDropdown) {
          handleMemorySelect(longTermMemoryItems[selectedWebhookIndex]);
        }
        setShowWebhookDropdown(false);
        setShowMemoryDropdown(false);
        setSelectedWebhookIndex(-1);
      } else {
        handleSend();
      }
    } else if (
      (isFetchDetected || isAnalyzeDetected) &&
      (e.key === "ArrowUp" || e.key === "ArrowDown")
    ) {
      e.preventDefault();
      const items = isFetchDetected ? agent.webhooks : longTermMemoryItems;
      if (!showWebhookDropdown && !showMemoryDropdown) {
        setShowWebhookDropdown(isFetchDetected);
        setShowMemoryDropdown(isAnalyzeDetected);
        setAnchorEl(inputRef.current);
        setSelectedWebhookIndex(e.key === "ArrowUp" ? items.length - 1 : 0);
      } else {
        setSelectedWebhookIndex((prevIndex) => {
          if (e.key === "ArrowUp") {
            return prevIndex > 0 ? prevIndex - 1 : items.length - 1;
          } else {
            return prevIndex < items.length - 1 ? prevIndex + 1 : 0;
          }
        });
      }
    } else if (
      e.key === "Escape" &&
      (showWebhookDropdown || showMemoryDropdown)
    ) {
      setShowWebhookDropdown(false);
      setShowMemoryDropdown(false);
      setSelectedWebhookIndex(-1);
    }
  };

  const renderWebhookDropdown = () => {
    if (
      !showWebhookDropdown ||
      !agent.webhooks ||
      agent.webhooks.length === 0
    ) {
      return null;
    }

    return (
      <Popper
        open={showWebhookDropdown}
        anchorEl={anchorEl}
        placement="top-start"
      >
        <Paper>
          <MenuList>
            {agent.webhooks.map((webhook, index) => (
              <Tooltip
                key={webhook.id}
                title={webhook.description}
                placement="right"
              >
                <MenuItem
                  onClick={() => handleWebhookSelect(webhook)}
                  selected={index === selectedWebhookIndex}
                >
                  {webhook.name}
                </MenuItem>
              </Tooltip>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    );
  };

  const handleWebhookSelect = (webhook) => {
    setInput((prevInput) => {
      // Remove "fetch" if it's at the end of the input
      const trimmedInput = prevInput
        .trim()
        .replace(/fetch$/i, "")
        .trim();
      return `${trimmedInput} ${webhook.name}`.trim();
    });
    setShowWebhookDropdown(false);
    inputRef.current.focus();
  };

  const handleMemorySelect = (memoryItem) => {
    setInput((prevInput) => {
      const trimmedInput = prevInput.trim();
      if (trimmedInput.toLowerCase().startsWith("analyze")) {
        return `${trimmedInput} ${
          memoryItem.webhookName
        } data (from ${getRelativeTime(memoryItem?.timestamp)})`.trim();
      } else {
        return `analyze ${memoryItem.webhookName} data`.trim();
      }
    });
    setShowMemoryDropdown(false);
    inputRef.current.focus();
  };

  const renderDropdown = () => {
    if (
      (!showWebhookDropdown && !showMemoryDropdown) ||
      (!agent.webhooks && !longTermMemoryItems) ||
      ((agent.webhooks?.length === 0 || !agent.webhooks) &&
        longTermMemoryItems.length === 0)
    ) {
      return null;
    }

    const items = showWebhookDropdown
      ? agent.webhooks || []
      : longTermMemoryItems;

    return (
      <Popper
        open={showWebhookDropdown || showMemoryDropdown}
        anchorEl={anchorEl}
        placement="top-start"
      >
        <Paper>
          <MenuList>
            {items.map((item, index) => (
              <Tooltip
                key={showWebhookDropdown ? item.id : item.timestamp}
                title={
                  showWebhookDropdown ? item.description : "View latest data"
                }
                placement="right"
              >
                <MenuItem
                  onClick={() =>
                    showWebhookDropdown
                      ? handleWebhookSelect(item)
                      : handleMemorySelect(item)
                  }
                  selected={index === selectedWebhookIndex}
                >
                  {showWebhookDropdown ? (
                    item.name
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{`${item.webhookName} data`}</span>
                      <Typography
                        variant="caption"
                        sx={{ marginLeft: 2, color: "text.secondary" }}
                      >
                        from {getRelativeTime(item.timestamp)}
                      </Typography>
                    </Box>
                  )}
                </MenuItem>
              </Tooltip>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    );
  };

  return (
    <Box sx={styles.agentContainer}>
      {renderFireConfirmModal()}
      {renderDropdown()}
      {/* SEARCH BAR */}
      <Box sx={styles.searchBar}>
        <InputBase
          placeholder="Search conversation"
          sx={{ flex: 1, fontSize: 14 }}
        />
        <IconButton type="submit" sx={{ p: "6px" }} aria-label="search">
          <SearchIcon sx={{ color: "#9AA0A6", fontSize: 18 }} />
        </IconButton>
      </Box>

      {/* Expandable Section */}
      <Collapse in={expanded} sx={{ width: "100%" }}>
        <Box sx={styles.expandedSection}>
          {/* Description */}
          <Typography variant="body2" sx={styles.description}>
            "{agent.description}"
          </Typography>

          {/* CREATED BY  */}
          <Typography variant="subtitle2" sx={styles.sectionTitle}>
            Created by:
          </Typography>
          <Box sx={styles.chipContainer}>
            <Chip
              key={"3"}
              label={agent.createdBy.username || ""}
              sx={styles.chip}
              size="small"
            />
          </Box>

          {/* Can Help With */}
          <Typography variant="subtitle2" sx={styles.sectionTitle}>
            Can help with:
          </Typography>
          <Box sx={styles.chipContainer}>
            {agent?.canHelpWith.map((item, index) => (
              <Chip key={index} label={item} sx={styles.chip} size="small" />
            ))}
          </Box>

          {/* Specialized Frameworks */}
          <Typography variant="subtitle2" sx={styles.sectionTitle}>
            Expert in:
          </Typography>
          <Box sx={styles.chipContainer}>
            {agent.specializedFrameworks.map((framework, index) => (
              <Chip
                key={index}
                label={framework}
                sx={styles.chip}
                size="small"
              />
            ))}
          </Box>

          {/* Built For */}
          <Typography variant="subtitle2" sx={styles.sectionTitle}>
            Ideal for:
          </Typography>
          <Box sx={styles.chipContainer}>
            {agent.builtFor.map((userType, index) => (
              <Chip
                key={index}
                label={userType}
                sx={styles.chip}
                size="small"
              />
            ))}
          </Box>

          {/* Personality */}
          <Typography variant="subtitle2" sx={styles.sectionTitle}>
            Personality:
          </Typography>
          <Box sx={styles.chipContainer}>
            {agent.responseCriteria.personality.map((trait, index) => (
              <Chip key={index} label={trait} sx={styles.chip} size="small" />
            ))}
          </Box>

          {/* Type and Trigger */}
          <Box sx={styles.infoRow}>
            <Box sx={styles.infoItem}>
              <Typography variant="subtitle2" sx={styles.infoLabel}>
                Type:
              </Typography>
              <Chip label={agent.type} sx={styles.typeChip} size="small" />
            </Box>
            <Box sx={styles.infoItem}>
              <Typography variant="subtitle2" sx={styles.infoLabel}>
                Triggered by:
              </Typography>
              <Chip label={agent.trigger[0]} sx={styles.chip} size="small" />
            </Box>
          </Box>

          {/* Quick Action Menu */}
          <Box sx={styles.quickActionMenu}>
            <Button
              onClick={() => {
                navigate("/hire/" + agent.roleSlug + "/" + agent.username);
              }}
              startIcon={<InfoIcon />}
              sx={styles.actionButton}
              size="small"
            >
              Full Profile
            </Button>
            {/* TODO - fetch from long term memory */}
            {/* <Button
        onClick = {null}
        startIcon={<HistoryIcon />}
        sx={styles.actionButton}
        size="small"
      >
        
      </Button> */}
            {/* SETTINGS */}
            <Button
              onClick={() => {
                setGlobalState({
                  ...globalState,
                  configureAgentModal: true,
                  selectedAgent: agent,
                });
              }}
              startIcon={<SettingsIcon />}
              sx={styles.actionButton}
              size="small"
            >
              Settings
            </Button>
            {/* FIRE */}
            <Button
              onClick={() => handleFireClick(agent)}
              startIcon={<PersonRemoveIcon />}
              sx={{
                ...styles.actionButton,
                borderRadius: "25px",
                padding: "4px 14px",
                color: "white",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              size="small"
            >
              FIRE
            </Button>
          </Box>
        </Box>
      </Collapse>

      {/* CHAT MESSAGES */}
      <Box sx={styles.messageContainer}>
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={
              message.sender === "user" ? styles.userMessage : styles.aiMessage
            }
          >
            {message.sender === "ai" && (
              <Avatar
                src={agent.image}
                alt={agent.name}
                sx={styles.messageAvatar}
              />
            )}
            <Box sx={styles.messageContent}>
              <Typography
                sx={{
                  ...styles.messageText,
                  ...(message.sender === "user"
                    ? styles.userMessageText
                    : styles.aiMessageText),
                }}
              >
                {message.text}
              </Typography>
              <Typography sx={styles.messageTime}>
                {new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* INPUT FIELD */}
      <Box sx={styles.inputContainer}>
        <InputBase
          fullWidth
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={
            agent?.webhooks && agent.webhooks.length > 0
              ? "Type 'fetch' to call available data sources..."
              : longTermMemoryItems.length > 0
              ? "Type 'analyze' to analyze saved data..."
              : "Type a message..."
          }
          sx={styles.input}
          multiline
          rowsMax={4}
          inputProps={{ style: { paddingRight: "48px" } }}
          inputRef={inputRef}
        />
        <IconButton onClick={handleSend} sx={styles.sendButton}>
          <SendIcon />
        </IconButton>
      </Box>
      {renderWebhookDropdown()}
    </Box>
  );
};

const AgentChats = ({ updateChatbotContext, departmentAgents }) => {
  const { user } = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderChatHeader = (agent) => {
    return (
      <Box sx={styles.chatHeader}>
        <Avatar src={agent.image} alt={agent.name} sx={styles.avatar} />
        <Box sx={styles.headerInfo}>
          <Typography variant="subtitle1" sx={styles.agentName}>
            {agent.name}
          </Typography>
          <Typography variant="caption" sx={styles.agentRole}>
            AI {agent.role}
          </Typography>
        </Box>
        <IconButton onClick={toggleExpanded} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <Box sx={styles.outerContainer}>
      <Box sx={styles.container}>
        {(departmentAgents || user?.activeMembers) &&
          (departmentAgents?.length > 0 || user?.activeMembers?.length > 0) &&
          (departmentAgents || user?.activeMembers).map((agent, index) => (
            <Box key={index} sx={styles.agentWrapper}>
              {/* Compact Chat Header */}
              {renderChatHeader(agent)}
              <SoloChatView
                expanded={expanded}
                toggleExpanded={toggleExpanded}
                agent={agent}
                updateChatbotContext={updateChatbotContext}
              />
            </Box>
          ))}
        <Box sx={styles.agentWrapper}>
          <AddNewAITeamMember />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  outerContainer: {
    width: "100%",
    height: "100%",
    overflowY: "hidden",
    paddingBottom: "1rem",
    "&::-webkit-scrollbar": {
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    backgroundColor: "transparent",
  },
  container: {
    display: "flex",
    height: "100%",
    backgroundColor: "transparent",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    paddingTop: "1rem",
  },
  agentWrapper: {
    flex: "0 0 auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  searchBar: {
    display: "flex",
    width: "95%",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "4px 8px",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#F1F3F4",
    marginBottom: "1rem",
  },
  agentContainer: {
    display: "flex",
    height: CHAT_HEIGHT,
    width: STANDARD_AGENT_CHAT_WIDTH,
    borderRadius: STANDARD_BORDER_RADIUS,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    marginLeft: "1rem",
    padding: "1rem",
  },
  inputContainer: {
    display: "flex",
    width: "95%",
    alignItems: "center",
    borderRadius: "24px",
    padding: "2px",
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    position: "relative",
  },
  specializationChip: {
    backgroundColor: "black",
    color: "white",
  },
  sectionTitle: {
    width: "100%",
    fontWeight: 600,
  },
  actionButton: {
    textTransform: "none",
  },
  emptyChat: {
    textAlign: "center",
    color: COLORS.darkGray,
    marginTop: "2rem",
  },
  chatHeader: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
    flex: 1,
    header: "15%",
    borderBottom: `1px solid ${COLORS.lightGray}`,
    width: "100%",
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: "12px",
  },
  headerInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  agentName: {
    fontWeight: 600,
    lineHeight: 1.2,
  },
  agentRole: {
    color: COLORS.darkGray,
    textTransform: "capitalize",
  },
  expandedSection: {
    width: "100%",
    borderBottom: `1px solid ${COLORS.lightGray}`,
    backgroundColor: "transparent", // Add this line
  },

  specializationContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginBottom: "12px",
  },
  quickActionMenu: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "16px",
  },
  addNewContainer: {
    display: "flex",
    height: "100%",
    width: "28rem",
    borderRadius: STANDARD_BORDER_RADIUS,
    background:
      "linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.1) 100%)",
    backdropFilter: "blur(10px)",
    border: "1px solid rgba(255,255,255,0.1)",
    padding: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  addNewContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  gradientCircle: {
    cursor: "pointer",
    width: 80,
    height: 80,
    borderRadius: "50%",
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  addIcon: {
    fontSize: 40,
    color: "white",
  },
  facePile: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1.5rem",
  },
  avatarStyles: {
    width: 48,
    height: 48,
    border: "2px solid white",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    margin: "-8px",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
      zIndex: 1,
    },
  },
  addNewText: {
    fontWeight: 700,
    marginBottom: "1.5rem",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  addButton: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    color: "white",
    padding: "10px 24px",
    borderRadius: "25px",
    textTransform: "none",
    fontWeight: 600,
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 20px rgba(33, 150, 243, 0.4)",
    },
  },
  messageContainer: {
    flexGrow: 1,
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    gap: "16px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },
  userMessage: {
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    maxWidth: "70%",
  },
  aiMessage: {
    alignSelf: "flex-start",
    display: "flex",
    maxWidth: "70%",
  },
  messageAvatar: {
    width: 32,
    height: 32,
    marginRight: "8px",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  messageText: {
    padding: "12px 16px",
    borderRadius: "18px",
    fontSize: "14px",
    lineHeight: 1.4,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
  },
  userMessageText: {
    backgroundColor: "#0B93F6",
    color: "#FFFFFF",
    borderRadius: "18px",
    padding: "10px 15px",
    alignSelf: "flex-end",
    width: "100%",
  },
  aiMessageText: {
    backgroundColor: "#3E4042",
    color: "#FFFFFF",
    borderRadius: "18px",
    padding: "10px 15px",
    alignSelf: "flex-start",
    width: "100%",
  },
  timestamp: {
    color: "#8E8E92",
    fontSize: "12px",
    marginTop: "5px",
    alignSelf: "center",
  },
  inputField: {
    backgroundColor: "#222222",
    color: "#FFFFFF",
    borderRadius: "20px",
    padding: "10px 15px",
    marginTop: "10px",
  },
  messageTime: {
    fontSize: "10px",
    color: "rgba(0, 0, 0, 0.5)",
    marginTop: "4px",
    alignSelf: "flex-end",
  },
  input: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: "22px",
    padding: "12px 16px",
    color: COLORS.darkGray,
    fontSize: "14px",
    "&::placeholder": {
      color: COLORS.lightGray,
    },
  },
  sendButton: {
    color: COLORS.primary,
    position: "absolute",
    right: "8px",
  },
  description: {
    fontStyle: "italic",
    marginBottom: "16px",
    color: COLORS.darkGray,
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginBottom: "12px",
  },
  chip: {
    backgroundColor: COLORS.lightGray,
    color: COLORS.darkGray,
  },
  typeChip: {
    backgroundColor: COLORS.primary,
    color: "black",
  },
  infoRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
  },
  infoLabel: {
    marginRight: "8px",
    color: COLORS.darkGray,
  },
};

// Add these style overrides to the existing styles object
Object.assign(styles.userMessage, {
  "& $messageText": {
    backgroundColor: "red", //  "#f0f0f0",
    color: "#000",
  },
});

Object.assign(styles.aiMessage, {
  "& $messageText": {
    backgroundColor: COLORS.lightBlue,
    color: "white",
  },
});

export default AgentChats;
