import React, { useContext, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import { httpsCallable } from "firebase/functions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import { firebase, firestore, functions } from "../firebaseConfig";
import toast from "react-simple-toasts";
import { guidGenerator } from "../utils/helperMethods";

const CreateDeptModal = () => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [deptName, setDeptName] = useState("");
  const [invitees, setInvitees] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [newDept, setNewDept] = useState(null);
  const inputRefs = useRef([]);

  // TODO - STEP 2 INVITE PEOPLE - DEPARTMENT - Step 2
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!user || !user.id) {
      toast("Error: User not authenticated", { position: "top-center" });
      setIsLoading(false);
      return;
    }

    try {
      const newDept = {
        name: deptName,
        id: guidGenerator(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        invitees: invitees.filter((email) => email.trim() !== ""),
      };

      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [`departments.${newDept.id}`]: newDept,
        });

      setStep(2);
      setNewDept(newDept);
    } catch (error) {
      console.error("Error creating department:", error);
      toast("Error creating department. Please try again.", {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index === invitees.length - 1 && invitees[index].trim() !== "") {
        handleAddInvitee();
        // Focus on the new input field after a short delay
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };

  const handleAddInvitee = () => {
    setInvitees([...invitees, ""]);
  };

  const handleRemoveInvitee = (index) => {
    const newInvitees = invitees.filter((_, i) => i !== index);
    setInvitees(newInvitees);
  };

  const handleInviteeChange = (index, value) => {
    const newInvitees = [...invitees];
    newInvitees[index] = value;
    setInvitees(newInvitees);
  };

  const handleFinish = async () => {
    try {
      setIsLoading(true);

      const filteredInvitees = invitees.filter((email) => email.trim() !== "");

      const inviteMeRef = firestore.collection("deptInvites").doc(user.id);

      // Add new entries to the existing document
      await inviteMeRef.update({
        [`invitedEmails.${Date.now()}`]: filteredInvitees,
      });

      // Close modal and reset state
      setGlobalState({ ...globalState, showCreateDeptsModal: false });
      closeOut(true);
    } catch (error) {
      console.error("Error sending invites:", error);
      toast("Error sending invites. Please try again.", {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeOut = (showToast) => {
    setDeptName("");
    setInvitees([""]);
    setStep(1);
    if(showToast) toast("Invites sent successfully", { position: "top-right" });

    // Wait 1.5 seconds and reload the page
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <Modal
      open={globalState?.showCreateDeptsModal}
      onClose={() =>
        setGlobalState({ ...globalState, showCreateDeptsModal: false })
      }
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile, step)}>
        <Box>
          <Box sx={styles.progressContainer}>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Typography variant="h6" sx={styles.stepIndicator(step >= 1)}>
                1
              </Typography>
            </motion.div>
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: step === 2 ? 1 : 0 }}
              style={styles.progressLine}
              transition={{ duration: 0.5 }}
            />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography variant="h6" sx={styles.stepIndicator(step === 2)}>
                2
              </Typography>
            </motion.div>
          </Box>
          <Typography variant="h4" sx={styles.title}>
            {step === 1 ? "Create Department" : "Invite People"}
          </Typography>
          <Box sx={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              {step === 1 ? (
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Department Name"
                  value={deptName}
                  onChange={(e) => setDeptName(e.target.value)}
                  margin="normal"
                  required
                  sx={styles.input}
                />
              ) : (
                <>
                  <Typography variant="body1" sx={styles.successMessage}>
                    Successfully created "{deptName}" department
                  </Typography>
                  {invitees.map((invitee, index) => (
                    <Box key={index} sx={styles.inviteeRow}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="Enter email"
                        value={invitee}
                        onChange={(e) =>
                          handleInviteeChange(index, e.target.value)
                        }
                        onKeyPress={(e) => handleKeyPress(e, index)}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        margin="normal"
                        sx={styles.input}
                      />
                      <IconButton
                        onClick={() => handleRemoveInvitee(index)}
                        sx={styles.actionButton}
                        disabled={invitees.length === 1}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      {index === invitees.length - 1 && (
                        <IconButton
                          onClick={handleAddInvitee}
                          sx={styles.actionButton}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </form>
          </Box>
        </Box>
        <Box sx={styles.fixedFooter}>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading || (step === 1 && !deptName.trim())}
            sx={styles.submitButton}
            onClick={step === 1 ? handleSubmit : handleFinish}
            fullWidth
          >
            <Typography sx={{ color: "white", fontWeight: 600 }}>
              {isLoading
                ? "Processing..."
                : step === 1
                ? "Create Department"
                : "Send Invites"}
            </Typography>
          </Button>

       {step === 2   && <Typography
            onClick={() => {
              setGlobalState({ ...globalState, showCreateDeptsModal: false });
              closeOut();
            }}
            variant="body2"
            sx={{
              cursor: "pointer",
              textAlign: "center",
              color: "text.secondary",
              mt: 2,
            }}
          >
            Skip for now
          </Typography>  }
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: (isMobile, step) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    width: isMobile ? "100%" : "500px",
    height: step === 2 ? "70%" : "auto",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: 3,
  }),
  inviteeRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },
  actionButton: {
    color: "#2196F3",
    "&:hover": {
      color: "#21CBF3",
    },
  },
  formContainer: {
    // flex: 1,
    maxHeight: "calc(100% - 150px)", // Adjust based on your header height
    overflowY: "auto",
    marginTop: 2,
    paddingRight: 2,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
  },
  addButton: {
    mt: 2,
    color: "#2196F3",
    borderColor: "#2196F3",
    "&:hover": {
      borderColor: "#21CBF3",
      backgroundColor: "rgba(33, 203, 243, 0.04)",
    },
    textTransform: "none",
    fontWeight: 600,
  },
  submitButton: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(33, 150, 243, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)",
      boxShadow: "0 4px 6px rgba(33, 150, 243, 0.3)",
      transform: "translateY(-2px)",
    },
  },

  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  stepIndicator: (isActive) => ({
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: isActive
      ? "linear-gradient(45deg, #2196F3, #21CBF3)"
      : "#E0E0E0",
    color: isActive ? "#FFFFFF" : "#757575",
    fontWeight: "bold",
    transition: "all 0.3s ease",
  }),
  progressLine: {
    width: 100,
    height: 4,
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    margin: "0 10px",
    transformOrigin: "left",
  },

  progressBar: {
    marginBottom: 2,
    height: 8,
    borderRadius: 4,
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
  },
  title: {
    mb: 3,
    fontWeight: 700,
    textAlign: "center",
    color: "#333",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#2196F3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#21CBF3",
      },
    },
  },

  deleteButton: {
    color: "#F44336",
    marginLeft: 1,
  },

  buttonContainer: {
    mt: 3,
    display: "flex",
    justifyContent: "flex-end",
  },

  successMessage: {
    mb: 2,
    color: "#4CAF50",
    fontWeight: 500,
    textAlign: "center",
  },
};

export default CreateDeptModal;
