import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Zoom,
  keyframes,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SendIcon from "@mui/icons-material/Send";
import { firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import {
  Home as HomeIcon,
  Diamond as DiamondIcon,
  People as PeopleIcon,
  Store as ShopIcon,
  AttachMoney as IncomeIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Notes as NotesIcon,
  LoginRounded,
  HelpOutlineOutlined,
  Diamond,
} from "@mui/icons-material";

const sparkle = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.2) rotate(180deg); }
  100% { transform: scale(1) rotate(360deg); }
`;


const getAdjustedFontSize = (text) => {
    if (text.length <= 20) return "1.25rem";
    if (text.length <= 30) return "1rem";
    return "0.875rem";
  };

  
const MobileComingSoon = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast("Please enter your email address", { position: "top-right" });
      return;
    }

    // add to firestore collection "mobile_notify_me"
    await firestore.collection("mobile_notify_me").add({
      email,
      timestamp: new Date(),
    });

    // Close the modal and show success message
    toast(`🎉 Got it! We'll be in touch!`, {
      position: "top-right",
    });

    setEmail("");
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        {/* <NotificationsActiveIcon sx={styles.icon} /> */}
        <Tooltip
          title="Shine bright like a diamond!"
          TransitionComponent={Zoom}
          arrow
        >
          <DiamondIcon
            sx={{
              fontSize: 36,
              color: "#1A73E8",
              "&:hover": {
                animation: `${sparkle} 0.5s ease-in-out`,
              },
            }}
          />
        </Tooltip>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                background: "linear-gradient(45deg, #1A73E8 30%, #34A853 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: getAdjustedFontSize("TeamMembers.ai"),
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              TeamMembers.ai
            </Typography>

        <Typography variant="h4" sx={styles.title}>
          Desktop only
        </Typography>
        <Typography variant="body1" sx={styles.subtitle}>
          Please visit our site on your laptop / desktop. Mobile coming soon.
        </Typography>
        <form onSubmit={handleSubmit} style={styles.form}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={styles.input}
            InputProps={{
              endAdornment: (
                <IconButton type="submit" sx={styles.submitIcon}>
                  <SendIcon />
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={styles.button}
          >
            Notify Me
          </Button>
        </form>
        <Typography variant="caption" sx={styles.caption}>
          Be the first to know when we launch. No spam, we promise!
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
    paddingX: "20px",
  },
  content: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "32px",
    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: "linear-gradient(90deg, #2196F3, #21CBF3)",
    },
  },
  icon: {
    fontSize: "48px",
    color: "#2196F3",
    marginBottom: "16px",
  },
  title: {
    fontWeight: 700,
    marginBottom: "16px",
    color: "#333",
  },
  subtitle: {
    color: "#666",
    marginBottom: "24px",
  },
  form: {
    width: "100%",
    marginBottom: "16px",
  },
  input: {
    marginBottom: "16px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#2196F3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#21CBF3",
      },
    },
  },
  submitIcon: {
    color: "#2196F3",
    "&:hover": {
      color: "#21CBF3",
    },
  },
  button: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    color: "white",
    fontWeight: 600,
    padding: "12px",
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)",
    },
  },
  caption: {
    color: "#888",
    fontSize: "0.75rem",
  },
};

export default MobileComingSoon;
