import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";

const WebhookModal = ({ onSave, onDelete }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [prefix, setPrefix] = useState("");
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [confirmDeleteButton, showConfirmDeleteButton] = useState(false);

  useEffect(() => {
    setPrefix("fetch");
    if (globalState.selectedWebhook) {
      const { name, link, description } = globalState.selectedWebhook;
      setName(name.replace(/^fetch|\(\)$/g, ""));
      setLink(link);
      setDescription(description);
    } else {
      setName("");
      setLink("");
      setDescription("");
    }
  }, [globalState.selectedWebhook]);

  const handleSave = () => {
    if (name && link) {
      const formattedName =
        name.replace(/\s+/g, "").charAt(0).toUpperCase() +
        name.replace(/\s+/g, "").slice(1);
      const webhookData = {
        id: globalState.selectedWebhook?.id,
        name: `${prefix}${formattedName}()`,
        link,
        description,
      };
      onSave(webhookData);
      showConfirmDeleteButton(false);
    }
  };

  const handleDelete = () => {
    if (globalState.selectedWebhook && globalState.selectedWebhook.id) {
      onDelete(globalState.selectedWebhook.id);
      handleClose();
    }
  };

  const handleClose = () => {
    setGlobalState({
      ...globalState,
      showEditWebhookModal: false,
      selectedWebhook: null,
    });
    showConfirmDeleteButton(false);
  };

  return (
    <Modal open={globalState?.showEditWebhookModal} onClose={handleClose}>
      <Box sx={styles.modal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{}}>
            {globalState.selectedWebhook
              ? "Edit Data Webhook"
              : "Add New Data Webhook"}
          </Typography>
          {globalState.selectedWebhook && (
            <Button
              onClick={() => {
                showConfirmDeleteButton(confirmDeleteButton ? false : true);
              }}
              size="small"
              sx={{ color: "red" }}
            >
              {confirmDeleteButton ? "Cancel" : "Delete webhook"}
            </Button>
          )}
        </Box>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => {
            const formattedName =
              e.target.value.replace(/\s+/g, "").charAt(0).toUpperCase() +
              e.target.value.replace(/\s+/g, "").slice(1);
            setName(formattedName);
          }}
          fullWidth
          required
          sx={styles.input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{prefix}</InputAdornment>
            ),
            // endAdornment: <InputAdornment position="end">()</InputAdornment>,
          }}
        />
        <TextField
          label="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          fullWidth
          required
          sx={styles.input}
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={3}
          sx={styles.input}
        />
        <Box sx={styles.buttons}>
          <Button onClick={handleClose} sx={styles.button}>
            Cancel
          </Button>
          {confirmDeleteButton ? (
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{ ...styles.button, backgroundColor: "red" }}
            >
              DELETE WEBHOOK
            </Button>
          ) : (
            <Button onClick={handleSave} variant="contained" sx={styles.button}>
              {globalState.selectedWebhook ? "Update" : "Save"}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  },
  title: {
    mb: 2,
  },
  input: {
    mb: 2,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    ml: 1,
  },
};

export default WebhookModal;
