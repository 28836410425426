import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const RedClose = ({handleClose}) => {
    return <IconButton
    onClick={handleClose}
    size="small"
    sx={styles.closeButton}
    aria-label="close"
  >
    <CloseIcon fontSize="small" />
  </IconButton>
}

const styles = { 
    closeButton: {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: "#FF5F57",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        padding: 0,
        minWidth: "unset",
        "& .MuiSvgIcon-root": {
          fontSize: "8px",
          color: "#FF5F57",
          opacity: 0,
          transition: "opacity 0.2s",
        },
        "&:hover .MuiSvgIcon-root": {
          opacity: 1,
          color: "#000",
        },
      },
}

export default RedClose;