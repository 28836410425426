import React, { useContext } from "react";
import {
  Modal,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme

toastConfig({
  theme: "light",
  position: "top-center",
  maxWidth: "80%",
  duration: 5000,
}); 

const DefaultModal = () => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleSubmit = () => {
   
  };


  return (
    <Modal
      open={globalState?.configureAgentModal}
      onClose={() => setGlobalState({ ...globalState, configureAgentModal: false })}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: isMobile ? "0px" : "8px",
          width: isMobile ? "100%" : "900px",
          maxHeight: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
       
      </Box>
    </Modal>
  );
};

export default DefaultModal;
