import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import {
  Box,
  Divider,
  Typography,
  Avatar,
  Button,
  Chip,
  Tooltip,
  TextField,
  Tabs,
  Tab,
  ChipInput,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  IconButton,
  Zoom,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import SaveIcon from "@mui/icons-material/Save";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  AddCircleTwoTone,
  CheckCircle,
  InfoOutlined,
} from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { capitalizeWords, guidGenerator } from "../utils/helperMethods";
import StandardHeader from "../components/StandardHeader";
import { COLORS } from "../utils/colors";
import RenderStars from "../components/RenderStars";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import ConfigureAgentModal from "../components/HowItWorksModal.js";
import SignupModal from "../components/SignupModal";
import { fetchUserInfo, handleNav } from "../utils/helperMethods";
import OnboardingModal from "../components/OnboardingModal";
import { firestore, firebase } from "../firebaseConfig";
import { LONG_TERM_MEMORY_OPTIONS } from "../utils/constants";
import ConfirmationDialog from "../components/ConfirmationModal";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import InviteModal from "../components/InviteModal";
import ShareButton from "../components/ShareButton";
import WebhookModal from "../components/WebhookModal.js";
import DeleteAgentModal from "../components/DeleteAgentModal.js";

const Edit = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { role, username } = useParams();
  const [agent, setAgent] = useState(null);
  const [editedAgent, setEditedAgent] = useState(null);
  const [reviews, setReviews] = useState({ rating: 4.7, count: 253 });
  const [agentIsTeamMember, setAgentIsTeamMember] = useState(false);
  const [longTermMemoryOptions] = useState(LONG_TERM_MEMORY_OPTIONS);
  const [activeTab, setActiveTab] = useState("basic");
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditWebhooks, setShowEditWebhooks] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadAgentData = async () => {
      if (user?.teamMembers) {
        const createdAiMembers = Object.values(user.teamMembers).filter(
          (member) => member.createdAt
        );
        const foundAgent = createdAiMembers.find(
          (member) => member.roleSlug === role && member.username === username
        );
        if (foundAgent) {
          // Fetch the latest data from Firestore
          const agentDoc = await firestore
            .collection("agents")
            .doc(foundAgent.id)
            .get();
          const latestAgentData = agentDoc.data();

          if (agentDoc.exists) {
            setAgent(latestAgentData);
            setEditedAgent(latestAgentData);

            // Set the showEditWebhooks state based on the dataSource
            setShowEditWebhooks(
              latestAgentData?.dataSource === "customWebhook"
            );

          } else {
            setAgent(foundAgent);
            setEditedAgent(foundAgent);
          }
        } else {
          console.log("Created agent not found");
        }
      }
    };

    loadAgentData();
  }, [user, role, username]);

  useEffect(() => {
    if (user?.teamMembers && agent) {
      checkIfAgentTeamMember();
    }
  }, [user, agent]);

  useEffect(() => {
    const getUserProfile = async () => {
      if (user?.id && !user?.email) {
        const profile = await fetchUserInfo(user.id);
        setUser(profile);
        if (!profile.orgName) {
          setGlobalState({ ...globalState, showOnboardingModal: true });
          return;
        }
        setGlobalState({ ...globalState, showPaywall: true });
      }
    };
    getUserProfile();
  }, [user]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true); // Add this line to show loading state

    try {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `agent-images/${editedAgent.id}/${file.name}`
      );

      const uploadResult = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(uploadResult.ref);

      // Update the editedAgent state
      handleChange("image", downloadURL);

      // Update the image in Firestore
      const userRef = doc(firestore, `users/${user.id}`);
      await updateDoc(userRef, {
        [`teamMembers.${editedAgent.id}.image`]: downloadURL,
      });

      // Update the local agent state
      setAgent((prevAgent) => ({ ...prevAgent, image: downloadURL }));
      setEditedAgent((prevAgent) => ({ ...prevAgent, image: downloadURL }));

      // Update the user context if necessary
      setUser((prevUser) => ({
        ...prevUser,
        teamMembers: {
          ...prevUser.teamMembers,
          [editedAgent.id]: {
            ...prevUser.teamMembers[editedAgent.id],
            image: downloadURL,
          },
        },
      }));

      toast("Image uploaded successfully", { position: "top-center" });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast("Error uploading image", { position: "top-center" });
    } finally {
      setIsLoading(false); // Add this line to hide loading state
    }
  };

  const checkIfAgentTeamMember = () => {
    const isTeamMember = Object.values(user.teamMembers).some(
      (teamMember) => teamMember.id === agent.id
    );
    setAgentIsTeamMember(isTeamMember);
  };

  const handleChange = (field, value) => {
    setEditedAgent((prev) => {
      const updatedAgent = { ...prev, [field]: value };

      if (field === "dataSource" && value === "customWebhook") {
        setShowEditWebhooks(true);
      } else if (field === "dataSource") {
        setShowEditWebhooks(false);
      }

      return updatedAgent;
    });
  };

  const checkFields = () => {
    // all fields are required
    const requiredFields = [
      "name",
      "description",
      "type",
      "builtFor",
      "specializedFrameworks",
      "canHelpWith",
      "responseCriteria",
      "trigger",
      "output",
      "longTermMemory",
    ];

    // stripe account is required for premium agents
    if (!user?.stripe_account_id) {
      toast("Please connect your Stripe account to upgrade to Premium", {
        position: "top-center",
      });
      return true;
    }

    const missingFields = requiredFields.filter(
      (field) =>
        !editedAgent[field] ||
        (Array.isArray(editedAgent[field]) && !editedAgent[field].length)
    );

    if (missingFields.length) {
      toast(
        `Please fill out all required fields: ${missingFields.join(", ")}`,
        {
          position: "top-center",
        }
      );
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!user || !editedAgent) return;

    // check to see if the agent has filled out all the required fields
    const hasEmptyRequiredFields = checkFields();
    if (hasEmptyRequiredFields) return;

    try {
      const updatedTeamMembers = { ...user.teamMembers };
      const agentIndex = Object.values(updatedTeamMembers).findIndex(
        (member) => member.id === editedAgent.id
      );

      if (agentIndex !== -1) {
        // Helper function to ensure array
        const ensureArray = (value) => {
          if (Array.isArray(value)) return value;
          if (typeof value === "object" && value !== null)
            return Object.values(value);
          return value ? [value] : [];
        };

        // Helper function to remove undefined properties
        const removeUndefined = (obj) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => v !== undefined)
          );
        };

        const updatedAgent = removeUndefined({
          ...updatedTeamMembers[agentIndex],
          ...agent,
          // Basic Info
          name: editedAgent.name,
          description: editedAgent.description,
          type: editedAgent.type || "private",
          builtFor: ensureArray(editedAgent.builtFor),
          specializedFrameworks: ensureArray(editedAgent.specializedFrameworks),
          canHelpWith: ensureArray(editedAgent.canHelpWith),
          responseCriteria: removeUndefined({
            ...updatedTeamMembers[agentIndex]?.responseCriteria,
            personality: ensureArray(
              editedAgent?.responseCriteria?.personality
            ),
          }),
          createdBy: {
            id: user.id,
            name: user.name || "",
            username: user.username || "",
          },
          birthed: editedAgent.birthed || new Date().toISOString(),
          image: editedAgent.image || agent.image,
          // Workflow
          trigger: ensureArray(editedAgent.trigger || ["chat"]),
          output: ensureArray(editedAgent.output || ["chat"]),
          // Data Source
          dataSource: editedAgent.dataSource || "none",
          webhooks: editedAgent.webhooks || [],
          // Settings
          longTermMemory: editedAgent.longTermMemory || "Firebase Firestore",
          // Status
          status: "active",
        });

        // check if user has customWebhook as dataSource AND has 0 webhooks = no pass
        if (
          updatedAgent.dataSource === "customWebhook" &&
          updatedAgent.webhooks.length === 0
        ) {
          toast(
            "Please add at least one webhook or a use chat-only data source",
            {
              position: "top-center",
            }
          );
          return;
        }

        updatedTeamMembers[agentIndex] = updatedAgent;

        // Update the user object with the new team members
        setUser((prevUser) => ({
          ...prevUser,
          teamMembers: updatedTeamMembers,
        }));

        // Update the local agent state
        setAgent(updatedAgent);

        // Implement actual save logic to update the agent in the database
        await firestore
          .collection("users")
          .doc(user.id)
          .update({
            [`teamMembers.${editedAgent.id}`]: updatedAgent,
          });

        // update all the agents in the database
        await firestore
          .collection("agents")
          .doc(editedAgent.id)
          .set(updatedAgent);

        toast(`Updated Successfully`, {
          position: "top-center",
        });

        setGlobalState({
          ...globalState,
          showInviteModal: true,
          showCongrats: true,
        });
      } else {
        toast("Error: Agent not found in team members", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error updating agent:", error);
      toast(`Error updating agent: ${error.message}`, {
        position: "top-center",
      });
    }
  };

  const handleSave = async () => {
    if (!user || !editedAgent) return;

    try {
      const updatedTeamMembers = { ...user.teamMembers };
      const agentIndex = Object.values(updatedTeamMembers).findIndex(
        (member) => member.id === editedAgent.id
      );

      if (agentIndex !== -1) {
        // Helper function to ensure array
        const ensureArray = (value) => {
          if (Array.isArray(value)) return value;
          if (typeof value === "object" && value !== null)
            return Object.values(value);
          return value ? [value] : [];
        };

        // Helper function to remove undefined properties
        const removeUndefined = (obj) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => v !== undefined)
          );
        };

        const updatedAgent = removeUndefined({
          ...updatedTeamMembers[agentIndex],
          ...agent,
          // Basic Info
          name: editedAgent.name,
          description: editedAgent.description,
          type: editedAgent.type || "private",
          builtFor: ensureArray(editedAgent.builtFor),
          specializedFrameworks: ensureArray(editedAgent.specializedFrameworks),
          canHelpWith: ensureArray(editedAgent.canHelpWith),
          responseCriteria: removeUndefined({
            ...updatedTeamMembers[agentIndex]?.responseCriteria,
            personality: ensureArray(
              editedAgent?.responseCriteria?.personality
            ),
          }),
          createdBy: user.id,
          image: editedAgent.image || agent.image,
          // Workflow
          trigger: ensureArray(editedAgent.trigger || ["chat"]),
          output: ensureArray(editedAgent.output || ["chat"]),
          // Data Source
          dataSource: editedAgent.dataSource || "none",
          webhooks: editedAgent.webhooks || [],
          // Settings
          longTermMemory: editedAgent.longTermMemory || "Firebase Firestore",
        });

        updatedTeamMembers[agentIndex] = updatedAgent;

        // check if user has customWebhook as dataSource AND has 0 webhooks = no pass
        if (
          updatedAgent.dataSource === "customWebhook" &&
          updatedAgent.webhooks.length === 0
        ) {
          toast(
            "Please add at least one webhook or a use chat-only data source",
            {
              position: "top-center",
            }
          );
          return;
        }

        // Update the user object with the new team members
        setUser((prevUser) => ({
          ...prevUser,
          teamMembers: updatedTeamMembers,
        }));

        // Update the local agent state
        setAgent(updatedAgent);

        // Implement actual save logic to update the agent in the database
        await firestore
          .collection("users")
          .doc(user.id)
          .update({
            [`teamMembers.${editedAgent.id}`]: updatedAgent,
          });

        // update all the agents in the database
        await firestore
          .collection("agents")
          .doc(editedAgent.id)
          .set(updatedAgent);

        toast(`Updated Successfully`, {
          position: "top-center",
        });
      } else {
        toast("Error: Agent not found in team members", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error updating agent:", error);
      toast(`Error updating agent: ${error.message}`, {
        position: "top-center",
      });
    }
  };

  const ChipInput = ({ value, onChange, label, disabled = false }) => {
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef(null);

    const handleDelete = (chipToDelete) => () => {
      if (disabled) return;
      if (Array.isArray(value)) {
        onChange(value.filter((chip) => chip !== chipToDelete));
      } else if (typeof value === "object") {
        const newValue = { ...value };
        delete newValue[chipToDelete];
        onChange(newValue);
      }
    };

    const handleAdd = (event) => {
      if (disabled) return;
      if (event.key === "Enter" && inputValue.trim()) {
        event.preventDefault();
        const newChip = capitalizeWords(inputValue.trim());
        let newValue;
        if (Array.isArray(value)) {
          newValue = value.includes(newChip) ? value : [...value, newChip];
        } else if (typeof value === "object") {
          newValue = { ...value, [newChip]: newChip };
        } else {
          newValue = [newChip];
        }
        onChange(newValue);
        setInputValue("");
        inputRef.current.focus();
      }
    };

    const handleInputChange = (event) => {
      if (disabled) return;
      setInputValue(event.target.value);
    };

    const chips = Array.isArray(value) ? value : Object.values(value || {});

    return (
      <Box sx={styles.chipInputContainer}>
        <TextField
          fullWidth
          label={label}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleAdd}
          inputRef={inputRef}
          sx={styles.chipTextField}
          placeholder={disabled ? "" : "Type and press Enter to add"}
          helperText={disabled ? "" : "Press Enter to add each item"}
          disabled={disabled}
        />
        <Box sx={styles.chipBox}>
          {chips.map((chip) => (
            <Chip
              key={chip}
              label={chip}
              onDelete={disabled ? undefined : handleDelete(chip)}
              sx={styles.chip}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const handleStripeConnect = () => {
    const clientId = "ca_QZ5Oet4s8zuUQWFj6o0AT3545YFKwux0"; // LIVE - ca_QZ5ObSyGRlHLiM38kOkteffDjBjxBtXs

    const redirectUri = encodeURIComponent(
      "https://us-central1-teammembers-ai.cloudfunctions.net/stripeRedirect"
    );

    // Encode user ID, agent role slug, and username into the state parameter
    const stateData = {
      userId: user.id,
      roleSlug: editedAgent.roleSlug,
      username: editedAgent.username,
    };

    const state = btoa(JSON.stringify(stateData)); // base64 encode the JSON string

    const stripeUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}&state=${state}`;

    window.location.href = stripeUrl;
  };

  if (!agent)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        ...
      </Box>
    );

  const handleWebhookUpdate = (data) => {
    // remove all space from beginning and end of the string
    const trimmedData = {
      ...data,
      createdAt: new Date().toISOString(),
      id: data.id || guidGenerator(),
      name: data.name.trim(),
      link: data.link.trim(),
      description: data.description.trim(),
    };

    let updatedWebhooks;
    if (data.id) {
      // Update existing webhook
      updatedWebhooks = editedAgent.webhooks.map((webhook) =>
        webhook.id === data.id ? trimmedData : webhook
      );
    } else {
      // Add new webhook
      updatedWebhooks = editedAgent.webhooks
        ? [...editedAgent.webhooks, trimmedData]
        : [trimmedData];
    }

    const updatedEditedAgent = {
      ...editedAgent,
      webhooks: updatedWebhooks,
      dataSource: "customWebhook",
    };

    setEditedAgent(updatedEditedAgent);

    // save to firestore
    const userRef = doc(firestore, `users/${user.id}`);
    updateDoc(userRef, {
      [`teamMembers.${editedAgent.id}.webhooks`]: updatedWebhooks,
      [`teamMembers.${editedAgent.id}.dataSource`]: "customWebhook",
    });

    const agentRef = doc(firestore, `agents/${editedAgent.id}`);
    updateDoc(agentRef, updatedEditedAgent);

    toast(`Updated Successfully`, {
      position: "top-center",
    });

    // Update the local agent state
    setAgent(updatedEditedAgent);

    setGlobalState({ ...globalState, showEditWebhookModal: false });
  };

  const handleWebhookDelete = (webhookId) => {
    const updatedWebhooks = editedAgent.webhooks.filter(
      (webhook) => webhook.id !== webhookId
    );

    setEditedAgent((prevAgent) => ({
      ...prevAgent,
      webhooks: updatedWebhooks,
    }));

    // Update Firestore
    const userRef = doc(firestore, `users/${user.id}`);
    updateDoc(userRef, {
      [`teamMembers.${editedAgent.id}.webhooks`]: updatedWebhooks,
    });

    // Update Agents collection
    const agentRef = doc(firestore, `agents/${editedAgent.id}`);
    updateDoc(agentRef, {
      webhooks: updatedWebhooks,
    });

    // If there are no more webhooks, update dataSource
    if (updatedWebhooks.length === 0) {
      setEditedAgent((prevAgent) => ({
        ...prevAgent,
        dataSource: "none",
      }));
      updateDoc(userRef, {
        [`teamMembers.${editedAgent.id}.dataSource`]: "none",
      });
    }

    toast("Webhook deleted successfully", { position: "top-center" });
  };

  const renderWebhookConfig = () => {
    if (!showEditWebhooks) return null;

    return (
      <Box sx={{ pb: 5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography sx={{}}>Your Data-Fetch Webhooks</Typography>
          <IconButton
            disabled={agent?.status === "archived"}
            onClick={() =>
              setGlobalState({ ...globalState, showEditWebhookModal: true })
            }
          >
            <AddCircleTwoTone />
          </IconButton>
        </Box>
        {editedAgent?.webhooks && editedAgent.webhooks.length > 0 ? (
          editedAgent.webhooks.map((webhook, index) => (
            <Box
              key={index}
              sx={{
                mt: 2,
                p: 2,
                border: "1px solid #e0e0e0",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {webhook.name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircle sx={{ color: "green", mr: 1 }} />
                  <Tooltip title={webhook.link}>
                    <Typography variant="body2" sx={{ color: "green" }}>
                      Link Included
                    </Typography>
                  </Tooltip>
                  <Button
                    disabled={agent?.status === "archived"}
                    onClick={() => {
                      setGlobalState({
                        ...globalState,
                        showEditWebhookModal: true,
                        selectedWebhook: webhook,
                      });
                    }}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={agent?.status === "archived"}
                    onClick={() => {
                      setGlobalState({
                        ...globalState,
                        showEditWebhookModal: true,
                        selectedWebhook: webhook,
                      });
                    }}
                    size="small"
                    sx={{ ml: 1, color: "red" }}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
              {webhook.description && (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {webhook.description}
                </Typography>
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2">Add one to get started.</Typography>
        )}
      </Box>
    );
  };

  const renderBasicInfo = () => (
    <>
      <TextField
        multiline
        rows={4}
        autoComplete="off"
        value={editedAgent?.description || ""}
        onChange={(e) => handleChange("description", e.target.value)}
        sx={styles.editableField}
        label="Profile Description"
      />
      <FormControl component="fieldset" sx={styles.editableField}>
        <Typography variant="subtitle1" sx={styles.fieldLabel}>
          Type
        </Typography>
        <RadioGroup
          row
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          value={editedAgent?.type || "private"}
          onChange={(e) => handleChange("type", e.target.value)}
        >
          <Box sx={{ ...styles.radioWithTooltip, mr: 10 }}>
            <FormControlLabel
              value="private"
              control={<Radio />}
              label="Private"
            />
            <Tooltip title="Only visible to you and your team" placement="top">
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </Tooltip>
          </Box>
          <Box sx={styles.radioWithTooltip}>
            <FormControlLabel
              value="public"
              control={<Radio />}
              label="Public"
            />
            <Tooltip title="Monetized & Visible to all users" placement="top">
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </Tooltip>
          </Box>
          {/* <Box sx={styles.radioWithTooltip}>
            <FormControlLabel
              value="premium"
              control={<Radio />}
              label="Premium"
            />
            <Tooltip
              title="Available to premium subscribers only"
              placement="top"
            >
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </Tooltip>
          </Box> */}
        </RadioGroup>
      </FormControl>

      <ChipInput
        value={editedAgent?.builtFor || []}
        onChange={(newValue) => handleChange("builtFor", newValue)}
        label="Built For profession (ie. React Developers, Product Managers, SEO Experts)"
      />
      <ChipInput
        value={editedAgent?.specializedFrameworks || []}
        onChange={(newValue) => handleChange("specializedFrameworks", newValue)}
        label="Expert in frameworks (ie. Lean Startup Modal, Design Thinking)"
      />

      <ChipInput
        value={editedAgent?.canHelpWith || []}
        onChange={(newValue) => handleChange("canHelpWith", newValue)}
        label="Can help you with (ie. daily analysis of Google Analytics data)"
      />

      <ChipInput
        value={editedAgent?.responseCriteria?.personality || []}
        onChange={(newValue) =>
          handleChange("responseCriteria", {
            ...editedAgent.responseCriteria,
            personality: newValue,
          })
        }
        label="Personality traits (ie. direct, concise, insightful)"
      />
    </>
  );

  const renderWorkflow = () => (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Stepper
        orientation="vertical"
        sx={{
          minWidth: "200px",
          mr: 4,
          "& .MuiStepConnector-line": {
            minHeight: "40px",
            borderColor: "transparent",
            borderLeftWidth: "2px",
            borderLeftStyle: "solid",
            borderImage: "linear-gradient(to bottom, #2196F3, #21CBF3) 1 100%",
          },
          "& .MuiStepLabel-iconContainer": {
            background: "linear-gradient(45deg, #2196F3, #21CBF3)",
            borderRadius: "50%",
            padding: "4px",
          },
          "& .MuiStepIcon-root": {
            color: "white",
          },
          "& .MuiStepLabel-label": {
            color: "#333",
            fontWeight: "bold",
          },
        }}
      >
        <Step active={true}>
          <StepLabel>Trigger</StepLabel>
        </Step>
        <Step active={true}>
          <StepLabel>Data Source</StepLabel>
        </Step>
        <Step active={true}>
          <StepLabel>AI Model</StepLabel>
        </Step>
        <Step
          active={true}
          StepIconComponent={() => (
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              4
            </Typography>
          )}
        >
          <StepLabel>Output</StepLabel>
        </Step>
      </Stepper>

      <Box sx={{ flexGrow: 1 }}>
        {/* TRIGGERS */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            mb: 3,
          }}
        >
          <Typography variant="h6" sx={{ ...styles.sectionTitle, pr: 1 }}>
            Select how you'd like to start the workflow
          </Typography>
          <Tooltip title="Trigger that starts the automation">
            <InfoOutlinedIcon sx={styles.infoIcon} />
          </Tooltip>
        </Box>
        <Select
          value={editedAgent?.trigger?.[0] || "chat"}
          onChange={(e) => handleChange("trigger", [e.target.value])}
          fullWidth
          sx={{ ...styles.editableField, mb: 4 }}
        >
          <MenuItem value="chat">CHAT</MenuItem>
        </Select>

        {/* DATA SOURCES */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3, mt: 5 }}>
          <Typography variant="h6" sx={{ ...styles.sectionTitle, pr: 1 }}>
            Select a data source
          </Typography>
          <Tooltip title="Data source for the automation" placement="top">
            <InfoOutlinedIcon sx={styles.infoIcon} />
          </Tooltip>
        </Box>
        <Select
          value={editedAgent?.dataSource || "none"}
          onChange={(e) => handleChange("dataSource", e.target.value)}
          fullWidth
          sx={{ ...styles.editableField, mb: 4 }}
        >
          <MenuItem value="none">CHAT-ONLY</MenuItem>
          <MenuItem value="customWebhook">CUSTOM WEBHOOK</MenuItem>
        </Select>

        {showEditWebhooks && renderWebhookConfig()}

        {/* AI MODEL */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3, mt: 5 }}>
          <Typography variant="h6" sx={{ ...styles.sectionTitle, pr: 1 }}>
            Select an AI model for processing data
          </Typography>
          <Tooltip title="AI model for processing the data" placement="top">
            <InfoOutlinedIcon sx={styles.infoIcon} />
          </Tooltip>
        </Box>
        <Select
          value={editedAgent?.aiModel || "gpt4o"}
          onChange={(e) => handleChange("aiModel", e.target.value)}
          fullWidth
          sx={{ ...styles.editableField, mb: 4 }}
        >
          <MenuItem value="gpt4o">GPT-4o</MenuItem>
          {/* <MenuItem value="gpt35turbo">GPT-3.5 Turbo</MenuItem> */}
        </Select>

        {/* OUTPUTS */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3, mt: 7 }}>
          <Typography variant="h6" sx={{ ...styles.sectionTitle, pr: 1 }}>
            Select where to output to
          </Typography>
          <Tooltip
            title="Output destination for the automation"
            placement="top"
          >
            <InfoOutlinedIcon sx={styles.infoIcon} />
          </Tooltip>
        </Box>
        <Select
          value={editedAgent?.output?.[0] || "chat"}
          onChange={(e) => handleChange("output", [e.target.value])}
          fullWidth
          sx={styles.editableField}
        >
          <MenuItem value="chat">CHAT</MenuItem>
        </Select>
      </Box>
    </Box>
  );

  const renderSettings = () => (
    <>
      <Typography variant="h6" sx={{ ...styles.sectionTitle, mb: 2 }}>
        Long term memory
      </Typography>
      <Select
        value={editedAgent?.longTermMemory || "Firebase Firestore"}
        onChange={(e) => handleChange("longTermMemory", e.target.value)}
        fullWidth
        sx={styles.editableField}
      >
        {longTermMemoryOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="body2" sx={styles.helperText}>
        Saves the conversation to the database for future reference on request
        or automatically routinely
      </Typography>

      {/* ... (other settings fields) */}
    </>
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    setOpenDialog(false);
    await handleSubmit();
  };

  const handleAgentArchive = async () => {
    if (!user || !editedAgent) return;

    try {
      // Update agent status to 'archived' in user's teamMembers
      const userRef = doc(firestore, `users/${user.id}`);
      await updateDoc(userRef, {
        [`teamMembers.${editedAgent.id}.status`]: "archived",
      });

      // Update agent status in agents collection
      const agentRef = doc(firestore, `agents/${editedAgent.id}`);
      await updateDoc(agentRef, { status: "archived" });

      // Update local state
      setUser((prevUser) => ({
        ...prevUser,
        teamMembers: {
          ...prevUser.teamMembers,
          [editedAgent.id]: {
            ...prevUser.teamMembers[editedAgent.id],
            status: "archived",
          },
        },
      }));

      setEditedAgent((prevAgent) => ({
        ...prevAgent,
        status: "archived",
      }));

      toast("Agent archived successfully", { position: "top-center" });

      window.location.href = "/";
    } catch (error) {
      console.error("Error archiving agent:", error);
      toast(`Error archiving agent: ${error.message}`, {
        position: "top-center",
      });
    }
  };

  return (
    <Box sx={styles.container}>
      <ConfirmationDialog
        onClose={handleCloseDialog}
        open={openDialog}
        onConfirm={handleConfirmDialog}
      />
      {globalState?.deleteAgentConfirmModal && (
        <DeleteAgentModal
          handleAgentArchive={handleAgentArchive}
          agentName={editedAgent?.name}
        />
      )}
      {globalState?.showInviteModal && (
        <InviteModal
          agent={agent}
          agentUrl={`https://www.teammembers.ai/hire/${agent.roleSlug}/${agent.username}`}
        />
      )}
      {globalState?.showEditWebhookModal && (
        <WebhookModal
          onSave={(data) => handleWebhookUpdate(data)}
          onDelete={handleWebhookDelete}
        />
      )}
      {globalState?.showOnboardingModal && <OnboardingModal />}
      {globalState?.showSignupModal && <SignupModal />}
      {globalState?.configureAgentModal && <ConfigureAgentModal />}
      <StandardHeader />
      <Box sx={styles.content}>
        <Box sx={styles.leftColumn}>
          <Box sx={styles.leftColumnContent}>
            <Box sx={styles.avatarContainer}>
              <Avatar
                src={editedAgent?.image || agent.image}
                alt={agent.name}
                sx={styles.avatar}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="icon-button-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={styles.uploadButton}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Box>
            <TextField
              value={editedAgent?.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
              sx={styles.editableField}
              label="Name"
            />
            <Typography variant="h5" sx={{ ...styles.role, pb: 2 }}>
              @{agent.username}
            </Typography>
            <TextField
              value={editedAgent?.role || ""}
              onChange={(e) => handleChange("role", e.target.value)}
              sx={styles.editableField}
              disabled
              label="Role"
            />
            {/* CONNECT TO STRIPE  */}
            <Typography
              variant="h6"
              sx={{ ...styles.sectionTitle, mb: 1, mt: 4, fontSize: ".9rem" }}
            >
              Productize your skilled knowledge & start earning today
            </Typography>

            {!user?.stripe_account_id ? (
              <Button
                disabled={user?.stripe_account_id}
                onClick={handleStripeConnect}
                variant="contained"
                sx={styles.stripeButton}
              >
                Connect Your Stripe
              </Button>
            ) : (
              <Typography variant="body2" sx={{ color: "green", mt: 1 }}>
                ✔️ Your Stripe account is connected
              </Typography>
            )}

            {/* SHARE BUTTON LIKE THE STRIPE ONE BUT DIFF COLOR  */}
            <ShareButton disabled={agent?.status === "archived"} />

            {user?.status === "active" && (
              <Typography variant="body2" sx={{ color: "green", mt: 2 }}>
                ✔️ Active
              </Typography>
            )}
            {user?.status === "pending" && (
              <Typography variant="body2" sx={{ color: "green", mt: 2 }}>
                ⏳ Pending
              </Typography>
            )}
            {user?.status === "denied" && (
              <Typography variant="body2" sx={{ color: "green", mt: 2 }}>
                ❌ Denied
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.rightColumn}>
          <Box sx={styles.tabsContainer}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="BASIC INFO" value="basic" />
              <Tab label="AI WORKFLOW" value="workflow" />
              <Tab label="SETTINGS" value="settings" />
            </Tabs>
            <Box>
              <Button
                disabled={agent?.status === "archived"}
                onClick={() =>
                  setGlobalState({
                    ...globalState,
                    deleteAgentConfirmModal: true,
                  })
                }
                variant="contained"
                startIcon={<SaveIcon />}
                sx={{ ...styles.saveButton, backgroundColor: "red" }}
              >
                Delete
              </Button>
              <Button
                disabled={agent?.status === "archived"}
                onClick={handleSave}
                variant="contained"
                startIcon={<SaveIcon />}
                sx={styles.saveButton}
              >
                Save
              </Button>
              {editedAgent?.birthed ? (
                <Button
                  disabled={agent?.status === "archived"}
                  onClick={() =>
                    (window.location.href =
                      "/hire/" + agent.roleSlug + "/" + agent.username)
                  }
                  variant="contained"
                  sx={styles.submitButton}
                >
                  View Profile
                </Button>
              ) : (
                <Button
                  onClick={handleOpenDialog}
                  variant="contained"
                  sx={styles.submitButton}
                >
                  CREATE
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={styles.tabContent}>
            {activeTab === "basic" && renderBasicInfo()}
            {activeTab === "workflow" && (
              <Box sx={styles.workflowContainer}>{renderWorkflow()} </Box>
            )}
            {activeTab === "settings" && renderSettings()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100vh", // Full viewport height
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  workflowContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  stepper: {
    minWidth: "200px",
    marginRight: 4,
  },
  workflowContent: {
    flexGrow: 1,
  },
  avatarContainer: {
    position: "relative",
    marginBottom: 4,
    width: 200,
    height: 200,
  },
  avatar: {
    width: "100%",
    height: "100%",
  },
  uploadButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
  stripeButton: {
    backgroundColor: "#635BFF",
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 24px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "4px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#4A42DD",
      boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
    },
    "&:focus": {
      outline: "none",
      boxShadow: "0 0 0 2px rgba(99, 91, 255, 0.5)",
    },
  },

  chipInputContainer: {
    marginBottom: 2,
    width: "100%",
  },
  chipTextField: {
    marginBottom: 1,
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
      fontSize: "0.75rem",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  chipBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
  },
  chip: {
    margin: "4px",
    backgroundColor: COLORS.diamondBlue,
    color: "white",
    "&:hover": {
      backgroundColor: COLORS.grey,
    },
  },

  content: {
    display: "flex",
    flex: 1, // Take remaining space
    overflow: "hidden", // Hide overflow
  },

  leftColumnContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "sticky",
  },
  avatar: {
    width: 200,
    height: 200,
    marginBottom: 4,
  },
  fieldLabel: {
    fontWeight: 600,
    marginBottom: 1,
  },
  radioWithTooltip: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    fontSize: 14,
    color: COLORS.lightGrey,
    cursor: "help",
  },

  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },

  saveButton: {
    marginLeft: 2,
    background: "#22C55E",
    color: "#FFFFFF",
    textTransform: "none",
    // padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },
  submitButton: {
    marginLeft: 2,
    background: COLORS.diamondBlue,
    color: "#FFFFFF",
    textTransform: "none",
    // padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },
  tabContent: {
    flex: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },
  rightColumn: {
    flex: 1,
    overflowY: "auto",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 3,

    "&::-webkit-scrollbar": {
      height: "4px",
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
  },

  name: {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.2,
    marginBottom: 0.5,
  },

  role: {
    color: COLORS.darkGrey,
    fontSize: "1rem",
    marginBottom: 1,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 2,
  },
  ratingNumber: {
    fontWeight: 700,
    marginRight: 1,
    color: COLORS.primary,
    fontSize: "1.5rem",
  },
  reviewCount: {
    display: "flex",
    alignItems: "center",
    color: COLORS.darkGrey,
    cursor: "help",
    marginLeft: 1,
  },
  primaryButton: {
    background: "#22C55E",
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
  },

  leftColumn: {
    flex: "0 0 300px",
    position: "sticky",
    top: 0,
    height: "100%",
    overflowY: "auto",
    padding: "40px 20px",
  },

  description: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: 1.3,
  },

  divider: {
    margin: "24px 0",
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: "1.1rem",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
  },
  text: {
    color: "#4B5563",
  },

  buttonGroup: {
    display: "flex",
    gap: 2,
    marginBottom: 4,
  },

  editableField: {
    marginBottom: 2,
    width: "100%",
  },
  editButton: {
    marginTop: 2,
    backgroundColor: COLORS.primary,
    "&:hover": {
      backgroundColor: COLORS.primaryDark,
    },
  },

  secondaryButton: {
    borderColor: "#D1D5DB",
    color: "#18181B",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
  },

  experienceList: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  experienceItem: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  companyLogo: {
    width: 40,
    height: 40,
    backgroundColor: "#F3F4F6",
    color: "#4B5563",
  },
  frameworkName: {
    fontWeight: 600,
  },
  frameworkDate: {
    color: "#6B7280",
  },

  ratingBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },

  starRating: {
    "& .MuiRating-iconFilled": {
      color: COLORS.primary,
    },
    "& .MuiRating-iconEmpty": {
      color: COLORS.lightGrey,
    },
  },
};

export default Edit;
