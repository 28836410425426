import React, { useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";

const DeleteAgentModal = ({ handleAgentArchive, agentName }) => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleConfirmDelete = () => {
    handleAgentArchive();
    setGlobalState({ ...globalState, deleteAgentConfirmModal: false });
    toast("Agent deleted successfully", { position: "top-center" });
  };

  return (
    <Modal
      open={globalState?.deleteAgentConfirmModal}
      onClose={() => setGlobalState({ ...globalState, deleteAgentConfirmModal: false })}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        bgcolor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: isMobile ? "0px" : "8px",
          width: isMobile ? "100%" : "400px",
          maxHeight: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Confirm Deletion
        </Typography>
        <Typography sx={{ mb: 3 }}>
          Are you sure you want to delete the agent "{agentName}"?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={() => setGlobalState({ ...globalState, deleteAgentConfirmModal: false })}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteAgentModal;